import React, { Component } from 'react'
import HeaderWrapper from '../../helper/HeaderWrapper'
import mobiscroll from '@mobiscroll/react'
import AiswSpinner from '../helper/aiswSpinner/aiswSpinner'
import DiscoveryType from '@soccerwatch/discovery'
import Discovery from '../../services/discovery'
import moment from 'moment'
import EventContainer from './Container/EventContainer'
import i18n from '../../languages/i18n'
import getCroppedImg from './Container/cropImage'
import AiTagCheckList from './Container/AiTagCheckList'
import Overlay from './Container/overlay'
import WaitScreenContainer from './Container/waitScreenContainer'
import bookmakSVG from '../../img/icons/bookmak.svg'
import Cropper from 'react-easy-crop'
import Insert from './Container/Insert'

import contractContainer, { ContractContainer } from '../../services/contractContainer'
import { checkLanguages } from '../helper/checkLanguages/checkLanguages'
import { CameraContainer } from '../../services/cameraContainer'
import { Link, RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router'
import { VideoContainer } from '../../services/videoContainerContracts'
import { ClubContainer } from '../../services/clubContainer'
import {
  ClubData,
  Role,
  EVENTTYPES,
  calculateEventTime,
  AiTag,
  Tag,
  AiTagState,
  mergeTagsCalcMeta
} from '@soccerwatch/common'
import { Point, Area } from 'react-easy-crop/types'
import { EVENTSETTINGS } from './Container/eventSettings'
import { searchAndSortGetRoles } from '../../services/serviceHelper'
import { deleteTagById, postAiTagConfirmDenied, postTag, postTagById } from '../../api/api-tag'

import './Tagging.scss'

type TaggingStateType = {
  videoState: { videoIsLive: boolean; videoIsEnd: boolean }
  activeTagMode: undefined | 'remote' | 'live'
  setInitTeamSite: boolean
  liveTagging: boolean
  videoId: undefined | string
  videoAvailable: boolean
  clubs:
    | {
        home: ClubData
        guest: ClubData
      }
    | undefined
  player: any
  actualPlayer: any
  playerCurrentTime: number
  discovery?: typeof DiscoveryType
  tags: Array<Tag>
  aiTags: Array<AiTag>
  aiTagCheckList: boolean
  videoType: string
  videoInfo: any
  lastNewTag?: { id: string; timer: number; eventType: any }
  timerInformation: { timer: string; minutes: number; seconds: number }
  insertObj: {
    imageFile: any
    center: boolean
    imageSrc: any
    crop: { x: number; y: number }
    zoom: number
    duration: number
    croppedImage: undefined | File
  }
  liveTaggingInterval: any
  newAiTag: Array<{ club?: string; eventName: string; aiTag: AiTag }>
  score: { home: number; guest: number }
  eventContainerTagging: boolean
  openInsert: boolean
  waitDelete: boolean
  deactivatedHelper: boolean
}

type TaggingPropsType = RouteComponentProps & {
  videoId: string
  videoContainer: VideoContainer
  cameraContainer: CameraContainer
  clubContainer: ClubContainer
  contractContainer: ContractContainer
  camerasLoading: boolean
  backToCoach?: boolean
}

class CameraMonitor extends Component<TaggingPropsType, TaggingStateType> {
  playerContainerRef = React.createRef<any>()
  discovery?: typeof DiscoveryType
  stopInterval = false
  intervalId: any = undefined
  // Constructor
  constructor(props: TaggingPropsType) {
    super(props)
    this.state = {
      videoState: { videoIsEnd: false, videoIsLive: false },
      activeTagMode: undefined,
      setInitTeamSite: false,
      liveTagging: false,
      videoId: undefined,
      clubs: undefined,
      videoAvailable: false,
      player: undefined,
      actualPlayer: undefined,
      playerCurrentTime: 0,
      tags: [],
      aiTags: [],
      aiTagCheckList: false,
      videoInfo: undefined,
      videoType: 'soccer',
      timerInformation: { timer: '', minutes: 0, seconds: 0 },
      insertObj: {
        imageFile: undefined,
        center: true,
        imageSrc: '',
        crop: { x: 0, y: 0 },
        zoom: 1,
        duration: 30,
        croppedImage: undefined
      },
      liveTaggingInterval: undefined,
      newAiTag: [],
      score: { home: 0, guest: 0 },
      eventContainerTagging: true,
      openInsert: false,
      waitDelete: false,
      deactivatedHelper: false
    }

    Discovery.then((d: any) => {
      this.setState({ discovery: d })
    })
  }

  popup = React.createRef<any>()
  popupTeam = React.createRef<any>()

  componentDidMount() {
    this.props.contractContainer.setLockContractSelect(true)
    if (!this.props.cameraContainer.state.loadingData) {
      this.props.videoContainer.setVideoById(this.props.videoId, true)
    }

    // get every 10 seconds all Tags new
    this.intervalId = setInterval(() => {
      this.reloadTags()
    }, 10000)
  }

  componentWillUnmount() {
    this.stopInterval = true
    clearInterval(this.intervalId)
    this.props.contractContainer.setLockContractSelect(false)
  }

  async componentDidUpdate(lastProps: TaggingPropsType) {
    // this.updateDimensions()

    if (lastProps.camerasLoading && !this.props.camerasLoading) {
      this.props.videoContainer.setVideoById(this.props.videoId, true)
    }

    if (!this.state.videoAvailable || this.props.videoId !== this.state.videoId) {
      const checkVideo = this.props.videoContainer
        .getVideosForCurrentContract()
        .filter((video) => video.RowKey === this.props.videoId)[0]

      if (Boolean(checkVideo)) {
        const clubHome = await this.props.clubContainer.getClubById(checkVideo.clubAId)
        const clubGuest = await this.props.clubContainer.getClubById(checkVideo.clubBId)

        if (clubGuest && clubHome && JSON.stringify(this.state.videoInfo) !== JSON.stringify(checkVideo)) {
          this.setState({
            videoInfo: checkVideo,
            videoId: this.props.videoId,
            videoAvailable: true,
            clubs: { home: clubHome, guest: clubGuest },
            videoState: this.checkWaitMode(checkVideo)
          })

          setTimeout(() => {
            this.createPlayer(this.props.videoId)
          }, 30)
        }
      }
    }
  }

  checkWaitMode = (videoInfo: any) => {
    if (videoInfo !== undefined) {
      const videoStart = moment(videoInfo.expectedStartTime).add(-2, 'minutes')
      const videoEnd = moment(videoInfo.expectedStartTime).add(videoInfo.durationMin, 'minutes')

      if (moment().isAfter(videoEnd)) {
        this.setState({ activeTagMode: 'remote' })
      }

      return {
        videoIsLive: moment().isBetween(videoStart, videoEnd) && videoInfo.cameraStart !== undefined,
        videoIsEnd: moment().isAfter(videoEnd)
      }
    }
    return { videoIsLive: false, videoIsEnd: false }
  }

  handleTeamSide = (clubALeft: boolean) => {
    if (this.state.videoId) {
      const body = { RowKey: this.state.videoId, clubALeft: clubALeft }
      this.props.videoContainer.updateVideoGeneric(body).then((res) => {
        this.setState({ setInitTeamSite: false })
      })

      const tempArray = [...this.state.newAiTag]
      if (tempArray.length > 0) {
        setTimeout(() => {
          this.popup.current.showOverlay()
        }, 1000)
      }
    }
  }

  postTag = (body: any, matchId: string, tagId?: string, removeTimerDisabled?: boolean) => {
    // for callAs
    const callAs = searchAndSortGetRoles([
      Role.admin,
      contractContainer.getHighestRelevantRoleForCurrentContract()
    ])
    //

    if (!tagId) {
      return postTag(body, matchId, callAs).then((res) => {
        this.reloadTags()
        this.handleSetLastTag(res.RowKey, body, removeTimerDisabled)
      })
    } else {
      return postTagById(body, matchId, tagId, callAs).then((res) => {
        this.reloadTags()
        this.handleSetLastTag(res.RowKey, body, removeTimerDisabled)
      })
    }
  }

  deleteTag = async () => {
    // for callAs
    const callAs = searchAndSortGetRoles([
      Role.admin,
      contractContainer.getHighestRelevantRoleForCurrentContract()
    ])
    //
    if (this.state.videoId && this.state.lastNewTag?.id) {
      this.setState({ waitDelete: true })
      return deleteTagById(this.state.videoId, this.state.lastNewTag.id, callAs).then((res) => {
        this.reloadTags()
        this.setState({ lastNewTag: undefined, waitDelete: false })
      })
    }
  }

  handleHighlightTag = (tag: any) => {
    const checkHighlightValue = tag.level === 0 ? 1 : 0
    const body = { level: checkHighlightValue, eventType: tag.eventType }

    mobiscroll.toast({
      message: checkHighlightValue === 1 ? 'Highlight Hinzugefügt' : 'Highlight Entfernt',
      color: 'primary'
    })

    if (this.state.videoId !== undefined) {
      return this.postTag(body, this.state.videoId, tag.RowKey)
    } else {
      console.log('no videoId found')
    }
  }

  handlePrivatTag = (tag: any) => {
    const body = { private: tag.private, eventType: tag.eventType }

    mobiscroll.toast({
      message: tag.private ? 'Privat gestellt' : 'Öffenltich gestellt',
      color: 'primary'
    })

    if (this.state.videoId !== undefined) {
      return this.postTag(body, this.state.videoId, tag.RowKey)
    } else {
      console.log('no videoId found')
    }
  }

  createPlayer = (videoId: string) => {
    if (this.playerContainerRef.current && !this.state.player) {
      // This is only the player element, not the actual instance with all the methods etc.
      const playerElement = document.createElement('aisw-player') as any

      // for callAs
      let callAs = searchAndSortGetRoles([
        Role.admin,
        contractContainer.getHighestRelevantRoleForCurrentContract()
      ])
      //

      playerElement.options = {
        gameId: videoId,
        volume: 0,
        autoplay: false,
        overlayControl: true,
        login: false,
        chat: false,
        defaultLanguage: checkLanguages(),
        // lazy: true,
        callAs: callAs,
        adFree: true,
        paywallFree: true,
        events: false,
        embed: false,
        media: false
      }

      // The hello event contains the actual instance, which should be saved for future use
      playerElement.addEventListener('hello', async (event: any) => {
        const actualPlayer = event.detail
        await actualPlayer.userService.firstAuthDone
        let tags: Tag[] = []
        let aiTags: AiTag[] = []

        actualPlayer.timeupdate.subscribe((event: any) => {
          // set current time of the player
          let timer = { timer: '', minutes: 0, seconds: 0 }
          let duration =
            moment.duration(moment().diff(this.state.videoInfo.expectedStartTime)).add(3, 'minute') || 0
          const playerTime =
            this.state.activeTagMode === undefined ? Number(duration.valueOf()) : this.state.playerCurrentTime

          const tagsCalc = calculateEventTime(playerTime * 1000, this.state.tags)

          if (tagsCalc.currentTime) {
            let seconds: any = Math.floor((tagsCalc.currentTime / 1000) % 60)
            let minutes: any = Math.floor(tagsCalc.currentTime / (1000 * 60))
            minutes = minutes < 10 ? '0' + minutes : minutes
            seconds = seconds < 10 ? '0' + seconds : seconds
            timer = {
              timer: '0' + ':' + minutes + ':' + seconds,
              minutes: Number(minutes),
              seconds: Number(seconds)
            }
          }

          this.setState({
            playerCurrentTime: actualPlayer.playerStatsManager.currentTime,
            timerInformation: timer,
            score: {
              home: tagsCalc.scoreA !== this.state.score.home ? tagsCalc.scoreA : this.state.score.home,
              guest: tagsCalc.scoreB !== this.state.score.guest ? tagsCalc.scoreB : this.state.score.guest
            }
          })
        })

        let identifier
        await actualPlayer.gameService.getMatch(videoId, undefined, true).then(async (res: any) => {
          identifier = res.eventType
          const tempTag = await actualPlayer?.gameService.tagCache[videoId]
          const tempAiTags = await actualPlayer?.gameService.aiTagCache[videoId]
          tags = this.mergeTags(tempTag)
          aiTags = this.mergeTags(tempAiTags)
        })

        if (identifier && EVENTSETTINGS[identifier]) {
          identifier = identifier
        } else {
          // default or not include in eventsettings
          identifier = 'soccer'
        }

        const aiTagCheckList = aiTags.filter((a) => a.state === AiTagState.unconfirmed).length > 0
        const giveKickoffAITag = aiTags.filter((a) => {
          const findTag = EVENTTYPES.find((event: any) => event.eventType === a.eventType)
          return findTag && findTag.startClockWithSeconds !== undefined
        })

        let setInitTeamSite = false
        if (giveKickoffAITag[0] && this.state.videoInfo.clubALeft === undefined) {
          this.handleSeekTo(giveKickoffAITag[0].timestamp)
          setInitTeamSite = true
        } else if (aiTags.length > 0 && this.state.videoInfo.clubALeft === undefined) {
          this.handleSeekTo(aiTags[0].timestamp)
          setInitTeamSite = true
        }

        this.setState({
          actualPlayer: actualPlayer,
          tags: tags,
          aiTags: aiTags,
          aiTagCheckList: aiTagCheckList,
          videoType: identifier,
          setInitTeamSite
        })
      })

      this.playerContainerRef.current.append(playerElement as unknown as Node)
      this.setState({
        player: playerElement
      })
    }
  }

  currentTimeLiveTagging = (date?: number) => {
    const cameraStart = new Date(this.state.videoInfo.cameraStart).getTime()
    const nowDate = date ? date : new Date().getTime()
    return Math.round((nowDate - cameraStart) / 1000)
  }

  createTag = async (
    eventType: number,
    extraBody?: any,
    secondCurrentTime?: number,
    removeTimerDisabled?: boolean,
    tempDate?: boolean
  ) => {
    let currentTime = this.state.playerCurrentTime
    if (this.state.liveTagging) {
      currentTime = this.currentTimeLiveTagging()
    }

    if (secondCurrentTime) {
      currentTime = secondCurrentTime
      if (this.state.liveTagging) {
        currentTime = this.currentTimeLiveTagging()
      }
    } else {
      currentTime = this.state.playerCurrentTime
      if (this.state.liveTagging) {
        currentTime = this.currentTimeLiveTagging()
      }
    }

    const body = {
      eventType: eventType,
      timestamp: currentTime,
      identifier: this.state.videoType,
      ...extraBody
    }

    if (this.state.videoId && body.RowKey) {
      return this.postTag(body, this.state.videoId, body.RowKey, removeTimerDisabled)
    } else if (this.state.videoId) {
      return this.postTag(body, this.state.videoId)
    } else {
      console.log('no videoId found')
    }
  }

  // the player redraws the tags
  reloadTags = async () => {
    if (this.state.actualPlayer && this.state.videoId) {
      await this.state.actualPlayer.gameService.getMatch(this.state.videoId, true, true).then((res: any) => {
        this.setState({ videoState: this.checkWaitMode(res) })
        if (this.state.videoId) {
          const tags = this.mergeTags(this.state.actualPlayer?.gameService.tagCache[this.state.videoId])
          const aiTags = this.mergeTags(this.state.actualPlayer?.gameService.aiTagCache[this.state.videoId])
          let oldAiTags = this.state.aiTags
          let newAiTag = aiTags.filter(({ RowKey: id1 }) => !oldAiTags.some(({ RowKey: id2 }) => id2 === id1))
          // remove kick-off tags
          newAiTag = newAiTag.filter((newTag) => newTag.eventTypes !== 47)
          ///////////////////////
          const tagsMerged = mergeTagsCalcMeta(tags, aiTags)
          newAiTag = newAiTag.filter(({ RowKey: id1 }) => tagsMerged.some(({ RowKey: id2 }) => id2 === id1))

          let newAiTagInfo = this.state.newAiTag
          newAiTag.map((aiTag) => {
            const eventListSingle = EVENTSETTINGS[this.state.videoType].eventButtons.single.filter(
              (event) => event.eventTypes === aiTag.eventType
            )
            const eventListDouble = EVENTSETTINGS[this.state.videoType].eventButtons.double.filter(
              (event) => event.eventTypes[0] === aiTag.eventType || event.eventTypes[1] === aiTag.eventType
            )
            let name = ''

            if (eventListSingle[0]) {
              name = checkLanguages() === 'de' ? eventListSingle[0].name : eventListSingle[0].nameEn
            } else if (eventListDouble[0]) {
              name = checkLanguages() === 'de' ? eventListDouble[0].name : eventListDouble[0].nameEn
            }

            const clubName =
              aiTag.club === 'Home' ? this.state.clubs?.home.name : this.state.clubs?.guest.name
            newAiTagInfo.push({ club: clubName, eventName: name, aiTag: aiTag })
          })

          if (newAiTag.length > 0) {
            if (res.clubALeft === undefined) {
              this.popupTeam.current.showOverlay()
            } else {
              if (!this.state.deactivatedHelper) {
                this.popup?.current.showOverlay()
              }
            }
          }

          this.setState({
            tags: tags,
            aiTags: aiTags,
            videoInfo: res,
            newAiTag: newAiTagInfo
          })
        }
      })
    }

    return
  }

  handleLiveTagging = () => {
    const liveTagging = !this.state.liveTagging
    this.setState({ liveTagging: liveTagging })
    let vid = this.playerContainerRef.current.querySelector('video')

    if (liveTagging) {
      vid.pause()

      const liveTaggingInterval = setInterval(() => {
        let timer = { timer: '', minutes: 0, seconds: 0 }
        const tagsCalc = calculateEventTime(this.currentTimeLiveTagging() * 1000, this.state.tags)
        if (tagsCalc.currentTime) {
          let seconds: any = Math.floor((tagsCalc.currentTime / 1000) % 60)
          let minutes: any = Math.floor(tagsCalc.currentTime / (1000 * 60))
          minutes = minutes < 10 ? '0' + minutes : minutes
          seconds = seconds < 10 ? '0' + seconds : seconds
          timer = {
            timer: '0' + ':' + minutes + ':' + seconds,
            minutes: Number(minutes),
            seconds: Number(seconds)
          }
        }

        if (this.state.liveTagging) {
          this.setState({
            playerCurrentTime: this.currentTimeLiveTagging(),
            timerInformation: timer,
            score: {
              home: tagsCalc.scoreA !== this.state.score.home ? tagsCalc.scoreA : this.state.score.home,
              guest: tagsCalc.scoreB !== this.state.score.guest ? tagsCalc.scoreB : this.state.score.guest
            }
          })
        }
      }, 1000)
      this.setState({
        liveTaggingInterval: liveTaggingInterval
      })
    } else {
      clearInterval(this.state.liveTaggingInterval)
      vid.play()
    }
  }

  handleSeekTo = (time: number) => {
    let vid = this.playerContainerRef.current.querySelector('video')
    vid.currentTime = time
  }

  handleStartStopPlayer = (type: 'play' | 'pause') => {
    let vid = this.playerContainerRef.current.querySelector('video')

    if (type === 'play') {
      vid.play()
    } else if (type === 'pause') {
      vid.pause()
    }
  }

  mergeTags = (tags: Array<any>) => {
    tags.map((tag: any) => {
      EVENTTYPES.every((event: any) => {
        if (tag.eventType === event.eventType) {
          tag.name = event.name
          tag.nameEn = event.nameEn
          tag.club = event.club
          return false
        }
        return true
      })
      return tag
    })

    tags.sort((a, b) => a.timestamp - b.timestamp)
    return tags
  }

  handleSetLastTag = (id: string, body: any, removeTimerDisabled?: boolean) => {
    if (!removeTimerDisabled) {
      this.setState({
        // @ts-ignore:next-line
        lastNewTag: {
          id: id,
          timer: 10,
          eventType: EVENTTYPES.find((event: any) => event.eventType === body.eventType)
        }
      })
    }
  }

  onChangeDuration = (number: any) => {
    this.setState({
      insertObj: {
        ...this.state.insertObj,
        duration: number
      }
    })
  }

  onCropChange = (crop: Point) => {
    this.setState({
      insertObj: {
        ...this.state.insertObj,
        crop
      }
    })
  }

  onZoomChange = (zoom: number) => {
    this.setState({
      insertObj: {
        ...this.state.insertObj,
        zoom
      }
    })
  }

  onPositionhange = (center: boolean) => {
    this.setState({
      insertObj: {
        ...this.state.insertObj,
        center
      }
    })
  }

  onCropComplete = async (croppedArea: Area, croppedAreaPixels: Area) => {
    const croppedImage: any = await getCroppedImg(this.state.insertObj.imageSrc, croppedAreaPixels, 0)
    const file = new File([croppedImage], 'name', { type: this.state.insertObj.imageFile?.type })
    this.setState({
      insertObj: {
        ...this.state.insertObj,
        croppedImage: file
      }
    })
  }

  onFileChange = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      let imageDataUrl = await this.readFile(file)

      this.setState({
        insertObj: {
          ...this.state.insertObj,
          imageSrc: imageDataUrl,
          imageFile: file
        }
      })
    }
  }

  readFile(file: File) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  handleResetIsert = () => {
    this.setState({
      insertObj: {
        imageFile: undefined,
        center: true,
        imageSrc: '',
        crop: { x: 0, y: 0 },
        zoom: 1,
        duration: 30,
        croppedImage: undefined
      }
    })
  }

  handleCloseAiList = () => {
    this.setState({
      aiTagCheckList: false
    })
  }

  handleCheckTag = async (
    state: AiTagState.confirmed | AiTagState.denied | AiTagState.unconfirmed,
    reload = true
  ) => {
    const { newAiTag } = this.state
    let tag = newAiTag[0]?.aiTag
    let callAs = searchAndSortGetRoles([
      Role.admin,
      contractContainer.getHighestRelevantRoleForCurrentContract()
    ])

    if (tag) {
      return await postAiTagConfirmDenied(
        { state, clubALeft: this.state.videoInfo.clubALeft },
        tag.matchId,
        tag.RowKey,
        callAs
      ).then(async () => {
        if (reload) {
          const tempArray = [...this.state.newAiTag]
          tempArray.shift()
          await this.reloadTags()
          this.setState({
            newAiTag: tempArray
          })

          if (tempArray.length > 0) {
            setTimeout(() => {
              if (!this.state.deactivatedHelper) {
                this.popup.current.showOverlay()
              }
            }, 1000)
          }
        }
      })
    }
  }

  handleEventContainerTagging = (value: boolean) => {
    this.setState({
      eventContainerTagging: value
    })
  }

  handleTagMode = (state: undefined | 'remote' | 'live') => {
    this.setState({
      activeTagMode: state
    })

    if (state === 'live') {
      this.handleLiveTagging()
    }
  }

  renderBeforeBeginn = () => {
    // for callAs
    let callAs = searchAndSortGetRoles([
      Role.admin,
      contractContainer.getHighestRelevantRoleForCurrentContract()
    ])
    //

    if (this.state.openInsert) {
      return (
        <Insert
          postTag={this.createTag}
          closeOverlay={this.handleOpenCloseInsert}
          liveTagging={this.state.liveTagging}
          data={{ active: false, time: 0, tempDate: 4 }}
          insertObj={this.state.insertObj}
          onChangeDuration={this.onChangeDuration}
          onCropChange={this.onCropChange}
          onZoomChange={this.onZoomChange}
          onPositionhange={this.onPositionhange}
          onCropComplete={this.onCropComplete}
          onFileChange={this.onFileChange}
          resetInsertObj={this.handleResetIsert}
          playerCurrentTime={this.state.playerCurrentTime}
        />
      )
    }

    if (this.state.setInitTeamSite) {
      return (
        <div className='teamSiteMode'>
          <div className='teamSiteMode-line' />
          <div className='teamSiteMode-text'>
            {i18n.t('tagging.siteMode.text01')}
            <br />
            <br />
            {i18n.t('tagging.siteMode.text02')}
          </div>
          <Overlay
            text={i18n.t('tagging.overlay.teamSide.text')}
            iconSrc={this.state.clubs?.home.thumbnail}
            btnConfig={[
              { title: i18n.t('tagging.overlay.teamSide.btn.left'), click: () => this.handleTeamSide(true) },
              { title: i18n.t('tagging.overlay.teamSide.btn.right'), click: () => this.handleTeamSide(false) }
            ]}
            className={'overlayTeamSite'}
            popupDisable
          />
        </div>
      )
    }

    if (this.state.aiTagCheckList && !this.state.setInitTeamSite) {
      return (
        <AiTagCheckList
          clubs={this.state.clubs}
          aiTags={this.state.aiTags.filter((tag) => tag.state === 'unconfirmed')}
          playerCurrentTime={this.state.playerCurrentTime}
          reloadTags={this.reloadTags}
          seekTo={this.handleSeekTo}
          isLive={false}
          callAs={callAs}
          closeAiList={this.handleCloseAiList}
          clubALeft={this.state.videoInfo.clubALeft}
        />
      )
    }
  }

  handleOpenCloseInsert = () => {
    if (this.state.openInsert) {
      this.setState({
        openInsert: !this.state.openInsert,
        insertObj: {
          imageFile: undefined,
          center: true,
          imageSrc: '',
          crop: { x: 0, y: 0 },
          zoom: 1,
          duration: 30,
          croppedImage: undefined
        }
      })
    } else {
      this.setState({
        openInsert: !this.state.openInsert
      })
    }
  }

  renderPopups = () => {
    let { newAiTag } = this.state
    return (
      <>
        <Overlay
          text={i18n.t('tagging.overlay.teamSide.text')}
          infoText={i18n.t('tagging.overlay.teamSide.infoText')}
          iconSrc={this.state.clubs?.home.thumbnail}
          btnConfig={[
            { title: i18n.t('tagging.overlay.teamSide.btn.left'), click: () => this.handleTeamSide(true) },
            { title: i18n.t('tagging.overlay.teamSide.btn.right'), click: () => this.handleTeamSide(false) }
          ]}
          ref={this.popupTeam}
        />

        <Overlay
          text={
            i18n.t('tagging.overlay.aiEvent.text') +
            newAiTag[0]?.eventName +
            i18n.t('tagging.overlay.aiEvent.text1') +
            newAiTag[0]?.club +
            i18n.t('tagging.overlay.aiEvent.text2')
          }
          btnConfig={[
            { title: i18n.t('general.yes'), click: () => this.handleCheckTag(AiTagState.confirmed) },
            { title: i18n.t('general.no'), click: () => this.handleCheckTag(AiTagState.denied) }
          ]}
          ref={this.popup}
          deactivatedHelper={() => {
            this.setState({ deactivatedHelper: true })
            this.popup?.current.closeOverlay()
          }}
        />
      </>
    )
  }

  render() {
    let { videoState, activeTagMode } = this.state

    if (this.props.cameraContainer.state.loadingData && !this.state.videoAvailable) {
      return (
        <HeaderWrapper headerTitle='Tagging' backLink='/'>
          <div style={{ position: 'fixed', left: '0px', top: '0px', width: '100%', height: '100%' }}>
            <AiswSpinner />
          </div>
        </HeaderWrapper>
      )
    }

    return (
      <div className='content-container'>
        <div className='topBar'>
          <div className='topBar-left'>
            {this.props.backToCoach ? (
              <a href={`https://staige.coach/coaching/match/${this.props.videoId}`}>
                <div className='smallIcon-button'>
                  <span className='mbsc-ic mbsc-ic-arrow-left2' />
                </div>
              </a>
            ) : (
              <Link to={`/recordingplan/video/${this.props.videoId}`}>
                <div className='smallIcon-button'>
                  <span className='mbsc-ic mbsc-ic-arrow-left2' />
                </div>
              </Link>
            )}
            Tag Tool
          </div>
          <div className='topBar-right'>
            <div>
              <div
                className={
                  (videoState.videoIsLive || !videoState.videoIsEnd) && activeTagMode === undefined
                    ? 'smallIcon-button mobile-eventlistBtn disabledOp'
                    : 'smallIcon-button mobile-eventlistBtn'
                }
                onClick={
                  (videoState.videoIsLive || !videoState.videoIsEnd) && activeTagMode === undefined
                    ? () => {}
                    : () => this.handleEventContainerTagging(!this.state.eventContainerTagging)
                }
                style={
                  (videoState.videoIsLive || !videoState.videoIsEnd) && activeTagMode === undefined
                    ? { opacity: 0.4 }
                    : {}
                }
              >
                {this.state.eventContainerTagging ? (
                  <span className='mbsc-ic mbsc-ic-material-list' />
                ) : (
                  <img src={bookmakSVG} />
                )}
              </div>
            </div>
          </div>
        </div>

        {(videoState.videoIsLive || !videoState.videoIsEnd) && activeTagMode === undefined ? (
          <div className='waitContainer'>
            <WaitScreenContainer
              clubs={this.state.clubs}
              videoInfo={this.state.videoInfo}
              videoState={videoState}
              handleTagMode={this.handleTagMode}
              score={this.state.score}
              timerInformation={this.state.timerInformation}
            />
          </div>
        ) : null}

        <div
          style={
            (videoState.videoIsLive || !videoState.videoIsEnd) && activeTagMode === undefined
              ? { display: 'none' }
              : {}
          }
          className={
            this.state.eventContainerTagging && !this.state.liveTagging
              ? 'tagContainer'
              : 'tagContainer tagContainerOnlyBottom'
          }
        >
          <div>
            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
              <div
                id='playerContainer'
                ref={this.playerContainerRef}
                style={this.state.liveTagging ? { display: 'none' } : { width: '100%', height: '100%' }}
              />
              {this.state.insertObj.imageFile !== undefined && this.state.insertObj.center ? (
                <div className='insert-cropper-full'>
                  <Cropper
                    image={this.state.insertObj.imageSrc}
                    crop={this.state.insertObj.crop}
                    zoom={this.state.insertObj.zoom}
                    aspect={16 / 9}
                    onCropChange={this.onCropChange}
                    onCropComplete={this.onCropComplete}
                    onZoomChange={this.onZoomChange}
                    objectFit='horizontal-cover'
                  />
                </div>
              ) : null}

              {this.state.insertObj.imageFile !== undefined && !this.state.insertObj.center ? (
                <div className='insert-cropper'>
                  <Cropper
                    image={this.state.insertObj.imageSrc}
                    crop={this.state.insertObj.crop}
                    zoom={this.state.insertObj.zoom}
                    aspect={16 / 3}
                    onCropChange={this.onCropChange}
                    onCropComplete={this.onCropComplete}
                    onZoomChange={this.onZoomChange}
                    objectFit='horizontal-cover'
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div>
            <EventContainer
              clubs={this.state.clubs}
              tags={this.state.tags}
              postTag={this.postTag}
              createTag={this.createTag}
              deleteTag={this.deleteTag}
              playerCurrentTime={this.state.playerCurrentTime}
              videoType={this.state.videoType}
              timerInformation={this.state.timerInformation}
              handleStartStopPlayer={this.handleStartStopPlayer}
              score={this.state.score}
              videoInfo={this.state.videoInfo}
              handleEventContainerTagging={{
                value: this.state.eventContainerTagging,
                handleChange: this.handleEventContainerTagging
              }}
              handleSeekTo={this.handleSeekTo}
              reloadTags={this.reloadTags}
              popupJSX={this.renderPopups}
              handleOpenCloseInsert={this.handleOpenCloseInsert}
              liveTagging={this.state.liveTagging}
              lastNewTag={this.state.lastNewTag}
              waitDelete={this.state.waitDelete}
            >
              {this.renderBeforeBeginn()}
            </EventContainer>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(CameraMonitor)
