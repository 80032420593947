import React from 'react'
import StaigeModal from './Modal'
import i18n from '../../languages/i18n'

import './Tooltip.scss'

type staigeTooltipPropTypes = {
  style?: any
  title?: string
  text?: string
  children?: any
}

export default class StaigeTooltip extends React.Component<staigeTooltipPropTypes, unknown> {
  constructor(props: staigeTooltipPropTypes) {
    super(props)
  }

  private checkAlertRef = React.createRef<any>()

  openTooltip = () => {
    this.checkAlertRef.current.handleOpenModal()
  }

  render() {
    const { title, text, style, children } = this.props

    return (
      <div className='staige-tooltip' style={style} onClick={() => this.openTooltip()}>
        <div className='mbsc-ic mbsc-ic-ion-ios7-information-outline' />

        <StaigeModal
          ref={this.checkAlertRef}
          onConfirm={() => {}}
          confirmText={i18n.t('general.close')}
          title={title || 'Tooltip'}
          text={text}
          disableCancleBtn
          closeOutsideClick
        >
          {children ? children : undefined}
        </StaigeModal>
      </div>
    )
  }
}
