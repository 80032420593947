import React from 'react'

import './Tag.scss'

type staigeTagPropTypes = {
  lable: string | undefined
  active?: boolean
  color?: 'white' | 'violet' | 'blue' | 'red'
  onClick?: () => void
  disabled?: boolean
}

export default class StaigeTag extends React.Component<staigeTagPropTypes, unknown> {
  constructor(props: staigeTagPropTypes) {
    super(props)
  }

  render() {
    const { lable, color, active, onClick, disabled } = this.props
    return (
      <div
        className={`staige-tag ${active ? 'staige-tagActive' : ''}`}
        onClick={!disabled ? this.props.onClick : () => {}}
      >
        <div className={`tag-lable`}>{lable || 'lable fehlt'}</div>
      </div>
    )
  }
}
