import React from 'react'
import i18n from '../../../languages/i18n'
import checkCircleIcon from '../../../img/icons/check_circle.svg'
import cancelIcon from '../../../img/icons/cancel.svg'
import checkIcon from '../../../img/icons/check.svg'
import closeIcon from '../../../img/icons/close.svg'
import whistleIcon from '../../../img/icons/whistle.svg'
import moment from 'moment'
import CircleLoader from '../../helper/circleLoader/circleLoader'

import { AiTag, AiTagState, ClubData, Tag, TagEvents } from '@soccerwatch/common'
import { postAiTagConfirmDenied } from '../../../api/api-tag'

import './AiTagCheckList.scss'
import EventTap from './EventTap'

type AiTagCheckListPropTypes = {
  aiTags: Array<AiTag>
  clubs: { home: ClubData; guest: ClubData } | undefined
  seekTo: (time: number) => void
  isLive: boolean
  callAs: string
  reloadTags: () => void
  playerCurrentTime: number
  closeAiList: () => void
  clubALeft: boolean | undefined
}

type AiTagCheckListState = {
  timeEvents: Array<number>
  loadingCheck: boolean
}

export default class AiTagCheckList extends React.Component<AiTagCheckListPropTypes, AiTagCheckListState> {
  constructor(props: AiTagCheckListPropTypes) {
    super(props)
    this.state = {
      timeEvents: [],
      loadingCheck: false
    }
  }

  componentDidMount(): void {
    const timeEvents = TagEvents.filter(
      (event) =>
        event.pauseClock ||
        event.startClock ||
        (event.startClockWithSeconds !== undefined && event.startClockWithSeconds >= 0) ||
        (event.startPenaltyClock !== undefined && event.startPenaltyClock >= 0)
    )

    let timeEventsNumber = timeEvents.map((event) => {
      return event.eventType
    })
    timeEventsNumber = timeEventsNumber.concat([
      12, 13, 14, 15, 54, 55, 56, 57, 100, 101, 102, 103, 104, 105, 200, 201, 202, 203, 204, 205, 206, 207,
      208, 209, 239, 240, 241, 242
    ])
    timeEventsNumber = timeEventsNumber
      .sort((a, b) => a - b)
      .filter(function (item, pos, ary) {
        return !pos || item !== ary[pos - 1]
      })

    this.setState({
      timeEvents: timeEventsNumber
    })
  }

  handleCheckAllTags = async (confirmed: boolean) => {
    const aiTags = this.props.aiTags
    const state = confirmed ? AiTagState.confirmed : AiTagState.denied
    this.setState({ loadingCheck: true })

    for (let index = 0; index < aiTags.length; index++) {
      const tag = aiTags[index]
      await this.handleCheckTag(state, tag, false)
    }

    this.props.reloadTags()
    this.setState({ loadingCheck: false })
    this.props.closeAiList()
  }

  handleCheckTag = async (
    state: AiTagState.confirmed | AiTagState.denied | AiTagState.unconfirmed,
    tag: AiTag,
    reload = true
  ) => {
    return await postAiTagConfirmDenied(
      { state, clubALeft: this.props.clubALeft },
      tag.matchId,
      tag.RowKey,
      this.props.callAs
    ).then(async () => {
      if (reload) {
        await this.props.reloadTags()
        if (this.props.aiTags.length === 0) {
          this.props.closeAiList()
        }
      }
    })
  }

  renderConfirmButton = (tag: AiTag) => {
    return (
      <div className='event-btnBox'>
        <div
          className='btn'
          style={this.state.loadingCheck ? { pointerEvents: 'none', cursor: 'none', opacity: 0.3 } : {}}
          onClick={(e) => {
            this.handleCheckTag(AiTagState.denied, tag)
            e.stopPropagation()
          }}
        >
          <img src={closeIcon} />
        </div>
        <div
          className='btn'
          style={this.state.loadingCheck ? { pointerEvents: 'none', cursor: 'none', opacity: 0.3 } : {}}
          onClick={(e) => {
            this.handleCheckTag(AiTagState.confirmed, tag)
            e.stopPropagation()
          }}
        >
          <img src={checkIcon} />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className='checkList-container'>
        <div className='container-quickBar'>
          <div className='quickBar-title'>{i18n.t('tagging.checkAiTagList.title')}</div>
          <div className='quickBar-btn'>
            {this.state.loadingCheck ? (
              <div className='circleLoader'>
                <CircleLoader spinning />
              </div>
            ) : null}
            <div
              className={'btn btn-cancle'}
              style={this.state.loadingCheck ? { pointerEvents: 'none', cursor: 'none', opacity: 0.3 } : {}}
            >
              <img src={cancelIcon} onClick={() => this.handleCheckAllTags(false)} />
            </div>
            <div
              className='btn btn-allow'
              style={this.state.loadingCheck ? { pointerEvents: 'none', cursor: 'none', opacity: 0.3 } : {}}
            >
              <img src={checkCircleIcon} onClick={() => this.handleCheckAllTags(true)} />
            </div>
          </div>
        </div>

        <div className='container-list'>
          {this.props.aiTags.map((tag, index) => {
            const timeTag = this.state.timeEvents.includes(tag.eventType)
            let icon

            if (!timeTag) {
              // @ts-ignore
              if (tag.club === 'Home') {
                icon = this.props.clubs?.home.thumbnail || ''
                // @ts-ignore
              } else if (tag.club === 'Guest') {
                icon = this.props.clubs?.guest.thumbnail || ''
              }
            } else {
              icon = whistleIcon
            }

            return (
              <EventTap
                key={index}
                tag={tag}
                iconSrc={icon}
                handleSeekTo={this.props.seekTo}
                playerCurrentTime={this.props.playerCurrentTime}
                postTag={() => {}}
                handleDeletePopup={() => {}}
                className='blue'
                renderButton={() => this.renderConfirmButton(tag)}
              />
            )
          })}
        </div>
      </div>
    )
  }
}
