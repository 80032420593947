import React, { ChangeEvent } from 'react'
import './teamForm.scss'
import mobiscroll from '@mobiscroll/react'
import i18n from 'i18next'
import * as mobiscrollV5 from '@mobiscroll/react5'
import { checkLanguages } from '../../helper/checkLanguages/checkLanguages'
import { ClubData } from '@soccerwatch/common'
import { TeamContainer } from '../../../services/teamContainer'
import { CityContainer } from '../../../services/cityContainer'
import { ClubContainer } from '../../../services/clubContainer'
// import { CompetitionContainer } from '../../../services/competitionContainer'
// import CompetitionForm from '../competitionForm/competitionForm'
import { TeamData } from '../../../types/TeamData'
import { selectTeamAgeGroups } from '../../recordingPlan/Container/teamSelectionData'
import AiswSpinner from '../../helper/aiswSpinner/aiswSpinner'
// import ClubForm from '../clubForm/clubForm'
// import FilteredSelect from '../../helper/filteredSelect/filteredSelect'

import './teamForm.scss'

type TeamFormProps = {
  id?: string
  teamContainer: TeamContainer
  clubContainer: ClubContainer
  cityContainer: CityContainer
  // competitionContainer: CompetitionContainer
  club?: ClubData
  open: boolean
  onClose?: (team?: TeamData) => void
}
type TeamFormState = {
  loading: boolean
  name: string
  age: string
  // competitions: CompetitionData[]
  aliasList: string[]
  // dId: string
  // dfbLink: string
  // club?: ClubData
  clubFormOpen: boolean
  // competitionFormOpen: boolean
}
const EmptyState: TeamFormState = {
  loading: false,
  name: '',
  age: '',
  // competitions: [],
  aliasList: [],
  // dId: '',
  // dfbLink: '',
  // club: undefined,
  clubFormOpen: false
  // competitionFormOpen: false
}

export default class TeamForm extends React.Component<TeamFormProps, TeamFormState> {
  constructor(props: TeamFormProps) {
    super(props)
    this.state = EmptyState
  }

  componentDidMount() {}

  closeModal = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  handleTextPropertyChanged = (key: keyof TeamFormState) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    this.setState({ ...this.state, [key]: value })
  }

  handleBooleanPropertyChanged = (key: keyof TeamFormState) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked
    this.setState({ ...this.state, [key]: value })
  }

  handleAgeChanged = (event: { valueText?: string | undefined }, i: { getVal: () => string }) => {
    const age = i.getVal()
    this.setState({ age })
  }

  // handleClubChange = (club: ClubData) => {
  //   if (!club.RowKey) {
  //     // @ts-ignore
  //     club.RowKey = club.id
  //   }
  //   this.setState({ club })
  // }

  openClubForm = () => {
    this.setState({ clubFormOpen: true })
  }

  // closeClubForm = (club?: ClubData) => {
  //   if (club) {
  //     this.setState({ club, clubFormOpen: false })
  //   } else {
  //     this.setState({ clubFormOpen: false })
  //   }
  // }

  handleCancleClick = () => {
    this.setState(EmptyState)
    this.closeModal()
  }

  validateInput = () => {
    //TODO: Validation Code
    return (
      this.state.name !== '' &&
      this.state.age !== '' &&
      // this.state.dId !== '' &&
      // this.state.dfbLink !== '' &&
      this.props.club !== undefined
    )
    // return false
  }

  handleSaveClick = async () => {
    if (this.validateInput()) {
      // const competitions = this.state.competitions.map((c) => c.RowKey)
      const body = {
        name: this.state.name,
        age: this.state.age,
        // competitions,
        // aliasList: this.state.aliasList,
        // dId: this.state.dId,
        // dfbLink: this.state.dfbLink,
        clubId: this.props.club?.RowKey,
        active: true
      }

      const team = await this.props.teamContainer.createTeam(body)
      this.props.onClose && this.props.onClose(team)
    }
  }

  // openNewCompetitionForm = () => {
  //   this.setState({ competitionFormOpen: true })
  // }

  // closeNewCompetitionForm = () => {
  //   this.setState({ competitionFormOpen: false })
  // }

  render() {
    const lang = checkLanguages()
    return (
      <div className='form teamForm'>
        {/* <mobiscroll.Button id={this.props.id} onClick={this.onMainInputClick} disabled={this.props.disabled}>
          {this.props.label}
        </mobiscroll.Button> */}
        <mobiscrollV5.Popup
          isOpen={this.props.open}
          onClose={this.closeModal}
          theme='ios'
          themeVariant='dark'
        >
          <div style={{ margin: '0 -16px' }}>
            <mobiscroll.FormGroupTitle>
              <h2 style={{ marginTop: 0 }}>
                {i18n.t('forms.team.title')}
                {/* {!props.cameraLoading && <AiswSpinner className='inline' />} */}
              </h2>
            </mobiscroll.FormGroupTitle>
            {/* Top Full Length */}
            <div className='mbsc-grid mbsc-no-padding' style={{ marginTop: '-24px' }}>
              <div className='mbsc-row'>
                <div className='mbsc-col mbsc-col-6 left'>
                  {/* LEFT */}
                  <mobiscroll.Input
                    onChange={this.handleTextPropertyChanged('name')}
                    type='text'
                    placeholder={i18n.t('general.name')}
                    lang={lang}
                    value={this.state.name}
                    disabled={this.state.loading}
                  >
                    {i18n.t('general.name')}
                  </mobiscroll.Input>

                  {/* <mobiscroll.Input
                    onChange={this.handleTextPropertyChanged('dfbLink')}
                    type='text'
                    placeholder={i18n.t('forms.team.dfbLink')}
                    lang={lang}
                    value={this.state.dfbLink}
                    disabled={this.state.loading}
                  >
                    {i18n.t('forms.team.dfbLink')}
                  </mobiscroll.Input> */}
                </div>
                <div className='mbsc-col mbsc-col-6 right'>
                  {/* Right */}
                  <mobiscrollV5.Select
                    data={selectTeamAgeGroups()}
                    label={i18n.t('recordingPlan.teams.ageclass')}
                    inputProps={{
                      inputStyle: 'box',
                      labelStyle: 'stacked',
                      placeholder: i18n.t('recordingPlan.teams.placeholder.select')
                    }}
                    locale={checkLanguages() === 'de' ? mobiscrollV5.localeDe : undefined}
                    display='center'
                    itemHeight={40}
                    filter
                    disabled={this.state.loading}
                    value={this.state.age ?? undefined}
                    onChange={this.handleAgeChanged}
                    theme='windows'
                    themeVariant='dark'
                  />
                  {/*  <mobiscroll.Select
                    placeholder={i18n.t('recordingPlan.teams.placeholder.select')}
                    lang={checkLanguages()}
                    display='center'
                    filter={true}
                    value={this.state.age ?? undefined}
                    data={selectTeamAgeGroups()}
                    onSet={this.handleAgeChanged}
                    disabled={this.state.loading}
                  >
                    <mobiscroll.Input>{i18n.t('recordingPlan.teams.ageclass')}</mobiscroll.Input>
                  </mobiscroll.Select>
                  <mobiscroll.Input
                    onChange={this.handleTextPropertyChanged('dId')}
                    type='text'
                    placeholder={i18n.t('forms.team.dId')}
                    lang={lang}
                    value={this.state.dId}
                    disabled={this.state.loading}
                  >
                    {i18n.t('forms.team.dId')}
                  </mobiscroll.Input> */}
                  <mobiscroll.Input disabled readOnly value={this.props.club?.name}>
                    {i18n.t('forms.team.club')}
                  </mobiscroll.Input>
                  {/* <FilteredSelect<ClubData>
                    label={i18n.t('forms.team.club')}
                    id='TeamSelectHome'
                    disabled={this.state.loading}
                    value={this.state.club}
                    onChange={this.handleClubChange}
                    onNewEntryNeeded={this.openClubForm}
                    baseUrl={`https://europe-west3-aisw-ww-prod.cloudfunctions.net/api_fanpage/clubs/`}
                    defaultSelection={this.props.clubContainer.getClubList()}
                  ></FilteredSelect> */}
                </div>
              </div>
            </div>

            <mobiscroll.FormGroup style={{ marginBottom: '10px' }}>
              <div className='mbsc-grid'>
                <div className='mbsc-row'>
                  <div className='mbsc-col mbsc-col-xl-6' style={{ paddingRight: '0.3em' }}>
                    <mobiscroll.Button
                      onClick={this.handleCancleClick}
                      className={'round blue fullWidth'}
                      style={{ height: '36px', padding: '0', lineHeight: '0' }}
                      disabled={this.state.loading}
                    >
                      {i18n.t('general.cancel')}
                    </mobiscroll.Button>
                  </div>
                  <div className='mbsc-col mbsc-col-xl-6' style={{ paddingLeft: '0.3em' }}>
                    <mobiscroll.Button
                      onClick={this.handleSaveClick}
                      className={'round blue fullWidth'}
                      disabled={this.state.loading || !this.validateInput()}
                      style={{ height: '36px', padding: '0', lineHeight: '0' }}
                    >
                      {this.state.loading ? (
                        <div style={{ height: '18px', width: '18px', marginLeft: '46%' }}>
                          <AiswSpinner />
                        </div>
                      ) : (
                        i18n.t('general.save')
                      )}
                    </mobiscroll.Button>
                  </div>
                </div>
              </div>
            </mobiscroll.FormGroup>
          </div>
        </mobiscrollV5.Popup>
        {/* <ClubForm
          open={this.state.clubFormOpen}
          clubContainer={this.props.clubContainer}
          cityContainer={this.props.cityContainer}
          onClose={this.closeClubForm}
        /> */}
        {/* <CompetitionForm
          open={this.state.competitionFormOpen}
          onClose={this.closeNewCompetitionForm}
          competitionContainer={this.props.competitionContainer}
        /> */}
      </div>
    )
  }
}
