import * as Discovery from '@soccerwatch/discovery'
import * as Helper from '../helper/GlobalHelper'
import { Paywall } from '@soccerwatch/common'

type PaywallSelf = {
  title?: string
  active: boolean
  type?: string | undefined
  description?: string
  priority?: number
  price?: number
  includes: Array<string>
  list?: Array<{ 'Trans-Nr': string; Nachname?: string }>
}

export const getPaywallManage = async (id: number | string) => {
  return await Helper.apiGet<Paywall[]>(Discovery.API_ADDITIONAL + '/paywall/manage/video/' + id)
}

export const postPaywallPackage = async (body: PaywallSelf, callAs: string) => {
  return await Helper.apiPost(Discovery.API_ADDITIONAL + '/paywall/package' + callAs, body)
}
