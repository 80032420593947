import React, { useEffect, useMemo, useRef, useState } from 'react'
import i18n from 'i18next'
import callToActionIcon from '../../../img/icons/callToAction.svg'
import StaigeTag from '../../../components/styles/Tag'

import { emptyImageAdMaterial } from './EmptyAdvertisements'
import { PlayerOptions, InstreamAd, InstreamTypes, GenericAd } from '@soccerwatch/common'

type PlayerInstance = {
  switchToVideo: (options: Partial<PlayerOptions>) => void
  options: PlayerOptions
}

type PlayerElement = {
  options?: Partial<PlayerOptions>
  addEventListener: (eventName: 'hello', listener: (event: { detail: PlayerInstance }) => void) => void
}

export const PreviewVideo = (props: {
  onChange: (instreamAdvertisement: InstreamAd) => void
  advertisement?: InstreamAd | null | undefined
}) => {
  const advertisement = props.advertisement ?? emptyImageAdMaterial
  const [player, setPlayer] = useState<PlayerInstance | undefined>()
  const [previewimage, setPreviewImage] = useState<string | undefined>()

  const { url: fileURL, progressInPercent: fileUploadProgressInPercent } = {
    url: previewimage ? previewimage : '',
    progressInPercent: 100
  }

  const previewURL = useMemo(() => advertisement.mediaInstream, [fileURL, advertisement.mediaInstream])

  const setContentType = (newContentType: InstreamAd['instreamType']) => {
    props.onChange({
      ...advertisement,
      instreamType: newContentType
    })
  }

  const playerContainerRef = useRef<HTMLDivElement | null>(null)

  const options: Partial<any> = {
    gameId: '147312',
    volume: 0,
    autoplay: true,
    overlayControl: true,
    login: false,
    chat: false,
    events: false,
    embed: false,
    media: false,
    seekTime: 800,
    adFree: true,
    paywallFree: true
  }

  const [playerHasBeenCreated, setPlayerHasBeenCreated] = useState(false)

  const createPlayer = () => {
    if (playerContainerRef.current && !playerHasBeenCreated) {
      // This is only the player element, not the actual instance with all the methods etc.
      const playerElement = document.createElement('aisw-player') as PlayerElement

      playerElement.options = options
      // The hello event contains the actual instance, which should be saved for future use
      playerElement.addEventListener('hello', (event) => {
        const actualPlayer = event.detail
        setPlayer(actualPlayer)
      })

      playerContainerRef.current?.append(playerElement as unknown as Node)
      setPlayerHasBeenCreated(true)
    }
  }

  const updatePlayerPreview = () => {
    if (player && previewURL) {
      const adRoll: GenericAd = {
        mediaInstream: advertisement.mediaInstream,
        instreamType: advertisement.instreamType,
        instreamDuration: Infinity,
        instreamStartOffset: 1,
        link: advertisement.link,
        inStream: true,
        active: true,
        name: '',
        comment: ''
      }
      player.options.sponsors = {
        inStreamElement: [adRoll],
        inSummaryElement: []
      }
    }
  }

  useEffect(updatePlayerPreview, [previewURL, player, advertisement.instreamType, previewimage])
  useEffect(createPlayer, [playerContainerRef.current, playerHasBeenCreated])

  return (
    <div className='bottom-container'>
      <div className='container-title'>{i18n.t('sponsors.prevVideo.title')}</div>

      <div className='container-previewBox'>
        <div className='previewBox-option'>
          <div
            className={`option-item ${
              advertisement.instreamType === InstreamTypes.IMAGE ? 'option-itemActive' : ''
            }`}
            onClick={() => setContentType(InstreamTypes.IMAGE)}
          >
            <div className='item-inner'>
              <div className='inner-top'>
                <div className='top-info'>
                  <img src={callToActionIcon} />
                  <div className='info-text'>{i18n.t('editAdvertisement.labels.loop')}</div>
                </div>
                <div className='top-check'>
                  <div className='check-active' />
                </div>
              </div>
              <div className='inner-text'>{i18n.t('sponsors.prevVideo.loopInfoText')}</div>
            </div>
          </div>
          <div
            className={`option-item ${
              advertisement.instreamType === InstreamTypes.STATIC ? 'option-itemActive' : ''
            }`}
            onClick={() => setContentType(InstreamTypes.STATIC)}
          >
            <div className='item-inner'>
              <div className='inner-top'>
                <div className='top-info'>
                  <img src={callToActionIcon} />
                  <div className='info-text'>{i18n.t('editAdvertisement.labels.fixed')}</div>
                </div>
                <div className='top-check'>
                  <div className='check-active' />
                </div>
              </div>
              <div className='inner-text'>{i18n.t('sponsors.prevVideo.fixedInfoText')}</div>
            </div>
          </div>
        </div>

        <div className='previewBox-mobile'>
          <StaigeTag
            active={advertisement.instreamType === InstreamTypes.IMAGE}
            lable={i18n.t('editAdvertisement.labels.loop')}
            onClick={() => setContentType(InstreamTypes.IMAGE)}
          />
          <StaigeTag
            active={advertisement.instreamType === InstreamTypes.STATIC}
            lable={i18n.t('editAdvertisement.labels.fixed')}
            onClick={() => setContentType(InstreamTypes.STATIC)}
          />
        </div>

        <div className='previewBox-prev'>
          <div className='prev-video' ref={playerContainerRef} />
        </div>
      </div>
    </div>
  )
}
