import React, { ChangeEvent } from 'react'
import './contractForm.scss'
import mobiscroll from '@mobiscroll/react'
import i18n from 'i18next'
import * as mobiscrollV5 from '@mobiscroll/react5'
import { checkLanguages } from '../../helper/checkLanguages/checkLanguages'
import { ClubData } from '@soccerwatch/common'
import { CityContainer } from '../../../services/cityContainer'
import { ClubContainer } from '../../../services/clubContainer'
import { TeamData } from '../../../types/TeamData'
import { ContractContainer } from '../../../services/contractContainer'
import ClubForm from '../clubForm/clubForm'
import FilteredSelect from '../../helper/filteredSelect/filteredSelect'
import AiswSpinner from '../../helper/aiswSpinner/aiswSpinner'
// import ClubForm from '../clubForm/clubForm'
// import FilteredSelect from '../../helper/filteredSelect/filteredSelect'

type ContractFormProps = {
  id?: string
  // teamContainer: TeamContainer
  contractContrainer: ContractContainer
  clubContainer: ClubContainer
  cityContainer: CityContainer
  // competitionContainer: CompetitionContainer
  open: boolean
  disableCancelButton?: boolean
  onClose: (contractData?: { name: string; clubIds: string[] }) => Promise<boolean>
}
type ContractFormState = {
  loading: boolean
  name: string
  club?: ClubData
  clubFormOpen: boolean
}
const EmptyState: ContractFormState = {
  loading: false,
  name: '',
  club: undefined,
  clubFormOpen: false
}

export default class ContractForm extends React.Component<ContractFormProps, ContractFormState> {
  constructor(props: ContractFormProps) {
    super(props)
    this.state = EmptyState
  }

  componentDidMount() {}

  closeModal = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  handleTextPropertyChanged = (key: keyof ContractFormState) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    this.setState({ ...this.state, [key]: value })
  }

  handleBooleanPropertyChanged = (key: keyof ContractFormState) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked
    this.setState({ ...this.state, [key]: value })
  }

  openClubForm = () => {
    this.setState({ clubFormOpen: true })
  }

  handleClubChange = (club: ClubData) => {
    this.setState({ club })
  }

  closeClubForm = (club?: ClubData) => {
    if (club) {
      this.setState({ club, clubFormOpen: false })
    } else {
      this.setState({ clubFormOpen: false })
    }
  }

  handleCancleClick = () => {
    this.setState(EmptyState)
    this.closeModal()
  }

  // validateInput = () => {
  //   //TODO: Validation Code
  //   return
  //   // return false
  // }

  handleSaveClick = async () => {
    if (this.state.name !== '' && this.state.club !== undefined) {
      this.setState({ loading: true })
      const body = {
        name: this.state.name,
        clubIds: [this.state.club.RowKey]
      }

      // const contract = await this.props.contractContainer.save(body)
      const success = await this.props.onClose(body)
      this.setState({ loading: false })
    }
  }

  validateInput = () => {
    return this.state.name && this.state.name !== '' && this.state.club
  }

  render() {
    const lang = checkLanguages()
    return (
      <div className='form contractForm'>
        {/* <mobiscroll.Button id={this.props.id} onClick={this.onMainInputClick} disabled={this.props.disabled}>
          {this.props.label}
        </mobiscroll.Button> */}
        <mobiscrollV5.Popup
          closeOnOverlayClick={this.props.disableCancelButton}
          isOpen={this.props.open}
          onClose={this.closeModal}
          theme='ios'
          themeVariant='dark'
        >
          <div style={{ margin: '0 -16px' }}>
            <mobiscroll.FormGroupTitle>
              <h2>
                {i18n.t('forms.contract.title')}
                {/* {!props.cameraLoading && <AiswSpinner className='inline' />} */}
              </h2>
              <p>{i18n.t('forms.contract.text1')}</p>
              <p>{i18n.t('forms.contract.text2')}</p>
            </mobiscroll.FormGroupTitle>
            {/* Top Full Length */}
            <div className='mbsc-grid mbsc-no-padding' style={{ marginTop: '-24px' }}>
              <div className='mbsc-row'>
                <div className='mbsc-col mbsc-col-6 left'>
                  {/* LEFT */}
                  <mobiscroll.Input
                    onChange={this.handleTextPropertyChanged('name')}
                    type='text'
                    placeholder={i18n.t('general.name')}
                    lang={lang}
                    value={this.state.name}
                    disabled={this.state.loading}
                  >
                    {i18n.t('general.name')}
                  </mobiscroll.Input>
                </div>
                <div className='mbsc-col mbsc-col-6 right'>
                  {/* Right */}
                  <FilteredSelect<ClubData>
                    label={i18n.t('forms.team.club')}
                    id='TeamSelectHome'
                    disabled={this.state.loading}
                    value={this.state.club}
                    onChange={this.handleClubChange}
                    onNewEntryNeeded={this.openClubForm}
                    newItemLabel={i18n.t('forms.club.newClub')}
                    urlParameters={`&kind=skyhook-beta-cluball&fuzzy=true&match=name`}
                    defaultSelection={this.props.clubContainer.getClubList()}
                  ></FilteredSelect>
                </div>
              </div>
            </div>

            {/* <mobiscroll.FormGroup className='controls'>
              {!this.props.disableCancelButton && (
                <mobiscroll.Button onClick={this.handleCancleClick}>
                  {i18n.t('general.cancel')}
                </mobiscroll.Button>
              )}
              <mobiscroll.Button onClick={this.handleSaveClick}>{i18n.t('general.save')}</mobiscroll.Button>
            </mobiscroll.FormGroup> */}

            <mobiscroll.FormGroup style={{ marginBottom: '10px' }}>
              <div className='mbsc-grid'>
                <div className='mbsc-row'>
                  <div className='mbsc-col mbsc-col-xl-6' style={{ paddingRight: '0.3em' }}>
                    {!this.props.disableCancelButton && (
                      <mobiscroll.Button
                        onClick={this.handleCancleClick}
                        className={'round blue fullWidth'}
                        disabled={this.state.loading}
                        style={{ height: '36px', padding: '0', lineHeight: '0' }}
                      >
                        {i18n.t('general.cancel')}
                      </mobiscroll.Button>
                    )}
                  </div>
                  <div className='mbsc-col mbsc-col-xl-6' style={{ paddingLeft: '0.3em' }}>
                    <mobiscroll.Button
                      onClick={this.handleSaveClick}
                      className={'round blue fullWidth'}
                      disabled={this.state.loading || !this.validateInput()}
                      style={{ height: '36px', padding: '0', lineHeight: '0' }}
                    >
                      {this.state.loading ? (
                        <div style={{ height: '18px', width: '18px', marginLeft: '46%' }}>
                          <AiswSpinner />
                        </div>
                      ) : (
                        i18n.t('general.save')
                      )}
                    </mobiscroll.Button>
                  </div>
                </div>
              </div>
            </mobiscroll.FormGroup>
          </div>
        </mobiscrollV5.Popup>
        <ClubForm
          open={this.state.clubFormOpen}
          clubContainer={this.props.clubContainer}
          cityContainer={this.props.cityContainer}
          onClose={this.closeClubForm}
        />
      </div>
    )
  }
}
