import React, { MouseEvent } from 'react'
import mobiscroll from '@mobiscroll/react'
import AiswSpinner from '../helper/aiswSpinner/aiswSpinner'
import PageWrapper from '../../helper/PageWrapper'
import i18 from 'i18next'
import circleIcon from '../../img/icons/check_circle.svg'
import deleteIcon from '../../img/icons/delete.svg'
import field from './field.svg'
import StaigeButton from '../../components/styles/Button'
import i18n from '../../languages/i18n'
import LoadingSpinner from '../helper/loadingSpinner/loadingSpinner'

import contractContainer, { ContractContainer } from '../../services/contractContainer'
import { RouteComponentProps } from 'react-router'
import { isAxiosError } from '../../helper/UtilityFunctions'
import { BrowserInfoContainer } from '../../services/browserInfoContainer'
import { CameraData, Role } from '@soccerwatch/common'
import { ClosedDueStates, WebRTCContainer } from '../../services/webrtcContainer'
import { searchAndSortGetCallAs } from '../../services/serviceHelper'
import {
  HomePosition,
  HomePositionStatusResponse,
  deleteCameraCornerPoints,
  getCameraCornerPointsAll,
  getCameraCornerPointsFieldName,
  getCameraHomePosition,
  setCameraHomePosition,
  updateCameraHomePositionName
} from '../../api/api-camera'

import './FieldMask.scss'
import cameraContainer from '../../services/cameraContainer'

function wait(t: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, t)
  })
}

const connectionClosedStrings = {
  [ClosedDueStates.CAMERA_IN_USE]: {
    title: 'matchfieldCalibration.deviceBusyTitle',
    message: 'matchfieldCalibration.deviceBusyText'
  },
  [ClosedDueStates.CAMERA_NOT_REACHABLE]: {
    title: 'matchfieldCalibration.connectionFailedTitle',
    message: 'matchfieldCalibration.connectionFailedText'
  },
  [ClosedDueStates.ERROR]: {
    title: 'matchfieldCalibration.connectionErrorTitle',
    message: 'matchfieldCalibration.connectionErrorText'
  },
  [ClosedDueStates.TIMEOUT]: {
    title: 'matchfieldCalibration.connectionClosedTitle',
    message: 'matchfieldCalibration.connectionClosedText'
  },
  [ClosedDueStates.TIMEOUT_OR_ERROR]: {
    title: 'matchfieldCalibration.connectionClosedTitle',
    message: 'matchfieldCalibration.connectionClosedText'
  }
}

const markerData = [
  { color: 'red', text: 'Unten Links', id: 'MarkerUL' },
  { color: 'blue', text: 'Oben Links', id: 'MarkerOL' },
  { color: 'green', text: 'Oben Rechts', id: 'MarkerOR' },
  { color: 'yellow', text: 'Unten Rechts', id: 'MarkerUR' }
]

const defaultCorners = [1713, 1274, 7, 1289, 3823, 9, 2127, 2539]

type FieldMaskPageProps = RouteComponentProps & {
  userLoggedIn: boolean
  webrtcSrc: MediaStream | undefined
  webrtcReady: boolean
  cameraId: string
  camera: CameraData
  webrtcContainer: WebRTCContainer
  contractContainer: ContractContainer
  sensorSwitchingAt: number
  fieldMask?: {
    lastCalibration: unknown
  }
  awaitingNewImages: boolean
  browserContainer: BrowserInfoContainer
  connectionClosed?: ClosedDueStates
  cameraUploading?: boolean
  fieldName?: string
  isNew?: boolean
  homeposition?: boolean
}

type FieldMaskStateType = {
  // lastUpdate: number
  currentImagesFrom: Date | undefined
  currentStep: number
  calButtonOpen: boolean
  loadingFields: boolean
  turnVideoClass: string

  currentField: number
  fieldNames: string[]
  history: number[][][]
  fieldCorners: number[][]
  working: boolean
  streamActive: boolean
  unsuportedBrowser: boolean

  showCameraView: boolean
  showStreamModal: boolean
  showWebRtcStats: boolean
  showWebRtcDevCtrl: boolean
  showDownloadButton: boolean
  logFrameInformation: boolean

  newFieldname: string
  editMode: boolean
  useColorField: boolean
  changingSensor: boolean
  calibrationRoutine: boolean
  useCalibrationRoutine: boolean
  usePixelId: boolean
  pixelIdTimeout: number
  connectionStarted: boolean
  connectionClosed: boolean
  openPopup: boolean

  activeLense: string
  sensorLoading: Array<string>
  finishedLoading: boolean
  currentCorners: number[]
  editCurrentCorners: boolean
  currentFieldName: string
  newCorner: any
  homePositionName: string
  homePosition: [number, number]
  fov: number
}

export default class FieldMaskPage extends React.Component<FieldMaskPageProps, FieldMaskStateType> {
  video: React.RefObject<HTMLVideoElement>
  placeholder: React.RefObject<HTMLImageElement>
  closedFromError = false
  constructor(props: FieldMaskPageProps) {
    super(props)
    this.video = React.createRef()
    this.placeholder = React.createRef()
    const unsuportedBrowser = !this.props.browserContainer.isSafari && !this.props.browserContainer.isChrome

    this.state = {
      // lastUpdate: 0,
      currentImagesFrom: undefined,
      currentStep: 0,
      calButtonOpen: false,
      loadingFields: true,
      turnVideoClass: '',
      history: [],

      currentField: 0,
      fieldNames: ['default'],
      fieldCorners: [defaultCorners],
      working: false,
      streamActive: false,
      unsuportedBrowser,

      showCameraView: false,
      showStreamModal: false,
      showWebRtcStats: false,
      showWebRtcDevCtrl: false,
      showDownloadButton: true,
      logFrameInformation: false,

      newFieldname: '',
      editMode: false,
      useColorField: false,
      changingSensor: false,
      calibrationRoutine: false,
      useCalibrationRoutine: true,
      usePixelId: true,
      pixelIdTimeout: 1000,

      connectionStarted: false,
      connectionClosed: false,
      openPopup: false,

      activeLense: 'string',
      sensorLoading: [],
      finishedLoading: false,
      currentCorners: [],
      editCurrentCorners: false,
      currentFieldName: '',
      newCorner: {},
      homePositionName: this.props.camera.homePositionName || 'Home Position',
      homePosition: [1054, 2045],
      fov: 0
    }
    !unsuportedBrowser && !this.props.homeposition && this.getCornerPoints()
    !unsuportedBrowser && this.props.homeposition && this.getHomePosition()
  }

  componentDidMount = () => {
    this.props.contractContainer.setLockContractSelect(true)
    if (this.state.unsuportedBrowser) {
      return
    }
    if (this.props.userLoggedIn && !this.props.webrtcReady) {
      this.props.webrtcContainer.websocketServerConnect()
    }
    // If Websocket is already connected: Start Camera-Stream
    if (!this.state.connectionStarted && this.props.webrtcReady) {
      console.log('Try Connecting to Camera')
      this.setState({ connectionStarted: true })
      this.connectToCamera()
    }

    this.setState({
      editMode: Boolean(this.props.isNew)
    })
  }

  videoInit = false
  componentDidUpdate = (lastProps: FieldMaskPageProps) => {
    if (this.state.unsuportedBrowser) {
      return
    }
    if (
      this.state.useCalibrationRoutine &&
      !this.state.usePixelId &&
      this.video.current &&
      this.props.sensorSwitchingAt !== -1 &&
      lastProps.sensorSwitchingAt !== this.props.sensorSwitchingAt
    ) {
      if (this.props.webrtcContainer.state.useVideoTime) {
        const diff = this.props.sensorSwitchingAt - this.video.current.currentTime
        console.log('Sensor Switching in', diff, 'Seconds')
      }
      this.video.current.requestVideoFrameCallback(this.waitForNewSensorFrame)
    }
    if (!lastProps.userLoggedIn && this.props.userLoggedIn && !this.props.webrtcReady) {
      console.log('Websocket Connect')
      this.props.webrtcContainer.websocketServerConnect()
    }
    // WebRTC just became Ready: Connect To Camera
    if (
      !this.state.connectionStarted &&
      !lastProps.webrtcReady &&
      this.props.webrtcReady &&
      !this.state.connectionClosed
    ) {
      console.log('Try Connecting to Camera')
      this.setState({ connectionStarted: true })
      this.connectToCamera()
    }

    if (!lastProps.webrtcSrc && this.props.webrtcSrc) {
      this.setPlaceholderImages()
    }

    // Show user a message based on the Reason our WebRTC Session Closed
    if (this.props.connectionClosed && !lastProps.connectionClosed && !this.closedFromError) {
      this.closedFromError = true
      const strings = connectionClosedStrings[this.props.connectionClosed]
      // this.setState({ connectionClosed: true, streamActive: false, currentImagesFrom: undefined })
      mobiscroll.alert({
        title: i18.t(strings.title),
        message: i18.t(strings.message),
        callback: () => {
          this.props.history.push(`/cameramonitor/${this.props.cameraId}`)
        }
      })
    }
    if (!lastProps.webrtcSrc && this.props.webrtcSrc) {
      console.log('Incomming Videostream')
      this.handleUpdateVideoStream()
    }
  }

  componentWillUnmount = () => {
    console.log('Component Unmount')
    this.props.contractContainer.setLockContractSelect(false)
    if (!this.closedFromError) {
      this.props.webrtcContainer.resetConnectionsAndState()
    }
  }

  handleUpdateVideoStream = () => {
    // console.log(this.props.webrtcSrc, this.video.current, this.state.currentImagesFrom)
    if (this.props.webrtcSrc && this.video.current) {
      const stream = this.props.webrtcSrc as MediaStream
      this.video.current.srcObject = stream
      this.setState({ streamActive: true })
    } else {
      //TODO: Notify Error
    }
  }

  waitForNewSensorFrame = async (now: number, meta: VideoFrameMetadata) => {
    if (this.video.current) {
      const timeProp = this.props.webrtcContainer.state.useVideoTime
        ? this.video.current.currentTime
        : meta.rtpTimestamp
      // const diff = this.props.sensorSwitchingAt - timeProp
      // +10000 is used as Frame Buffer
      if ((timeProp ?? NaN) > this.props.sensorSwitchingAt + 10000) {
        this.props.webrtcContainer.sensorSwitched()
        this.setState({ changingSensor: false })
        if (this.state.calibrationRoutine) {
          const sensor = parseInt(this.props.webrtcContainer.state.currentSensor, 10)
          // Uncomment for TimeBuffer
          // await wait(100)
          this.extractCalibrationImage(sensor)
          if (sensor >= 5) {
            this.endCalibrationRoutine()
          } else {
            this.setState({ changingSensor: true }, () => {
              this.props.webrtcContainer.sensorChange(sensor + 1 + '')
            })
          }
        }
      } else {
        this.video.current.requestVideoFrameCallback(this.waitForNewSensorFrame)
      }
    }
  }

  toggleFrameLog = () => {
    const logFrameInformation = !this.state.logFrameInformation
    this.setState({ logFrameInformation }, () => {
      this.onStreamFrame(undefined, undefined)
    })
  }

  onStreamFrame = (now?: number, meta?: VideoFrameMetadata) => {
    if (this.video.current && this.state.logFrameInformation) {
      const time = meta?.rtpTimestamp ? meta.rtpTimestamp / 1000000 : 0
      this.video.current.requestVideoFrameCallback(this.onStreamFrame)
    }
  }

  readPixelIdFromCurrentFrame = () => {
    if (this.video.current) {
      const canvas = document.createElement('canvas')
      canvas.width = this.video.current.videoWidth
      canvas.height = this.video.current.videoHeight
      const context = canvas.getContext('2d')
      if (!context) {
        console.error('Could not get 2d-Context of Canvas')
        throw new Error('Could not get 2d-Context of Canvas')
      }
      context.drawImage(this.video.current, 0, 0, canvas.width, canvas.height)
      const data = context.getImageData(0, 0, 4, 1).data
      const four = data[0] > 150 && data[1] > 150 && data[2] > 150 ? 8 : 0
      const three = data[4] > 150 && data[5] > 150 && data[6] > 150 ? 4 : 0
      const two = data[8] > 150 && data[9] > 150 && data[10] > 150 ? 2 : 0
      const one = data[12] > 150 && data[13] > 150 && data[14] > 150 ? 1 : 0
      return one + two + three + four
    }
    console.error('Tried to Read PixelId without Videostream')
    return -1
  }

  onStreamReady = () => {
    console.log('Video Canplay :D')
    this.video.current && this.video.current.paused && this.video.current.play()
    if (!this.state.currentImagesFrom) {
      const calibrationFn = this.state.useCalibrationRoutine
        ? this.startCalibrationRoutine
        : this.extractCalibrationImages
      this.setState({ showCameraView: true }, calibrationFn)
    }
  }

  getHomePosition = async () => {
    try {
      const callAs = searchAndSortGetCallAs([
        Role.admin,
        contractContainer.getHighestRelevantRoleForCurrentContract()
      ])
      const homePositionData = await getCameraHomePosition(this.props.cameraId, callAs)
      if ((homePositionData as HomePositionStatusResponse).status) {
        this.setState({ loadingFields: false })
        return
      }
      this.setState({
        loadingFields: false,
        homePosition: (homePositionData as HomePosition).grid_pos,
        fov: (homePositionData as HomePosition).fov
      })
    } catch (err) {
      console.error(err)
      this.setState({ loadingFields: false })
    }
  }

  getCornerPoints = async () => {
    try {
      const callAs = searchAndSortGetCallAs([
        Role.admin,
        contractContainer.getHighestRelevantRoleForCurrentContract()
      ])
      const res = await getCameraCornerPointsAll(this.props.cameraId, callAs)
      if (res.status === 200) {
        const fieldNames: string[] = []
        const fieldCorners: number[][] = []
        res.data.forEach((dataset) => {
          if (!dataset.Corners.corners.length) {
            dataset.Corners.corners = JSON.parse(JSON.stringify(defaultCorners))
          }
          if (this.props.fieldName === dataset.fieldName) {
            fieldNames.push(dataset.fieldName)
            fieldCorners.push(dataset.Corners.corners)
          }
        })

        this.setState({
          fieldNames,
          fieldCorners,
          loadingFields: false,
          currentCorners: fieldCorners[0],
          currentFieldName: fieldNames[0]
        })
        return
      }
      console.warn('Get Cornerpoints for', this.props.cameraId, ' Failed with Status', res.status)
      this.setState({ loadingFields: false })
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        console.warn('Get Cornerpoints for', this.props.cameraId, ' Failed with Status', err.response?.status)
      } else {
        console.warn('An unknown error occurred:', err)
      }

      this.setState({ loadingFields: false })
    }
  }

  uploadCornerPoints = async () => {
    this.setState({ working: true })
    const currentCorners = this.state.currentCorners
    const currentFieldName = this.state.currentFieldName

    let body = { Corners: { corners: currentCorners } }
    if (this.props.isNew) {
      let newCorner: Array<number> = []
      for (const [key, value] of Object.entries(this.state.newCorner)) {
        newCorner.push(Number(value))
      }
      body = { Corners: { corners: newCorner } }
    }

    const callAs = searchAndSortGetCallAs([
      Role.admin,
      contractContainer.getHighestRelevantRoleForCurrentContract()
    ])
    const res = await getCameraCornerPointsFieldName(this.props.cameraId, currentFieldName, body, callAs)
    this.setState({ working: false })
    if (res.status === 'Ok') {
      this.props.history.push(`/cameramonitor/${this.props.cameraId}`)
      return
    }
    console.error('Could not save Fieldmask:', res)
    // this.setState({ error: 'Beim Speichern der Feldmaske ist ein Fehler aufgetreten. Bitte versuchen sie es gleich erneut.' })
    alert(i18.t('matchfieldCalibration.saveError'))
  }

  getCoordinatesFromClick = (imageId: string, e: MouseEvent) => {
    const originalWidth = 1920
    const originalHeight = 1280
    const target = e.target as Element
    const rect = target.getBoundingClientRect()
    const width = rect.right - rect.left
    const height = rect.bottom - rect.top
    let x = 0,
      y = 0
    const elementX = ((e.clientX - rect.left) / width) * 100
    const elementY = ((e.clientY - rect.top) / height) * 100
    if (imageId !== '0_0' && imageId !== '1_1') {
      x = (e.clientX - rect.left) * (originalWidth / width)
      y = (e.clientY - rect.top) * (originalHeight / height)
    } else if (imageId === '0_0') {
      // Image 0_0 is turned 90Degree so, ClientY is used to Calc ImageX
      // Also originalHeight & originalWidth must be swapped
      // Turning Right: Invert Height
      y = originalHeight - (e.clientX - rect.left) * (originalHeight / width)
      x = (e.clientY - rect.top) * (originalWidth / height)
    } else if (imageId === '1_1') {
      // Image 0_0 is turned 90Degree so, ClientY is used to Calc ImageX
      // Also originalHeight & originalWidth must be swapped
      // Turning Right: Invert Width
      y = (e.clientX - rect.left) * (originalHeight / width)
      x = originalWidth - (e.clientY - rect.top) * (originalWidth / height)
    }

    const coord = imageId.split('_')
    const xCoord = parseFloat(coord[0])
    const yCoord = parseFloat(coord[1])
    const GridX = x + xCoord * originalWidth
    const GridY = y + yCoord * originalHeight
    return { GridX, GridY, elementX, elementY }
  }

  getCoordinatesFromPoint(xIn: number, yIn: number) {
    const originalWidth = 1920
    const originalHeight = 1280
    const XCoord = Math.floor(xIn / originalWidth)
    const yCoord = Math.floor(yIn / originalHeight)
    let imageId = XCoord + '_' + yCoord
    if (XCoord > 1 || yCoord > 2 || isNaN(XCoord) || isNaN(yCoord)) {
      console.warn('<GetCoordinatesFromPoint>: Calculated ImageID', imageId, 'is out of Bound')
      imageId = 'OUT_OF_BOUND'
    }
    const elementX = xIn % originalWidth
    const elementY = yIn % originalHeight
    let x = (elementX / originalWidth) * 100
    let y = (elementY / originalHeight) * 100
    if (imageId === '0_0') {
      const tmp = x
      x = 100 - y
      y = tmp
    } else if (imageId === '1_1') {
      const tmp = 100 - x
      x = y
      y = tmp
    }
    return { x, y, imageId }
  }

  addMarker = (imageId: string, x: number, y: number, manualStep?: number) => {
    if (imageId === 'OUT_OF_BOUND') {
      return
    }
    const image = document.getElementById(imageId)
    if (!image) {
      return
    }
    const step = manualStep ?? this.state.currentStep
    const markerMeta = markerData[step]
    const exists = document.getElementById(markerMeta.id)
    if (exists) {
      exists.remove()
    }
    const marker = document.createElement('div')
    const markerX = document.createElement('div')
    marker.id = markerMeta.id
    markerX.textContent = 'X'
    markerX.classList.add('markerX')
    marker.classList.add('marker')
    marker.classList.add(markerMeta.color)
    if (image.classList.contains('turn90Left')) {
      marker.style.left = y + '%'
      marker.style.bottom = x + '%'
    } else if (image.classList.contains('turn90Right')) {
      marker.style.right = y + '%'
      marker.style.top = x + '%'
    } else {
      marker.style.left = x + '%'
      marker.style.top = y + '%'
    }
    marker.appendChild(markerX)
    image.appendChild(marker)
  }

  handleSetHomePosition = (e: MouseEvent) => {
    const imageCords = this.sensorToCoordMapping[parseInt(this.props.webrtcContainer.state.currentSensor, 10)]
    const imageId = `${imageCords.x}_${imageCords.y}`
    const GridCoordinates = this.getCoordinatesFromClick(imageId, e)
    const x = Math.round(GridCoordinates.GridX)
    const y = Math.round(GridCoordinates.GridY)
    this.setState({ homePosition: [x, y] })
    const markerData = this.getCoordinatesFromPoint(x, y)
    this.addMarker(markerData.imageId, markerData.x, markerData.y)
    this.setTemporaryFieldMarkerToVideo()
  }

  handleVideoClick = async (e: MouseEvent) => {
    if (this.props.homeposition) {
      return this.handleSetHomePosition(e)
    }
    if (!this.state.editMode) {
      return
    }
    const imageCords = this.sensorToCoordMapping[parseInt(this.props.webrtcContainer.state.currentSensor, 10)]
    const imageId = `${imageCords.x}_${imageCords.y}`
    const GridCoordinates = this.getCoordinatesFromClick(imageId, e)
    const fieldCorners = JSON.parse(JSON.stringify(this.state.fieldCorners))
    await this.pushToHistory(fieldCorners)
    const corners = fieldCorners[this.state.currentField]

    let newCorner = this.state.newCorner

    this.addMarker(imageId, GridCoordinates.elementX, GridCoordinates.elementY)
    switch (this.state.currentStep) {
      case 0:
        // UL
        if (corners && corners[0]) {
          corners[0] = Math.round(GridCoordinates.GridX)
        }
        if (corners && corners[1]) {
          corners[1] = Math.round(GridCoordinates.GridY)
        }

        if (this.props.isNew) {
          newCorner = {
            ...newCorner,
            0: Math.round(GridCoordinates.GridX),
            1: Math.round(GridCoordinates.GridY)
          }
        }
        break
      case 1:
        // Ol
        if (corners && corners[2]) {
          corners[2] = Math.round(GridCoordinates.GridX)
        }
        if (corners && corners[3]) {
          corners[3] = Math.round(GridCoordinates.GridY)
        }
        if (this.props.isNew) {
          newCorner = {
            ...newCorner,
            2: Math.round(GridCoordinates.GridX),
            3: Math.round(GridCoordinates.GridY)
          }
        }
        break
      case 2:
        // OR
        if (corners && corners[4]) {
          corners[4] = Math.round(GridCoordinates.GridX)
        }
        if (corners && corners[5]) {
          corners[5] = Math.round(GridCoordinates.GridY)
        }
        if (this.props.isNew) {
          newCorner = {
            ...newCorner,
            4: Math.round(GridCoordinates.GridX),
            5: Math.round(GridCoordinates.GridY)
          }
        }
        break
      case 3:
        // UR
        if (corners && corners[6]) {
          corners[6] = Math.round(GridCoordinates.GridX)
        }
        if (corners && corners[7]) {
          corners[7] = Math.round(GridCoordinates.GridY)
        }
        if (this.props.isNew) {
          newCorner = {
            ...newCorner,
            6: Math.round(GridCoordinates.GridX),
            7: Math.round(GridCoordinates.GridY)
          }
        }
        break
      case 4:
        return
    }
    // const pointName = `point${this.state.currentStep}Set`
    let currentStep = this.state.currentStep
    if (currentStep >= 3) {
      currentStep = 0
    } else if (currentStep < 0) {
      currentStep = 3
    } else {
      currentStep += 1
    }
    fieldCorners[this.state.currentField] = corners
    console.log('FieldCorners', fieldCorners, this.state.currentField)
    this.setState(
      {
        fieldCorners,
        currentCorners: corners,
        newCorner,
        currentStep,
        editCurrentCorners: true,
        showStreamModal: false,
        activeLense: ''
      },
      () => {
        this.setTemporaryFieldMarkerToVideo()
        // this.uploadCornerPoints()
      }
    )
  }

  handleClick = (imageId: string) => async () => {
    if (!this.state.currentImagesFrom) {
      return
    }
    const sensor = this.sensorToCoordMapping.findIndex((e) => `${e.x}_${e.y}` === imageId)
    const changingSensor = this.props.webrtcContainer.state.currentSensor !== sensor + ''
    if (changingSensor) {
      const imageSrc = (document.getElementById('img' + imageId) as HTMLImageElement).src
      if (this.placeholder.current) {
        this.placeholder.current.src = imageSrc
      }

      this.props.webrtcContainer.sensorChange(sensor + '')
      this.watchPixelId()
    }
    let turnVideoClass = ''
    if (imageId === '0_0') {
      turnVideoClass = 'turn90Left'
    } else if (imageId === '1_1') {
      turnVideoClass = 'turn90Right'
    }
    this.setTemporaryFieldMarkerToVideo()
    this.setState({ showStreamModal: true, turnVideoClass, activeLense: imageId })
  }

  getCurrentStepText = () => {
    switch (this.state.currentStep) {
      case 0:
        return <span className=''>{i18.t('matchfieldCalibration.ll')}</span>
      case 1:
        return <span className=''>{i18.t('matchfieldCalibration.ul')}</span>
      case 2:
        return <span className=''>{i18.t('matchfieldCalibration.ur')}</span>
      case 3:
        return <span className=''>{i18.t('matchfieldCalibration.lr')}</span>
      case 4:
        return
    }
  }

  stepForward = () => {
    if (this.state.currentStep < 3) {
      this.setState({ currentStep: this.state.currentStep + 1 })
    } else {
      this.setState({ currentStep: 0 })
    }
  }

  goToStep = (currentStep: number) => () => {
    if (currentStep >= 0 && currentStep <= 3) {
      this.setState({ currentStep })
    } else if (currentStep > 3) {
      this.setState({ currentStep: 0 })
    } else if (currentStep < 0) {
      this.setState({ currentStep: 3 })
    }
  }

  setTemporaryFieldMarkerToVideo = () => {
    if (this.video.current) {
      this.clearVideoMarker()
      const imageCoords =
        this.sensorToCoordMapping[parseInt(this.props.webrtcContainer.state.currentSensor, 10)]
      const imageId = `${imageCoords.x}_${imageCoords.y}`
      const image = document.getElementById(`img${imageId}`)?.parentElement
      if (image) {
        Array.from(image.querySelectorAll('.marker')).forEach((marker) => {
          const copy = marker.cloneNode(true) as HTMLElement
          copy.id = copy.id + '_copy'
          this.video.current?.parentElement?.appendChild(copy)
        })
      } else {
        //TODO: Handle this pretty unlikly error
        console.error('Image Element missing. How did that happen?')
      }
    }
  }

  clearVideoMarker = () => {
    console.log('Clearing Marker')
    if (this.video.current) {
      Array.from(this.video.current.parentElement?.querySelectorAll('.marker') ?? []).forEach((marker) => {
        marker.remove()
      })
    }
  }

  setFieldMarkerFromActiveCorners() {
    console.log('Setting Corners!')
    const corners = this.state.fieldCorners[this.state.currentField]

    if (corners) {
      let step = 0
      for (let i = 0; i < 8; i = i + 2) {
        console.log(corners[i], corners[i + 1])
        const markerData = this.getCoordinatesFromPoint(corners[i], corners[i + 1])
        this.addMarker(markerData.imageId, markerData.x, markerData.y, step++)
      }
    }
  }

  removeFieldMarkerFromActiveCorners() {
    Array.from(document.querySelectorAll('.marker')).forEach((m) => m.remove())
  }

  // setFieldName = (e: ChangeEvent<HTMLSelectElement>) => {
  setFieldName = (event: { valueText?: string | undefined }, i: any) => {
    const currentField = i.getVal()
    // const currentField = this.state.fieldNames.findIndex((name) => name === event.valueText)
    this.setState({ currentField }, this.setFieldMarkerFromActiveCorners)
  }

  createNewMatchfield = () => {
    const newFieldname = this.state.newFieldname
    if (newFieldname === '') {
      return false
    }
    const fieldNames = JSON.parse(JSON.stringify(this.state.fieldNames))
    if (this.state.fieldNames.includes(newFieldname)) {
      alert(i18.t('matchfieldCalibration.fieldExists', { newFieldname }))
      return false
    }
    const fieldCorners = JSON.parse(JSON.stringify(this.state.fieldCorners))
    const newLength = fieldNames.push(newFieldname)
    fieldCorners.push(defaultCorners)
    this.setState({ fieldNames, fieldCorners, currentField: newLength - 1, newFieldname: '' }, () => {
      // this.uploadCornerPoints()
      this.setFieldMarkerFromActiveCorners()
    })
    return true
  }

  deleteField = async () => {
    if (this.state.currentField === 0) {
      return
    }
    this.setState({ working: true })
    const fieldNames = JSON.parse(JSON.stringify(this.state.fieldNames))
    const fieldCorners = JSON.parse(JSON.stringify(this.state.fieldCorners))
    const fieldName = this.state.fieldNames[this.state.currentField]
    const callAs = searchAndSortGetCallAs([
      Role.admin,
      contractContainer.getHighestRelevantRoleForCurrentContract()
    ])
    const res = await deleteCameraCornerPoints(this.props.cameraId, fieldName, callAs)
    if (res.status !== 200) {
      this.setState({ working: false })
      console.error('Could not Delete Field:', res)
      return
    }
    fieldNames.splice(this.state.currentField, 1)
    fieldCorners.splice(this.state.currentField, 1)
    const currentField = this.state.currentField - 1
    this.setState(
      { fieldNames, fieldCorners, currentField, working: false },
      this.setFieldMarkerFromActiveCorners
    )
  }

  connectToCamera = async () => {
    // console.log(this.props.cameraId)
    this.props.webrtcContainer.startStream(this.props.cameraId)
  }
  endCameraConnection = () => {
    this.props.webrtcContainer.resetConnectionsAndState()
    this.resetVideo()
    this.setState({ streamActive: false })
  }

  resetVideo = () => {
    if (this.video.current) {
      this.video.current.pause()
      this.video.current.src = ''
      this.video.current.load()
    }
  }

  setPlaceholderImages = () => {
    const img0_0 = document.getElementById('img0_0') as HTMLImageElement
    img0_0.src = '/img/tmp-DL.png'
    const img0_1 = document.getElementById('img0_1') as HTMLImageElement
    img0_1.src = '/img/tmp-TL.png'
    const img0_2 = document.getElementById('img0_2') as HTMLImageElement
    img0_2.src = '/img/tmp-TM.png'
    const img1_0 = document.getElementById('img1_0') as HTMLImageElement
    img1_0.src = '/img/tmp-TR.png'
    const img1_1 = document.getElementById('img1_1') as HTMLImageElement
    img1_1.src = '/img/tmp-DR.png'
    const img1_2 = document.getElementById('img1_2') as HTMLImageElement
    img1_2.src = '/img/tmp-DM.png'
  }

  // Since the Coordinate System is in regards to the Original Grid-View
  // to make the Math a bit more sensible, The Mapping inside the Html
  // Might apear a bit crooked. Here is a Visual Repesentation for your enjoyment:
  //
  //  0_1 | 0_2 | 1_0
  //  0_0 | 1_2 | 1_1
  //

  sensorToCoordMapping = [
    { x: 0, y: 0 }, //0
    { x: 0, y: 1 }, //1
    { x: 0, y: 2 }, //2
    { x: 1, y: 0 }, //3
    { x: 1, y: 1 }, //4
    { x: 1, y: 2 } //5
  ]

  startCalibrationRoutine = () => {
    if (this.video.current) {
      this.setState({ currentImagesFrom: undefined, calibrationRoutine: true })
      if (this.state.usePixelId) {
        this.watchPixelId()
      }
      this.setState({ changingSensor: true }, () => {
        console.log('Change', 0)
        this.props.webrtcContainer.sensorChange('0')
      })
    }
  }

  endCalibrationRoutine = (timeUpdateFunction?: () => void, resolve?: (value: unknown) => void) => {
    if (timeUpdateFunction && this.video.current) {
      this.video.current.removeEventListener('timeupdate', timeUpdateFunction)
    }
    this.setState({ currentImagesFrom: new Date(), calibrationRoutine: false }, () => {
      !this.props.homeposition && this.setFieldMarkerFromActiveCorners()
      if (this.props.homeposition) {
        const markerData = this.getCoordinatesFromPoint(
          this.state.homePosition[0],
          this.state.homePosition[1]
        )
        this.addMarker(markerData.imageId, markerData.x, markerData.y)
        this.setTemporaryFieldMarkerToVideo()
      }
      resolve && resolve(true)
    })
  }

  watchPixelId = () => {
    let startedWatchingAt = new Date().getTime()
    return new Promise((resolve) => {
      if (this.video.current) {
        const onTimeUpdate = () => {
          // console.log('TimeUpdate')
          let id = this.readPixelIdFromCurrentFrame()
          const now = new Date().getTime()
          const diff = now - startedWatchingAt
          // console.log(startedWatchingAt, now, diff)
          const currentId = this.props.webrtcContainer.state.currentSensor
          if (id + '' === currentId || diff > this.state.pixelIdTimeout) {
            // We we came here due to timeout:
            // Use the currentSensor from WebRtc as Id instead of the PixelId
            if (id + '' !== currentId) {
              console.warn(
                'FieldMask.watchPixelId(): Expected PixelId not found after',
                this.state.pixelIdTimeout,
                'MS. Timeout'
              )
              id = +currentId
            }
            this.setState({ changingSensor: false })
            startedWatchingAt = new Date().getTime()
            if (this.state.calibrationRoutine) {
              this.extractCalibrationImage(id)
              if (id === 5) {
                this.endCalibrationRoutine(onTimeUpdate, resolve)
                this.setState({
                  finishedLoading: true
                })
                return
              }
              this.setState({ changingSensor: true }, () => {
                console.log('Change', id + 1)
                this.props.webrtcContainer.sensorChange(id + 1 + '')
              })
            }
          }
        }
        console.log('Setting Timeupdate')
        this.video.current.addEventListener('timeupdate', onTimeUpdate)
      }
    })
  }

  extractCalibrationImage = (sensor: number) => {
    if (this.video.current && this.state.streamActive) {
      const video = this.video.current
      const canvas = document.createElement('canvas')
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      const { x, y } = this.sensorToCoordMapping[sensor]
      this.setImageToSource(x, y, canvas, video)
      this.setState({
        sensorLoading: [...this.state.sensorLoading, x + '_' + y],
        activeLense: x + '_' + y
      })
      console.log('Extracted')
    }
  }

  extractCalibrationImages = async () => {
    if (this.video.current && this.state.streamActive) {
      this.removeFieldMarkerFromActiveCorners()
      this.setState({ currentImagesFrom: undefined })
      const video = this.video.current
      const canvas = document.createElement('canvas')
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      if (this.props.webrtcContainer.state.currentSensor !== '0') {
        this.props.webrtcContainer.sensorChange('0')
        await wait(2000)
      } else {
        await wait(1000)
      }
      for (let i = 0; i < this.sensorToCoordMapping.length; i++) {
        const { x, y } = this.sensorToCoordMapping[i]
        this.setImageToSource(x, y, canvas, video)
        // console.log('Set Image To ', x, y)
        if (i + 1 < this.sensorToCoordMapping.length) {
          this.props.webrtcContainer.sensorChange(i + 1 + '')
          await wait(2000)
        }
      }
      this.setState({ currentImagesFrom: new Date() }, this.setFieldMarkerFromActiveCorners)
    }
  }

  downloadImage = () => {
    if (this.video.current && this.state.streamActive) {
      const video = this.video.current
      const canvas = document.createElement('canvas')
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      canvas.getContext('2d')?.drawImage(video, 0, 0, canvas.width, canvas.height)
      const img = canvas.toDataURL()
      const a = document.createElement('a')
      a.download = new Date().toISOString() + '.png'
      a.href = img
      document.body.appendChild(a)
      a.click()
      document.removeChild(a)
    }
  }

  setImageToSource = (x: number, y: number, canvas: HTMLCanvasElement, video: HTMLVideoElement) => {
    canvas.getContext('2d')?.drawImage(video, 0, 0, canvas.width, canvas.height)
    const img = canvas.toDataURL()
    const imageObject = document.getElementById(`img${x}_${y}`) as HTMLImageElement
    if (!imageObject) {
      console.error('Could not set Image on Coordinate', x, y, '. Please check your Code.')
      return
    }
    imageObject.src = img
  }

  toggleStreamModal = () => {
    const showStreamModal = !this.state.showStreamModal
    this.setState({ showStreamModal })
  }

  onChangeNewFieldname = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFieldname = e.currentTarget.value
    // console.log(newFieldname)
    this.setState({ newFieldname })
  }

  popupOnOkClick = () => {
    const created = this.createNewMatchfield()
    if (created) {
      this.setState({
        openPopup: false
      })
    }
  }

  popupOnCancelClick = () => {
    this.setState({ newFieldname: '', openPopup: false })
  }

  toggleEditMode = () => {
    const editMode = !this.state.editMode
    this.setState({ editMode })
  }

  getPlacementStringFromCurrentStep = () => {
    return ['DL', 'TL', 'TR', 'DR'][this.state.currentStep]
  }

  showBrowserUnsuportedMessage = () => {
    return (
      <PageWrapper headerTitle='Aufnahmeplan' linkTo='/'>
        <div className='mbsc-row .mbsc-justify-content-center .mbsc-align-content-center'>
          <div className='mbsc-col '>
            <h1> {i18.t('browserUnsuported.maintext')} </h1>
            <h2> {i18.t('browserUnsuported.subtext')} </h2>
          </div>
        </div>
      </PageWrapper>
    )
  }

  onSetBitrate = () => {
    // const value = e.currentTarget.value
    const bitrateObj = document.getElementById('Bitrate') as HTMLInputElement
    //            + is used to Parse Strings to Int
    const value = +bitrateObj.value
    if (!isNaN(value) && value > 0 && value < 100000) {
      this.props.webrtcContainer.bitrateChange(value)
    }
  }

  pushToHistory = (fieldCorners: number[][]): Promise<void> => {
    return new Promise((resolve) => {
      const corners = JSON.parse(JSON.stringify(fieldCorners))
      const history = JSON.parse(JSON.stringify(this.state.history))
      if (history.length > 100) {
        history.shift()
      }
      history.push(corners)
      this.setState({ history }, () => {
        resolve()
      })
    })
  }

  restoreFromHistory = () => {
    const history = JSON.parse(JSON.stringify(this.state.history))
    if (!history.length) {
      console.error('Tried to Pull from Empty History')
      return
    }

    const fieldCorners = history.pop()
    console.log('Restoring', fieldCorners[1], history)

    let currentStep = this.state.currentStep
    if (currentStep > 3) {
      currentStep = 0
    } else if (currentStep <= 0) {
      currentStep = 3
    } else {
      currentStep -= 1
    }

    this.setState({ fieldCorners, history, currentStep }, () => {
      this.setFieldMarkerFromActiveCorners()
      this.setTemporaryFieldMarkerToVideo()
      // this.uploadCornerPoints()
    })
  }

  handleOpenPopup = () => {
    this.setState({
      openPopup: !this.state.openPopup
    })
  }

  renderBottomBtns = () => {
    let isNew =
      this.state.newCorner[0] === undefined ||
      this.state.newCorner[1] === undefined ||
      this.state.newCorner[2] === undefined ||
      this.state.newCorner[3] === undefined ||
      this.state.newCorner[4] === undefined ||
      this.state.newCorner[5] === undefined ||
      this.state.newCorner[6] === undefined ||
      this.state.newCorner[7] === undefined
    if (!this.props.isNew) {
      isNew = false
    }
    console.log(
      'FieldCorners?',
      this.state.currentFieldName,
      this.state.finishedLoading,
      this.state.editCurrentCorners
    )
    return (
      <StaigeButton
        label={this.props.isNew ? i18n.t('general.create') : i18n.t('general.save')}
        style='white'
        iconMargin='0 -4px 0 4px'
        disabled={
          isNew ||
          (this.state.fieldCorners &&
            this.state.fieldCorners[0] &&
            this.state.fieldCorners[0].length !== 8) ||
          !this.state.finishedLoading ||
          !this.state.editMode ||
          this.state.currentFieldName === '' ||
          this.state.currentFieldName === undefined
        }
        onClick={() => this.uploadCornerPoints()}
      />
    )
  }

  saveHomePosition = async () => {
    try {
      this.setState({ working: true })
      const callAs = searchAndSortGetCallAs([
        Role.admin,
        contractContainer.getHighestRelevantRoleForCurrentContract()
      ])
      if (this.state.homePositionName !== this.props.camera.homePositionName) {
        await updateCameraHomePositionName(this.props.camera.RowKey, this.state.homePositionName, callAs)
      }
      const homePosition: HomePosition = {
        grid_pos: this.state.homePosition,
        fov: this.state.fov
      }
      await setCameraHomePosition(this.props.camera.RowKey, homePosition, callAs)
      // ? In spite if its name it updates the data of a single camera
      await cameraContainer.setSingleCamera(this.props.camera.RowKey)
      this.props.history.push(`/cameramonitor/${this.props.cameraId}`)
    } catch (err) {
      console.error(err)
    }
    this.setState({ working: false })
  }

  renderHomePositionSaveButton = () => {
    return (
      <StaigeButton
        label={i18n.t('general.save')}
        style='white'
        iconMargin='0 -4px 0 4px'
        disabled={!this.state.finishedLoading || this.state.working || !this.state.fov || this.state.fov < 0}
        onClick={() => {
          this.saveHomePosition()
        }}
      ></StaigeButton>
    )
  }

  render() {
    if (this.state.unsuportedBrowser) {
      return this.showBrowserUnsuportedMessage()
    }

    return (
      <PageWrapper
        headerTitle={`${i18n.t('fieldMask.headerTitleLoad')} ${this.props.camera?.hardwarePlatform} – ${
          this.props.camera?.RowKey
        }`}
        linkTo={`/cameramonitor/${this.props.camera.RowKey}`}
        simpleTopbar
        bottomBar={this.props.homeposition ? this.renderHomePositionSaveButton : this.renderBottomBtns}
      >
        <div className='aab'>
          <div
            className={`aa ${this.state.turnVideoClass}`}
            style={this.state.showStreamModal ? {} : { display: 'none' }}
          >
            <div className={`aacild`}>
              {this.state.turnVideoClass === '' ? (
                <StaigeButton
                  label={i18n.t('general.close')}
                  onClick={() => this.setState({ showStreamModal: false, activeLense: '' })}
                />
              ) : null}
              <video
                muted
                onCanPlay={this.onStreamReady}
                autoPlay
                preload='auto'
                playsInline
                className='streamPlayer'
                onClick={this.handleVideoClick}
                ref={this.video}
              />
            </div>
          </div>
          {this.state.turnVideoClass !== '' ? (
            <div className='btn' style={this.state.showStreamModal ? {} : { display: 'none' }}>
              <StaigeButton
                label={i18n.t('general.close')}
                onClick={() => this.setState({ showStreamModal: false, activeLense: '' })}
              />
            </div>
          ) : null}
        </div>

        <div className='page-fieldMask'>
          <div className='fieldMask-container'>
            <div className='container-content'>
              <div className='content-header'>
                <div className='header-title'>
                  {this.props.homeposition
                    ? i18n.t('homePosition.headerTitleEdit')
                    : this.props.isNew
                    ? i18n.t('fieldMask.headerTitleNew')
                    : i18n.t('fieldMask.headerTitleEdit')}
                </div>
                {/*<StaigeButton
                  style='ghost'
                  icon={deleteIcon}
                  iconMargin='0 -4px 0 -4px'
                  // onClick={() => this.deleteField(name)}
                />*/}
              </div>
              <div className='content-bottom'>
                <div className='bottom-input'>
                  {this.props.homeposition ? (
                    <mobiscroll.Input
                      type={'text'}
                      value={this.state.homePositionName}
                      onChange={(v) =>
                        this.setState({
                          homePositionName: String(v.target.value).trim()
                        })
                      }
                      valid={true}
                      errorMessage={'ey'}
                    >
                      {i18n.t('homePosition.inputHomePositionTitle')}
                    </mobiscroll.Input>
                  ) : (
                    <mobiscroll.Input
                      type={'text'}
                      value={this.state.currentFieldName}
                      disabled={!this.props.isNew}
                      onChange={(v) =>
                        this.setState({
                          currentFieldName: String(v.target.value).trim()
                        })
                      }
                      placeholder='Hauptfeld - 11er-Fußball...'
                      valid={true}
                      errorMessage={'ey'}
                    >
                      {i18n.t('fieldMask.inputFieldMaskTitle')}
                    </mobiscroll.Input>
                  )}
                  {this.props.homeposition && (
                    <mobiscroll.Input
                      type={'number'}
                      value={this.state.fov}
                      max={180}
                      min={0}
                      disabled={this.state.loadingFields}
                      onChange={(v) =>
                        this.setState({
                          fov: parseInt(v.target.value, 10)
                        })
                      }
                      valid={true}
                      errorMessage={'ey'}
                    >
                      {i18n.t('homePosition.fov')}
                    </mobiscroll.Input>
                  )}
                </div>
                <div className='bottom-fieldmaskContainer'>
                  <div className='fieldmaskContainer-header'>
                    <div className='header-title'>
                      {this.props.homeposition
                        ? i18n.t('homePosition.containerTitle')
                        : i18n.t('fieldMask.fieldmaskContainerTitle')}
                      {this.props.homeposition && (
                        <div className='subtext'>{i18n.t('homePosition.subtext')}</div>
                      )}
                    </div>
                    {this.props.webrtcSrc ? (
                      <div className='title-lenses'>
                        {i18n.t('fieldMask.lenses')}:
                        <div
                          onClick={this.handleClick('0_0')}
                          className={`lenses ${this.state.activeLense === '0_0' ? 'lensesActive' : ''}`}
                        >
                          {!this.state.sensorLoading.includes('0_0') ? (
                            <LoadingSpinner className='lesnses-loading' />
                          ) : (
                            '1'
                          )}
                        </div>
                        <div
                          onClick={this.handleClick('0_1')}
                          className={`lenses ${this.state.activeLense === '0_1' ? 'lensesActive' : ''}`}
                        >
                          {!this.state.sensorLoading.includes('0_1') ? (
                            <LoadingSpinner className='lesnses-loading' />
                          ) : (
                            '2'
                          )}
                        </div>
                        <div
                          onClick={this.handleClick('0_2')}
                          className={`lenses ${this.state.activeLense === '0_2' ? 'lensesActive' : ''}`}
                        >
                          {!this.state.sensorLoading.includes('0_2') ? (
                            <LoadingSpinner className='lesnses-loading' />
                          ) : (
                            '3'
                          )}
                        </div>
                        <div
                          onClick={this.handleClick('1_0')}
                          className={`lenses ${this.state.activeLense === '1_0' ? 'lensesActive' : ''}`}
                        >
                          {!this.state.sensorLoading.includes('1_0') ? (
                            <LoadingSpinner className='lesnses-loading' />
                          ) : (
                            '4'
                          )}
                        </div>
                        <div
                          onClick={this.handleClick('1_1')}
                          className={`lenses ${this.state.activeLense === '1_1' ? 'lensesActive' : ''}`}
                        >
                          {!this.state.sensorLoading.includes('1_1') ? (
                            <LoadingSpinner className='lesnses-loading' />
                          ) : (
                            '5'
                          )}
                        </div>
                        <div
                          onClick={this.handleClick('1_2')}
                          className={`lenses ${this.state.activeLense === '1_2' ? 'lensesActive' : ''}`}
                        >
                          {!this.state.sensorLoading.includes('1_2') ? (
                            <LoadingSpinner className='lesnses-loading' />
                          ) : (
                            '6'
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className='fieldmaskContainer-content'>
                    {!this.props.homeposition && (
                      <div className='content-interactiv'>
                        <div className='interactiv-action'>
                          {this.state.editMode ? (
                            <>
                              <StaigeButton
                                size='small'
                                onClick={this.goToStep(this.state.currentStep - 1)}
                                label={i18n.t('fieldMask.beforCorner')}
                                style='ghost'
                              />
                              <StaigeButton
                                size='small'
                                onClick={this.goToStep(this.state.currentStep + 1)}
                                label={i18n.t('fieldMask.nextCorner')}
                                style='ghost'
                              />
                            </>
                          ) : (
                            <StaigeButton
                              size='small'
                              label={i18n.t('general.edit')}
                              style='primary'
                              onClick={this.toggleEditMode}
                              disabled={this.state.sensorLoading.length < 5}
                              fullWidth
                            />
                          )}
                        </div>
                        <div className='interactiv-field'>
                          {this.state.editMode && this.state.currentStep === 0}
                          <img src={field} />
                          <img
                            className={`corner ${
                              (this.state.currentCorners &&
                                this.state.currentCorners[0] &&
                                this.state.currentCorners[1]) ||
                              (this.props.isNew && this.state.newCorner[2] && this.state.newCorner[3])
                                ? 'cornerActive'
                                : ''
                            } corner-a`}
                            src={circleIcon}
                          />
                          <img
                            className={`corner ${
                              (this.state.currentCorners &&
                                this.state.currentCorners[2] &&
                                this.state.currentCorners[3]) ||
                              (this.props.isNew && this.state.newCorner[4] && this.state.newCorner[5])
                                ? 'cornerActive'
                                : ''
                            } corner-b`}
                            src={circleIcon}
                          />
                          <img
                            className={`corner ${
                              (this.state.currentCorners &&
                                this.state.currentCorners[4] &&
                                this.state.currentCorners[5]) ||
                              (this.props.isNew && this.state.newCorner[6] && this.state.newCorner[7])
                                ? 'cornerActive'
                                : ''
                            } corner-c`}
                            src={circleIcon}
                          />
                          <img
                            className={`corner ${
                              (this.state.currentCorners &&
                                this.state.currentCorners[6] &&
                                this.state.currentCorners[7]) ||
                              (this.props.isNew && this.state.newCorner[0] && this.state.newCorner[1])
                                ? 'cornerActive'
                                : ''
                            } corner-d`}
                            src={circleIcon}
                          />
                          {this.state.editMode && this.state.currentStep === 0 ? (
                            <div className='currentCorner currentCorner-d' />
                          ) : null}
                          {this.state.editMode && this.state.currentStep === 1 ? (
                            <div className='currentCorner currentCorner-a' />
                          ) : null}
                          {this.state.editMode && this.state.currentStep === 2 ? (
                            <div className='currentCorner currentCorner-b' />
                          ) : null}
                          {this.state.editMode && this.state.currentStep === 3 ? (
                            <div className='currentCorner currentCorner-c' />
                          ) : null}
                        </div>
                        <div className='interactiv-text'>{i18n.t('fieldMask.interactiveText')}</div>
                      </div>
                    )}
                    <div className='content-prev'>
                      {this.props.webrtcSrc ? (
                        <>
                          <div
                            className='mbsc-row mbsc-justify-content-center'
                            style={
                              !this.state.changingSensor
                                ? {
                                    /*display: 'none'*/
                                  }
                                : {}
                            }
                          >
                            <div className='mbsc-col-4 mbsc-col-md-4'>
                              <div id='0_1' className='imageContainer'>
                                <img
                                  onClick={this.handleClick('0_1')}
                                  className='gridSnippet'
                                  id='img0_1'
                                  alt='0_1.png'
                                />
                              </div>
                            </div>
                            <div className='mbsc-col-4 mbsc-col-md-4'>
                              <div id='0_2' className='imageContainer'>
                                <img
                                  onClick={this.handleClick('0_2')}
                                  className='gridSnippet'
                                  id='img0_2'
                                  alt='0_2.png'
                                />
                              </div>
                            </div>
                            <div className='mbsc-col-4 mbsc-col-md-4'>
                              <div id='1_0' className='imageContainer'>
                                <img
                                  onClick={this.handleClick('1_0')}
                                  className='gridSnippet'
                                  id='img1_0'
                                  alt='1_0.png'
                                />
                              </div>
                            </div>
                            {/* </div> */}
                            {/* <div className='mbsc-row mbsc-justify-content-center'> */}
                            <div className='mbsc-col-4 mbsc-col-md-4'>
                              <div
                                id='0_0'
                                className={`imageContainer ${
                                  this.state.currentImagesFrom ? 'turn90Left' : ''
                                }`}
                              >
                                <img
                                  onClick={this.handleClick('0_0')}
                                  className='gridSnippet turn90Left'
                                  id='img0_0'
                                  alt='0_0.png'
                                />
                              </div>
                            </div>
                            <div className='mbsc-col-4 mbsc-col-md-4'>
                              <div id='1_2' className='imageContainer'>
                                <img
                                  onClick={this.handleClick('1_2')}
                                  className='gridSnippet'
                                  id='img1_2'
                                  alt='1_2.png'
                                />
                              </div>
                            </div>
                            <div className='mbsc-col-4 mbsc-col-md-4'>
                              <div
                                id='1_1'
                                className={`imageContainer ${
                                  this.state.currentImagesFrom ? 'turn90Right' : ''
                                }`}
                              >
                                <img
                                  onClick={this.handleClick('1_1')}
                                  className='gridSnippet turn90Right'
                                  id='img1_1'
                                  alt='1_1.png'
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className='prev-loading'>
                          <AiswSpinner />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    )
  }
}
