import * as Discovery from '@soccerwatch/discovery'
import * as Helper from '../helper/GlobalHelper'
import { VideoData } from '@soccerwatch/common'

export const getVideoListByContract = async (contractId: string | number) => {
  return await Helper.apiList(`${Discovery.API_VIDEO}/metaBy/contract/${contractId}`)
}

export const getVideoListForClubBetweenDate = async (
  homeOrAway: 'Home' | 'Away',
  clubId: string | number,
  startTime: string,
  endTime: string,
  callAs: string
) => {
  return await Helper.apiList<VideoData>(
    Discovery.API_VIDEO + `/metasExportFor${homeOrAway}Club/${clubId}/${startTime}/${endTime}/`,
    undefined,
    undefined,
    { callAs }
  )
}

export const getVideoListByCameraBetweenDate = async (
  cameraId: string | number,
  startTime: string,
  endTime: string,
  callAs: string
) => {
  return await Helper.apiList<VideoData>(
    Discovery.API_VIDEO + `/metasExportForCam/${cameraId}/${startTime}/${endTime}/`,
    undefined,
    undefined,
    { callAs }
  )
}

export const getVideoListBetweenDate = async (startTime: string, endTime: string, callAs: string) => {
  return await Helper.apiList<VideoData>(
    Discovery.API_VIDEO + `/metasExport/${startTime}/${endTime}/`,
    undefined,
    undefined,
    { callAs }
  )
}

export const getVideoMetaById = async (videoId: number | string, callAs: string) => {
  return await Helper.apiGet<VideoData>(Discovery.API_VIDEO + `/meta/${videoId}${callAs}`)
}

export const postVideoMeta = async (body: Partial<VideoData> | string, callAs: string) => {
  return await Helper.apiPost<any>(`${Discovery.API_VIDEO}/meta/${callAs}`, body)
}

export const postVideoMetaById = async (body: Partial<VideoData>, id: string, callAs?: string) => {
  callAs = callAs !== undefined ? callAs : ''
  return await Helper.apiPost<any>(`${Discovery.API_VIDEO}/meta/${id}${callAs}`, body)
}
