import React, { useEffect, useState, useMemo, useRef } from 'react'
import mobiscroll from '@mobiscroll/react'
import i18n from 'i18next'
import StaigeCheckbox from '../../../components/styles/Checkbox'
import iconUpLine from '../../../img/icons/upLine.svg'
import ImgCropper from './imagesCropper'

import { useChangeHandlers } from '../EditSponsors'
import { InstreamAd, InstreamTypes, Role } from '@soccerwatch/common'
import { ContractContainer } from '../../../services/contractContainer'
import { ClubContainer } from '../../../services/clubContainer'
import { emptyImageAdMaterial } from './EmptyAdvertisements'
import { FileInput } from '../../../helper/FileInput'

type MainSettingsProps = {
  advertisement: InstreamAd
  contractContainer: ContractContainer
  clubContainer: ClubContainer
  onChange: (newAd: InstreamAd) => void
  onChangeValidity: (newValidity: boolean) => void
  isValid: {
    name: boolean
    url: boolean
  }
}

function isValidHttpUrl(string: string) {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const MainSettings = (props: MainSettingsProps) => {
  const { textInputChangeHandler, setArrayProperty } = useChangeHandlers(
    {
      ...props.advertisement,
      name: props.advertisement.name ?? '',
      comment: props.advertisement.comment ?? '',
      link: props.advertisement.link ?? ''
      // contractId: props.advertisement.contractId ?? ''
    },
    props.onChange
  )

  const setTitle = textInputChangeHandler('name')
  const setComment = textInputChangeHandler('comment')
  const setLink = textInputChangeHandler('link')
  const setClubs = setArrayProperty('enabledClubs')

  const [commentText, setCommentText] = useState<boolean>(() => {
    if (props.advertisement.comment) return true
    return false
  })

  const [matchBasedAllow] = useState<boolean>(() => {
    const highestRelevantRoles = props.contractContainer.getHighestRelevantRolesForCurrentContract()
    if (
      highestRelevantRoles.includes(Role.contractOwner) ||
      highestRelevantRoles.includes(Role.cameraController)
    ) {
      return true
    }
    return false
  })
  const [matchBased, setMatchBased] = useState<boolean>(matchBasedAllow)
  const [tempClubs, setTempClubs] = useState<Array<string> | undefined>(undefined)

  useEffect(() => {
    if (props.advertisement.enabledClubs && props.advertisement.enabledClubs.length > 0) {
      setMatchBased(false)
    }
  }, [])

  useEffect(() => {
    if (matchBased) {
      setTempClubs(props.advertisement.enabledClubs)
      setClubs({ value: [] })
    } else {
      if (tempClubs) {
        setClubs({ value: tempClubs })
      }
    }
  }, [matchBased])
  //

  const advertisement = props.advertisement ?? emptyImageAdMaterial

  const [image, setImage] = useState<File | undefined>()
  const [previewimage, setPreviewImage] = useState<string | undefined>()

  useEffect(() => {
    if (image) {
      const file = image
      const reader = new FileReader()
      reader.onloadend = (e) => {
        setPreviewImage(String(reader.result))
      }
      reader.readAsDataURL(file)
    }
  }, [image])

  const { url: fileURL, progressInPercent: fileUploadProgressInPercent } = {
    url: previewimage ? previewimage : '',
    progressInPercent: 100
  }
  // const { url: fileURL, progressInPercent: fileUploadProgressInPercent } = useUploadedFileURL(image)

  const previewURL = useMemo(() => advertisement.mediaInstream, [fileURL, advertisement.mediaInstream])
  const isGif = image?.type === 'image/gif' || previewURL?.includes('.gif')
  // Force contentType = MediaResourceType.STATIC if image format is GIF
  useEffect(() => {
    if (isGif) {
      setContentType(InstreamTypes.STATIC)
    }
  }, [isGif])

  const setContentType = (newContentType: InstreamAd['instreamType']) => {
    props.onChange({
      ...advertisement,
      instreamType: newContentType
    })
  }

  // Update advertisement.imageURL after image upload
  useEffect(() => {
    // only update URL if a new file was uploaded (otherwise, image is undefined)
    if (image && advertisement.mediaInstream !== fileURL) {
      props.onChange({
        ...advertisement,
        mediaInstream: fileURL
      })
    }
  }, [fileURL, advertisement, props.onChange])

  return (
    <div className='bottom-container'>
      <div className='container-adImg'>
        <div className='adImg-imgContainer'>
          <div className='imgContainer-text'>{i18n.t('sponsors.mainSettings.title')}</div>
          <div className='imgContainer-img'>
            {!previewURL ? (
              <div className='img-prev'>
                <div className='img-uploadIcon'>
                  <img src={iconUpLine} />
                  {i18n.t('sponsors.mainSettings.bannerUpload')}
                </div>
                <FileInput accept={'image/*'} onFileChange={setImage} />
              </div>
            ) : null}

            {previewURL ? (
              <>
                {isValidHttpUrl(previewURL) ? (
                  <div className='img-prevImg'>
                    <img style={{ width: '100%' }} src={previewURL}></img>

                    <div className='img-prev'>
                      <div className='img-uploadIcon'>
                        <img src={iconUpLine} />
                        {i18n.t('sponsors.mainSettings.bannerUpload')}
                      </div>
                      <FileInput accept={'image/*'} onFileChange={setImage} />
                    </div>
                  </div>
                ) : null}

                <div>
                  {image ? (
                    <ImgCropper setPreviewImage={setPreviewImage} image={image} isGif={isGif} />
                  ) : null}
                </div>

                {fileUploadProgressInPercent === 0 || fileUploadProgressInPercent === 100 ? null : (
                  <mobiscroll.Progress
                    className={`file-upload-progress ${
                      fileUploadProgressInPercent === 0 || fileUploadProgressInPercent === 100 ? 'hidden' : ''
                    }`}
                    value={fileUploadProgressInPercent}
                    max={100}
                    data-icon={JSON.stringify({ left: 'cloud-upload' })}
                    data-val={'right'}
                  />
                )}
              </>
            ) : null}
          </div>
          <div className='imgContainer-infoText'>
            {i18n.t('sponsors.mainSettings.infoText01')} <br /> {i18n.t('sponsors.mainSettings.infoText02')}{' '}
            .jpeg, .png und .gif - Max. 65 kb
          </div>
        </div>
      </div>
      <div className='container-adTextConfig'>
        <div className='adTextConfig-nameLink'>
          <mobiscroll.Input
            type={'text'}
            value={props.advertisement?.name ?? ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const regEx1 = /[^a-zA-Z\-_0-9\s]+/
              e.target.value = e.target.value.replace(regEx1, '')
              setTitle(e)
            }}
            placeholder='Staige'
            valid={props.isValid.name}
            errorMessage={i18n.t('editAdvertisement.errorMessages.nameInvalid')}
          >
            {i18n.t('editAdvertisement.labels.name')}
          </mobiscroll.Input>

          <mobiscroll.Input
            type={'url'}
            value={props.advertisement?.link}
            valid={props.isValid.url}
            errorMessage={i18n.t('editAdvertisement.errorMessages.linkInvalid')}
            onChange={setLink}
            placeholder='https://www.yoursite.com'
          >
            {i18n.t('editAdvertisement.labels.link')}
          </mobiscroll.Input>
        </div>
        <div className='adTextConfig-description'>
          <StaigeCheckbox
            checked={commentText}
            handleClick={() => setCommentText(!commentText)}
            label={i18n.t('sponsors.mainSettings.checkBoxText')}
          />
        </div>
        {commentText ? (
          <div className='adTextConfig-descriptionOpen'>
            <mobiscroll.Input
              type={'text'}
              value={props.advertisement.comment ?? ''}
              onChange={setComment}
              placeholder={i18n.t('sponsors.mainSettings.commentPlaceholder')}
            >
              {i18n.t('editAdvertisement.labels.comment')}
            </mobiscroll.Input>
          </div>
        ) : null}
      </div>
    </div>
  )
}

MainSettings.defaultProps = {}
