import React, { useCallback, useEffect, useState } from 'react'
import * as mobiscrollV5 from '@mobiscroll/react5'
import HeaderWrapper from '../../helper/PageWrapper'
import mobiscroll from '@mobiscroll/react'
import AiswSpinner from '../helper/aiswSpinner/aiswSpinner'
import CSS from './ClaimCamera.module.scss'
import { QrReader } from 'react-qr-reader'
import i18n from 'i18next'
import Menu from '../menu/Menu'

import { CameraContainer } from '../../services/cameraContainer'
import { ContractContainer } from '../../services/contractContainer'
import { Link, Redirect } from 'react-router-dom'
import { CityContainer } from '../../services/cityContainer'
import { ClubContainer } from '../../services/clubContainer'
import { UserContainer } from '../../services/userContainer'
import { getCameraClaimToken } from '../../api/api-user'

type ClaimCameraProps = {
  token?: string
  cameraContainer: CameraContainer
  contractContainer: ContractContainer
  cityContainer: CityContainer
  clubContainer: ClubContainer
  userContainer: UserContainer
}

const claimToken = async (
  token: string
): Promise<{ success: false } | { success: true; cameraID: string; contractId?: string }> => {
  const response = await getCameraClaimToken(token)
  if (response.data.length) {
    return {
      success: true,
      cameraID: response.data[0].camId,
      contractId: response.data[0].contractId
    }
  } else {
    return { success: false }
  }
}

enum State {
  IDLE,
  SCANNING,
  LOADING,
  VALID,
  INVALID
}

export const ClaimCamera = ({
  token: tokenFromUrl,
  cameraContainer,
  contractContainer,
  userContainer
}: ClaimCameraProps) => {
  const [cameraID, setCameraID] = useState<string | undefined>()
  const [token, setToken] = useState<string>(tokenFromUrl ?? '')
  const [currentState, setCurrentState] = useState(tokenFromUrl ? State.LOADING : State.IDLE)
  const [manualTokenInput, setManualTokenInput] = useState('')
  const [toastIsActive, setToastIsActive] = useState(false)
  const [agbCheck, setAgbCheck] = useState(false)
  const [agbPopup, setAgbPopup] = useState(false)

  const onValidateToken = useCallback(async () => {
    setCurrentState(State.LOADING)
    const response = await claimToken(token)
    if (response.success) {
      await userContainer.fetchUserLicences()
      await contractContainer.fetchContractList()
      userContainer.initialize()
      setCurrentState(State.VALID)
    } else {
      setCurrentState(State.INVALID)
    }
  }, [token])

  // Attempt to validate token as soon as it's set
  useEffect(() => {
    if (token) {
      onValidateToken()
    }
  }, [token])

  const resetToTryAgain = useCallback(() => {
    setToken('')
    setCurrentState(State.IDLE)
  }, [])

  const handleQrScanResult = (result: string | null | undefined) => {
    // Check if QR Code value is a valid URL to backstaige.com
    let qrCodeResultIsValid = false
    if (result) {
      try {
        const url = new URL(result)

        qrCodeResultIsValid = ['backstaige.com', 'www.backstaige.com'].includes(url.hostname)
        if (!qrCodeResultIsValid) {
          // Error thrown *here* to trigger toast if qr code is recognized but invalid
          // (i.e. no toast is shown before a QR code is in the camera's view)
          throw new Error()
        }
      } catch (e) {
        // Only output a new warning if the old one has already disappeared
        if (!toastIsActive) {
          setToastIsActive(true)
          mobiscroll.toast({
            color: 'warning',
            duration: 10000,
            message: i18n.t('claimCamera.invalidQrCode') + result,
            callback: () => setToastIsActive(false)
          })
        }
      }
    }

    const parsedToken = result?.split('/').pop()
    if (parsedToken && qrCodeResultIsValid) {
      setToken(parsedToken)
    }
  }

  let content: JSX.Element | null = null
  switch (currentState) {
    case State.IDLE:
      content = (
        <>
          <mobiscrollV5.Popup
            isOpen={agbPopup}
            onClose={() => setAgbPopup(false)}
            theme='ios'
            themeVariant='dark'
          >
            <div style={{ margin: '0 -16px' }}>
              <mobiscroll.FormGroupTitle>
                <h2>{i18n.t('claimCamera.agbTitle')}</h2>
              </mobiscroll.FormGroupTitle>
              <div className='mbsc-grid'>
                <div className='mbsc-row'>
                  <div className='mbsc-col mbsc-col-12'>
                    Liebe Vereine, liebe Vereinsvertreter*innen,
                    <br />
                    Liebe*r Backstaige-Nutzer*in
                    <br />
                    <br />
                    Die nachfolgenden Bedingungen gelten für die Nutzung von Backstaige durch
                    Vereine/Unternehmen (nachfolgend gemeinsam als „Vereine“, alle Regelungen gelten
                    entsprechend auch für Unternehmen unabhängig von der Rechtsform) bzw. die jeweilige
                    Person, die die Handlungen ausführt und Einstellungen in Backstaige vornimmt. Mit Eurer
                    Registrierung / Eurem Login in Backstaige erkennt Ihr diese Nutzungsbedingungen für Euch
                    bzw. für Euren Verein an. <br />
                    Die Regelungen des Vertrages zwischen Eurem Verein und Staige einschließlich der AGB
                    gelten daneben weiter.
                    <br />
                    <br />
                    <br />
                    <b>1. Nutzungsberechtigung und Zugang</b>
                    <br />
                    <br />
                    Zur Nutzung von Backstaige sind alle Vereine/Unternehmen berechtigt, die eine
                    Staige-Kamera nutzen und mit Staige die Nutzung von Backstaige vereinbart haben.
                    <br />
                    Diese Vereine erhalten von Staige ihre Zugangsdaten/können sich bei Staige für die Nutzung
                    von Backstaige registrieren. Die Vereine sind dafür verantwortlich, dass die Zugangsdaten
                    nur an solche Vertreter*innen des jeweiligen Vereins weitergegeben werden, die vom Verein
                    mit der Bedienung von Backstaige beauftragt sind.
                    <br />
                    Vereinsvertreter*innen, die sich in Backstaige einloggen, bestätigen damit, dass sie im
                    Auftrag ihres Vereins handeln. Eine anderweitige Nutzung von Backstaige ist nicht
                    zulässig. Loggt sich eine Person in Backstaige ein, ohne von einem Verein dazu beauftragt
                    zu sein, ist sie persönlich für die Einhaltung dieser Nutzungsbedingungen und der
                    allgemeinen gesetzlichen Anforderungen verantwortlich!
                    <br />
                    Die Nutzung darf nur in Form der vorgesehenen Funktionen anhand der bereitgestellten
                    Einstellungsmöglichkeiten erfolgen. Jegliche eigenmächtige Veränderung von Backstaige oder
                    seiner Funktionen oder das Kopieren von Backstaige ist verboten. <br />
                    <br />
                    <br />
                    <b>2. Aufnahme- und Streamplanung</b>
                    <br />
                    <br />
                    Ihr könnt die Kamera jederzeit selbstständig in Betrieb nehmen und selbst festlegen, ob
                    nur aufgenommen oder auch gestreamt werden soll! Das macht es bspw. möglich, auch noch
                    während des Trainings kurzfristig die Abschlusseinheit vor dem nächsten Spiel aufzunehmen
                    und zu analysieren!
                    <br />
                    Die Aufnahmen sollen aber nur zu solchen Zeiten geplant und aktiviert werden, zu denen Ihr
                    tatsächlich eine Sportveranstaltung (Training, Spiel, etc.) Eures Vereins aufnehmen wollt
                    und sicher seid, dass die Personen, die sich im Aufnahmebereich befinden, von Euch über
                    die Aufnahmen und deren vertragsgemäße Verwertung informiert sind bzw. rechtzeitig
                    informiert werden können! Sollte das nicht der Fall sein, könnt Ihr eine geplante Aufnahme
                    jederzeit über Backstaige deaktivieren!
                    <br />
                    Unnötige Aufnahmen (bspw. nachts – unsere Kameras sind keine Überwachungstechnik) oder
                    heimliche Aufnahmen sind aus Kosten- und Rechtsgründen unbedingt zu vermeiden! Das gilt
                    insbesondere, wenn Ihr die Aufnahmen über staige.tv streamt. <br />
                    Mit der Planung einer Aufnahme in Backstaige bestätigt Ihr, dass diese Voraussetzungen bei
                    Beginn der Aufnahmen erfüllt sind.
                    <br />
                    <br />
                    <br />
                    <b>3. Ad-Manager / Sponsoring-Tool</b>
                    <br />
                    <br />
                    Ihr könnt nun Eure eigenen Vereinssponsoren in den Stream einbinden und Euren Partnern so
                    noch mehr Sichtbarkeit und Werbewirkung als Gegenleistung für ihre Unterstützung Eures
                    Vereins zurückgeben!
                    <br />
                    Dabei ist jedoch wichtig, dass Ihr die Inhalte (Logos, Grafiken, Text, etc.) und den
                    Verbreitungsweg über Live-Stream mit dem jeweiligen Sponsor abstimmt und Euch die
                    Zustimmung einholt, damit niemand überrascht wird! <br />
                    Der Ad-Manager ist nur für die rechtlich zulässige Darstellung Eurer Sponsoren oder die
                    allgemeine Bewerbung Eures eigenen Vereins vorgesehen. In die Werbeflächen dürfen keine
                    anderen Inhalte, seien es Bilder, Texte, etc. eingestellt werden. Das gilt
                    selbstverständlich insbesondere für beleidigende, diskriminierende oder sonstige Inhalte,
                    die die Rechte Dritter, Gesetze oder verbandsrechtliche Vorgaben verletzen.
                    <br />
                    Mit dem Anlegen von Werbung bestätigt Ihr, dass Ihr diese Voraussetzungen zur Kenntnis
                    genommen habt.
                    <br />
                    <br />
                    <br />
                    <b>4. Paywall</b>
                    <br />
                    <br />
                    Ihr könnt nun auch Eure Spiele hinter eine Paywall legen und damit zusätzliche Einnahmen
                    für Euren Verein generieren. Die Buchung und Zahlungsabwicklung mit den Zuschauern
                    übernimmt (gegen eine kleine Servicepauschale, nähere Infos in Backstaige unter Paywall!)
                    Staige für Euch! <br />
                    Beachtet, dass es möglich ist, dass Eure Fans Tickets für Paywall-Spiele kaufen können,
                    sobald Ihr ein Spiel als Paywall-Spiel angelegt habt. Wollt Ihr das Spiel dann doch frei
                    oder ggf. überhaupt nicht streamen, bekommen Eure Fans selbstverständlich ihr Geld zurück.
                    Sofern uns für die Rückerstattung in solchen Fällen Kosten entstehen, müssen wir diese
                    jedoch Eurem Verein berechnen. Die Paywall sollte daher erst aktiviert werden, wenn Ihr
                    sicher seid, dass eine Pay-Übertragung gewollt ist und tatsächlich durchgeführt werden
                    wird. <br />
                    Entsprechendes gilt, wenn eine vom Verein bereitgestellte Infrastruktur (Strom, Internet)
                    während eines Paywall-Spiels eine störungsfreie Übertragung nicht ermöglicht.
                    <br />
                    Mit dem Aktivieren der Paywall bestätigt Ihr, dass Ihr diese Voraussetzungen zur Kenntnis
                    genommen habt.
                    <br />
                    <br />
                    <br />
                    <b>5. Geltungsdauer</b>
                    <br />
                    <br />
                    Staige ist berechtigt, diese Nutzungsbedingungen bei Vorliegen eines sachlichen Grundes
                    (bspw. Änderung der Rechtslage, Feststellung von häufigen Verstößen durch die Nutzer,
                    Ergänzung von weiteren Diensten auf Backstaige, Umstrukturierung von Backstaige zur
                    Verbesserung der Nutzererfahrung, etc.) zu ändern. Staige wird die Änderung mit mindestens
                    4 Wochen Vorlaufzeit ankündigen und den Zugang zu Backstaige nach Ablauf dieser Frist von
                    der Zustimmung zu den aktualisierten Nutzungsbedingungen abhängig zu machen. Wird
                    Backstaige nach Ablauf dieser Frist weiter genutzt, gilt dies als Zustimmung. Wird den
                    geänderten Nutzungsbedingungen nicht zugestimmt, ist Staige berechtigt, die
                    Nutzungsvereinbarung mit dem Verein zu beenden. Die Zustimmung des Vereins zur jeweils
                    aktuellen Fassung der Nutzungsbedingungen gilt jeweils für die Dauer der Gültigkeit der
                    Fassung, längstens bis zum Ende der Vertragslaufzeit zwischen Staige und dem Verein.
                    <br />
                    <br />
                    6. Kontakt / Ansprechpartner <br />
                    Bei Fragen zur Bedienung oder zu den Funktionen von Backstaige, zu Eurem Zugang, zu diesen
                    Nutzungsbedingungen oder zu allen anderen Fragen rund um Backstaige wendet Euch jederzeit
                    an:
                    <br />
                    <br />
                    clubsupport@staige.com
                    <br />
                    <br />
                    Ruft uns an
                    <br />
                    • an Werktagen: +49 (0)201 246 851-0
                    <br />• am Wochenende: +49 (0) 201 246851-777
                  </div>
                </div>
              </div>

              <mobiscroll.FormGroup style={{ margin: '20px 20px 0px 0px', textAlign: 'right' }}>
                <mobiscroll.Button onClick={() => setAgbPopup(false)} style={{ color: '#fff' }}>
                  {i18n.t('general.close')}
                </mobiscroll.Button>
              </mobiscroll.FormGroup>
            </div>
          </mobiscrollV5.Popup>
          <p>{i18n.t('claimCamera.welcomeMessage')}</p>
          <div className={CSS.agbCheckboxContainer}>
            <div className={CSS.agbCheckbox}>
              <mobiscroll.Checkbox checked={agbCheck} onChange={() => setAgbCheck(!agbCheck)} />
            </div>

            <label>
              {i18n.t('claimCamera.agbAcceptText')}
              <a onClick={() => setAgbPopup(true)}>{i18n.t('claimCamera.agbAcceptBtnText')}</a>
              {i18n.t('claimCamera.agbAcceptTextEnd')}
            </label>
          </div>
          <div className={CSS.inputOptions}>
            <mobiscroll.Button
              onClick={() => setCurrentState(State.SCANNING)}
              className={'round blue full fullWidth'}
              style={{ color: '#fff', marginTop: '14px' }}
              disabled={!agbCheck}
            >
              {i18n.t('claimCamera.scanQrCode')}
            </mobiscroll.Button>
            <span className={CSS.inputSeparator}>{i18n.t('general.or')}</span>
            <div style={{ margin: '-1em', display: 'flex' }}>
              <div style={{ flex: '1' }}>
                <mobiscroll.Input
                  type={'text'}
                  value={manualTokenInput}
                  onChange={(e) => setManualTokenInput(e.target.value)}
                  placeholder={i18n.t('claimCamera.enterTokenManually')}
                  disabled={!agbCheck}
                />
              </div>
              <div style={{ flex: '0', alignSelf: 'center' }}>
                {/* Once token is set, the verification happens automatically */}
                <mobiscroll.Button
                  onClick={() => setToken(manualTokenInput)}
                  className={'round blue'}
                  style={{ marginRight: '1em', height: '34px', lineHeight: '0' }}
                  disabled={!agbCheck}
                >
                  {i18n.t('claimCamera.check')}
                </mobiscroll.Button>
              </div>
            </div>
          </div>
        </>
      )
      break
    case State.VALID:
      content = <Redirect to={'/recordingplan'} />
      break
    case State.INVALID:
      content = (
        <>
          <code className={`${CSS.scannedToken} ${CSS.invalidToken}`}>{token}</code>
          <h3>{i18n.t('claimCamera.tokenWasInvalidMessage')}</h3>
          <mobiscroll.Button onClick={resetToTryAgain}>{i18n.t('claimCamera.tryAgain')}</mobiscroll.Button>
        </>
      )
      break
    case State.SCANNING:
      content = (
        <>
          <QrReader
            constraints={{ facingMode: 'environment' }}
            className={CSS.qrReader}
            onResult={(result: any, error: any) => {
              if (!!result) {
                handleQrScanResult(result)
              }

              if (!!error) {
                console.info(error)
              }
            }}
          />
          <div className={'mbsc-row mbsc-justify-content-center'}>
            <mobiscroll.Button
              className={'round blue full fullWidth'}
              style={{ color: '#fff', margin: '0 1em' }}
              onClick={() => setCurrentState(State.IDLE)}
            >
              {i18n.t('general.cancel')}
            </mobiscroll.Button>
          </div>
        </>
      )
      break
    case State.LOADING:
      content = (
        <>
          <code className={CSS.scannedToken}>{token}</code>
          <AiswSpinner />
        </>
      )
      break
  }

  return (
    <HeaderWrapper headerTitle={i18n.t('claimCamera.title')} linkTo='/cameramonitor'>
      <div className={CSS.wrapper}>{content}</div>
    </HeaderWrapper>
  )
}
