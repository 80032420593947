import englishTranslations from './en'
import { MediaResourceType } from '@soccerwatch/common'
import { placeholder } from '@babel/types'

const translations: typeof englishTranslations = {
  general: {
    save: 'Speichern',
    cancel: 'Abbrechen',
    back: 'Zurück',
    continue: 'Weiter',
    create: 'Erstellen',
    description: 'Beschreibung',
    title: 'Titel',
    delete: 'Löschen',
    edit: 'Bearbeiten',
    yes: 'Ja',
    no: 'Nein',
    new: 'Neu',
    name: 'Name',
    done: 'Fertig',
    revert: 'Rückgängig',
    confirm: 'Bestätigen',
    refresh: 'Aktualisieren',
    close: 'Schließen',
    search: 'Suchen',
    mail: 'E-Mail',
    private: 'Privat',
    public: 'Öffentlich',
    unknown: 'Unbekannt',
    adTypes: {
      [MediaResourceType.IMAGE]: 'Schleifenticker',
      [MediaResourceType.STATIC]: 'Feste Position',
      [MediaResourceType.VIDEO]: 'Video',
      [MediaResourceType.LOTTY]: 'Lotty'
    },
    or: 'oder',
    set: 'übernehmen',
    contract: 'Contract',
    day: {
      mo: 'Montag',
      tu: 'Dienstag',
      we: 'Mittwoch',
      th: 'Donnerstag',
      fr: 'Freitag',
      sa: 'Samstag',
      su: 'Sonntag'
    }
  },
  sidebar: {
    calendar: 'Kalender',
    sponsors: 'Sponsoren',
    cameras: 'Kameras',
    administration: 'Verwaltung',
    staigeApps: 'Staige Apps',
    help: 'Hilfe',
    faq: 'FAQ'
  },
  fieldMask: {
    headerTitleLoad: 'Zurück zur Kamera',
    headerTitleNew: 'Neue Feldmaske anlegen',
    headerTitleEdit: 'Feldmaske editieren',
    inputFieldMaskTitle: 'Name der Feldmaske',
    fieldmaskContainerTitle: 'Feldmaske einrichten',
    lenses: 'Linsen',
    nextCorner: 'Vorherige Ecke',
    beforCorner: 'Nächste Ecke',
    interactiveText:
      'Markiere bitte auf dem Player die ausgewählte Ecke. Klicke dazu einfach an die Stelle, an der sich die Ecke des Spielfeldes befindet.'
  },
  homePosition: {
    inputHomePositionTitle: 'Name der Homeposition',
    infoText: 'Live-Ansicht und Einrichtung der Home Position',
    headerTitleEdit: 'Homeposition Editieren',
    containerTitle: 'Home Position einrichten',
    subtext:
      'Bitte markiere auf der entsprechenden Linse die Position, auf welche sich die Kamera ausrichten soll, sobald die Ki deaktiviert wird.',
    fov: 'Field of View'
  },
  camera: {
    headerTitleLoad: 'Kamera Laden -',
    headerTitle: 'Kamera',
    err: 'Error',
    errText: 'Keine Daten gefunden',
    fieldmask: 'Feldmasken',
    addFieldmaskBtn: 'Feldmaske anlegen',
    fieldmaskDefault: 'Standard',
    fieldmaskOptinal: 'Optional',
    homePosition: 'Home Position',
    cameraInfoTitle: 'Kamera',
    errorFree: 'fehlerfrei',
    check: 'überprüfen',
    camQuest: 'Für die Optimierung der Kamera melde dich beim Club-Support',
    deleteModal: {
      title: 'Löschen der Feldmaske',
      text: 'Bist du sicher, dass du die Feldmaske löschen möchtest?'
    }
  },
  cameraList: {
    headerTitle: 'Kameras',
    btnAddCam: 'Kamera hinzufügen',
    noAllowText: 'Sie haben noch keine Kameras eingerichtet.',
    noAllowText2: 'Fügen Sie eine hinzu, um loszulegen!'
  },
  sponsors: {
    headerTitle: 'Sponsoren',
    newAdTitle: 'Werbung anlegen',
    editAdTitle: 'Werbung editieren',
    createButton: 'Werbung anlegen',
    adCreate: 'Erstellt Am',
    badges: {
      clubBased: 'Vereinsbasiert',
      gameBased: 'Spielbasiert'
    },
    activate: 'Aktivieren',
    mainSettings: {
      title: 'Banner',
      bannerUpload: 'Banner Upload',
      infoText01: 'Empfohlenes Format: 800x90px',
      infoText02: 'Dateiformate:',
      checkBoxText: 'Beschreibung hinzufügen',
      commentPlaceholder: 'Winterrabatt...'
    },
    prevVideo: {
      title: 'Banneranzeige Preview',
      loopInfoText: 'Das Banner bewegt sich wie ein Ticker während des gesamten Events.',
      fixedInfoText: 'Das Banner bleibt stehen während des gesamten Events.'
    },
    basedType: {
      title: 'Werbung Zuordnung',
      gameBased: 'Spielbasiert',
      gameBasedInfo: 'Die Werbung steht bei allen Events der ausgewählten Vereine zur Auswahl.',
      clubBased: 'Vereinsbasiert',
      clubBasedInfo: 'Die Werbung wird bei allen Events der ausgewählten Vereine gespielt.'
    },
    deleteModal: {
      title: 'Löschen des Sponsors',
      text: 'Bist du sicher, dass du denn Sponsor löschen möchtest?'
    }
  },
  calendar: {
    pageTitle: 'Kalender',
    filter: {
      allClubs: 'Alle Vereine',
      allTeams: 'Alle Teams',
      allEvents: 'Alle Events'
    },
    createNewEvent: 'Neues Event',
    event: {
      rec: 'Aufgenommen',
      notRec: 'Nicht Aufgenommen'
    },
    legende: {
      match: 'Spiel',
      training: 'Training',
      eventRec: 'Event wird aufgenommen'
    }
  },
  matchEditor: {
    stepCamera: {
      stepperTitel: 'Kamera',
      title: 'Kamera auswählen',
      cameraList: {
        title: 'Kamera'
      },
      fieldmask: {
        title: 'Feldmaske',
        createNewFieldmask: 'Neue Feldmaske anlegen'
      }
    },
    stepEvent: {
      stepperTitel: 'Event',
      title: 'Event konfiguration',
      date: {
        title: 'Wann findet das Event statt?'
      },
      settings: {
        title: 'Was für ein Event ist das?',
        eventType: 'Eventart',
        duration: 'Aufnahmelänge in Minuten',
        durationInfo: 'Wie lange geht das Spiel inklusive Halbzeit und ggf. Nachspielzeit?',
        visibilityEvent: {
          title: 'Visibilität des Events',
          tooltipPublic1: 'Öffentlich: Stream wird auf ',
          tooltipPublic2: ' angekündigt und gestreamt.',
          tooltipPrivat1: 'Privat: Der Stream auf ',
          tooltipPrivat2: ' kann nur über einen privaten Link aufgerufen werden.'
        },
        paywall: {
          title: 'Paywall',
          agb1: 'Hiermit bestätige ich die',
          agb2: 'Allgemeine Geschäftsbedingungen'
        },
        cameraMode: {
          title: 'Wie soll das Event am besten gestreamt werden?'
        },
        audio: 'Audio-Kommentare zulassen',
        audioTooltip:
          'Sind Audiokommentare erlaubt, können Livestream und Highlights von freigeschalteten Users kommentiert werden.',
        scoreboard: 'Scoreboard anzeigen',
        scoreboardTooltip: 'Der Spielstand wird angezeigt.'
      }
    },
    stepTeams: {
      stepperTitel: 'Teams',
      title: 'Teams Aufstellung'
    },
    stepAds: {
      stepperTitel: 'Werbung',
      title: 'Werbung zuweisen',
      adsWrapper: {
        title: 'Vereinbasierte werbung',
        tooltip:
          'Diese Werbung wird bei allen Spielen dieser Mannschaft gespielt. Diese Einstellung kann auf der Sponsorenseite geändert werden.'
      },
      createNewAd: 'Neue Werbung anlegen'
    },
    newEvent: 'Neues Event',
    changeModal: {
      confirmText: 'Event verlassen',
      title: 'Nicht gespeicherte Änderungen',
      text: 'Bist du sicher, dass du das Event verlassen möchtest? Die vorgenommenen Änderungen werden nicht gespeichert.'
    }
  },

  filteredSelect: {
    pleaseSelect: 'Bitte auswählen...',
    noMatch: 'Neuen Eintrag anlegen...',
    noneChoice: 'Keine'
  },
  notFound: {
    title: '404 - Nicht gefunden',
    message: '{{nameOfItem}} konnte nicht gefunden werden'
  },
  pageNav: {
    control: 'Steuerung',
    planer: 'Planer',
    shop: 'Shop'
  },
  home: {
    title: 'Menü',
    buttonTitle: {
      cameracontrol: 'Kamerasteuerung',
      recordingplan: 'Aufnahme',
      administration: 'Administration',
      cameramonitor: 'Kamera',
      shop: 'Shop',
      logout: 'Konto',
      adManagement: 'Werbung'
    }
  },
  login: {
    button: {
      btnLogin: 'Einloggen',
      btnLogout: 'Abmelden',
      btnSignin: 'Registrieren',
      signup: 'Keinen soccerwatch.tv Account ?',
      login: 'Du hast einen soccerwatch.tv Account ?',
      pwReset: 'Passwort vergessen?',
      loginWithProvider: 'Mit {{authProviderName}} anmelden'
    },
    toast: {
      logoutSuccessful: 'Erfolgreich ausgeloggt!',
      loginSuccess: 'Login erfolgreich',
      loginErr: 'login fehlgeschlagen'
    },
    input: {
      email: 'Email',
      password: 'Passwort'
    }
  },
  editPaywall: {
    title: 'Paywall bearbeiten',
    name: 'Name',
    standard: 'Standard',
    description: 'Beschreibung',
    price: 'Preis',
    currency: 'Währung',
    paywallNotFound: 'Paywall konnte leider nicht gefunden werden.'
  },
  editUser: {
    title: 'Nutzer bearbeiten',
    username: 'Username',
    name: 'Name',
    role: 'Rolle',
    isActivated: 'Aktiviert',
    userNotFound: 'Nutzer konnte leider nicht gefunden werden.',
    roles: {
      admin: 'Admin'
    }
  },
  editAdvertisement: {
    isValidErrMsg: 'Bitte überprüfen Sie die Daten nochmals',
    title: 'Sponsor bearbeiten',
    adsAreLoading: 'Lade Sponsoren...',
    noAdsYet: 'Sie haben noch keine Sponsoren erstellt.',
    generalInformation: 'Allgemein',
    colors: 'Farbauswahl',
    enabledFor: 'Freigeschaltet für',
    instreamOptions: 'Instream-Werbung konfigurieren',
    videoOptions: 'Preroll-Video konfigurieren',
    imageOptions: 'Banner konfigurieren',
    video: 'Preroll-Video',
    lotty: 'Instream-Werbung',
    image: 'Banner Upload',
    imageTooltip:
      'Hinweis zum Format: 800 x 90 px wird empfohlen, es kann aber auch jedes andere Format hochgeladen werden.\n\nDateiformate: .jpeg, .png und .gif, max. 65 kb',
    titles: {
      clubs: 'Vereine',
      adManagement: 'Sponsoren',
      paywallManagement: 'Paywallmanagement',
      userManagement: 'Usermanagement',
      normalPreview: 'Vorschau der eigentlichen Werbung',
      previewInPlayer: 'Vorschau im Video',
      bannerTyp: 'ART DER BANNERANZEIGE',
      tooltip:
        'Schleifenticker: Banner wird in einer Laufschrift animiert.\n\nFeste Position: Banner steht fixiert.'
    },
    labels: {
      name: 'Name des Sponsors',
      loop: 'Schleifen Ticker',
      fixed: 'Feste Position',
      comment: 'Kommentar (optional)',
      startDate: 'Ausspielen ab',
      endDate: 'Ausspielen bis',
      owner: 'Besitzer',
      isActive: 'Aktiv',
      isActiveTooltip:
        'Aktiv: Beim Anlegen eines Spiels kann der Banner ausgewählt werden und wird im Anschluss gespielt.\n\nInaktiv: Der Banner kann nicht mehr ausgewählt werden',
      isActiveLotty: 'Animiert',
      isMainSponsor: 'Hauptsponsor',
      backgroundColor: 'Hintergrundfarbe',
      backgroundLottyColor: 'Hintergrundfarbe',
      foregroundColor: 'Vordergrundfarbe',
      instreamTextColor: 'Schriftfarbe',
      instreamButtonColor: 'Hintergrundfarbe Button',
      instreamButtonTextColor: 'Textfarbe Button',
      cityIDs: 'freigeschaltete Städte',
      clubIDs: 'freigeschaltete Vereine',
      matchIDs: 'freigeschaltete Spiele',
      cupIDs: 'freigeschaltete Cups',
      regionIDs: 'freigeschaltete Regionen',
      link: 'Link zur Website (Sponsor)',
      hasGlobalPreroll: 'Global',
      validFrom: 'Gültig ab',
      validUntil: 'Gültig bis',
      instreamTextA: 'Erste Zeile',
      instreamTextB: 'Zweite Zeile',
      instreamButtonText: 'Button Text',
      instreamLogo: 'Werbendenlogo',
      instreamBackgroundImage: 'Hintergrundbild',
      imageDimensionErrorMessage: 'Bitte wählen Sie ein Bild der Größe {{width}} x {{height}} px'
    },
    errorMessages: {
      textTooLong: '{{textName}} darf höchstens {{maxLength}} Zeichen lang sein! (inklusive Leerzeichen)',
      wrongVideoDimensions: 'Bitte wählen Sie ein Video der Größe {{width}} x {{height}} px',
      wrongVideoDuration: 'Das Video muss zwischen {{min}} und {{max}} Sek. dauern.',
      invalidVideoFile: 'Bitte laden Sie ein gültiges Video hoch!',
      fileTooLarge: 'Ihre Datei darf nicht größer als {{maxFileSize}} sein.',
      linkInvalid: 'Bitte geben Sie einen gültigen HTTP oder HTTPS Link an.',
      nameInvalid: 'Bitte geben Sie einen Namen an.',
      invalidDate: 'Bitte geben Sie ein gültiges Datum an.'
    }
  },
  recording: {
    stopRecTitle: 'Aufnahme Beenden',
    stopRecModal: {
      confirmText: 'Aufnahme Beenden',
      title: 'Aufnahme Beenden',
      text: 'Das Beenden der Aufnahme bedeutet, dass die Live-Übertragung des Events eingestellt wird. Möchtest du wirklich fortfahren?'
    },
    open: 'öffnen',
    embedLink: 'Embed link',
    embedCopy: 'Code Kopieren',
    embedCopyToast: 'Code in der Zwischenablage',
    staigeLink: 'StaigeTV',
    panorama: 'Panorama',
    openPanorama: 'Öffnen',
    tagtool: 'Tag Tool',
    free: 'Kostenlos',
    freecard: 'Freikarten',
    freecards: 'Freikarten anzeigen',
    ticket: 'Ticket',
    paywall: 'Paywall',
    informationAboutImportedVideo:
      'Dieses Video ist automatisch importiert worden und wird daher vorerst nicht öffentlich' +
      'angezeigt. Bestätige bitte die Übertragung, damit es auch öffentlich gesehen werden kann, oder lehne sie ab,' +
      ' damit es gar nicht erst aufgenommen wird.',
    informationOtherContract:
      'Diese Aufnahme wurde von einem anderen Contract erstellt, daher kannst du diese Aufnahme nicht bearbeiten.',
    informationNotSetContract:
      'Diese Aufnahme wurde keinem Contract zugewiesen, sobald du die Aufnahme bearbeitest oder aktivierst, beanspruchst du damit diese Aufnahme.',
    confirmFromImporter: 'Übertragung bestätigen',
    declineFromImporter: 'Übertragung ablehnen',
    cancelVideo: 'Absagen',
    revokeCancellation: 'Übertragen',
    activate: 'Aktivieren',
    deactivate: 'Deaktivieren',
    instantStop: 'Aufnahme sofort beenden',
    instantStopMessage: 'Die Aufnahme wird beendet, dies kann bis zu 1 min dauern.',
    videoWillBeRecordedAndStreamed: 'Video wird übertragen',
    videoWillNotBeRecordedOrStreamed: 'Video wird nicht übertragen',
    embedCode: {
      title: 'Embed Code',
      diebarOption: 'Mit Sidebar'
    }
  },
  stepper: {
    stepText: 'Schritt',
    ofText: 'von'
  },
  recordingPlan: {
    headerTitleNew: 'Zurück zum Kalender',
    headerTitleEdit: 'Aufnahme bearbeiten',
    classes: {
      first: '1. Mannschaft',
      second: '2. Mannschaft',
      third: '3. Mannschaft',
      four: '4. Mannschaft',
      five: '5. Mannschaft',
      a: 'A-Jugend',
      b: 'B-Jugend',
      c: 'C-Jugend',
      d: 'D-Jugend',
      e: 'E-Jugend',
      f: 'F-Jugend',
      g: 'G-Jugend / Bambini',
      senior30: 'Senioren 30+',
      senior40: 'Senioren 40+',
      senior50: 'Senioren 50+'
    },
    gender: {
      men: 'Herren',
      women: 'Damen',
      mixed: 'Gemischt'
    },
    details: {
      stepTitle: 'Aufnahme',
      title: 'Aufnahme anlegen',
      cameraInputText: 'Kamera',
      fieldInputText: 'Feldmaske',
      fieldInputTooltip:
        'Unterschiedliche Feldmasken können unter Kamera > Feldmaske angelegt werden bspw. für unterschiedliche Sportarten oder verschiedene Feldmaße.',
      contractInputText: 'Contract',
      contractInputTooltip:
        'Wähle den Vertrag, über den die hier anfallenden Spielminuten abgerechnet werden sollen. Kann nur bei neuen Aufnahmen geändert werden.',
      inputDate: {
        title: 'Datum',
        placeholder: 'Datum wählen...'
      },
      inputTime: {
        title: 'Startzeit',
        placeholder: 'Uhrzeit wählen...',
        tooltip:
          'Die Aufnahme beginnt genau zu dem eingestellten Zeitpunkt. Soll die Kamera früher angeschaltet werden (Aufwärmprogramm usw.), bitte mit einberechnen.'
      },
      inputEnd: {
        title: 'Ende'
      },
      minutesBeforText:
        'Ziehen Sie den Schieberegler, um einzustellen, wie viele Minuten vor dem Start die Übertragung beginnen soll',
      durationText: 'Aufnahmenlänge',
      durationTooltip:
        'Wie lange geht das Spiel inklusive Halbzeit und ggf. Nachspielzeit? Gibt es Besonderheiten wie Verlängerung oder Elfmeterschießen?',
      durationValueText: '{{duration}} MIN',
      minutesBeforValueText: '{{minutesBefor}} minuten vorher {{clock}}',
      infoTextDisabledTimeChange: 'Startzeit nicht mehr änderbar 10 Minuten vor Aufnahmebeginn'
    },
    advanced: {
      stepTitle: 'Stream',
      title: 'Event',
      durationTooltip:
        'Öffentlich: Stream wird  auf staige.tv angekündigt und gestreamt. Privat: Der Stream auf staige.tv kann nur über einen privaten Link aufgerufen werden.',
      duration: {
        private: 'Privat',
        public: 'Öffentlich',
        special: 'Spezial'
      },
      cameraControl: 'Kameraführung',
      cameraWorkSwitches: {
        cameraWork: 'Geführter Stream',
        cameraVRWork: 'VR Stream',
        cameraFullWork: 'Feld Totale',
        cameraHorseWork: 'Reitsport',
        cameraScoutingWork: 'Scouting Feed'
      },
      streamTypes: {
        autoStream: {
          title: 'Camera Man',
          description:
            'KI-Stream, Kamerafokus folgt dem Spielgeschehen automatisch (Standard bei Livestreams).'
        },
        trainingStream: {
          title: 'VR Stream',
          description:
            'VR-Stream, manuelle Kameraführung. Anwender legt den Kamerafokus fest (Standard bei Training).'
        },
        analyseStream: {
          title: 'Scouting',
          description:
            'Rausgezoomtes KI-Stream, Kamerafokus folgt rausgezoomt dem Spielgeschehen automatisch.'
        },
        vrStream: {
          title: '180° Full Field',
          description: 'Permanente Ansicht des gesamten Spielfeldes.'
        }
      },
      activeLinkTitle: 'Aufnahmeanzeige',
      activeLinkSwitches: {
        coachingtool: 'Coachingtool',
        coachingtoolDesc: 'nur im Coachingtool vorhanden',
        platform: 'Platform',
        platformDesc: 'geheimer Link'
      },
      audioTitle: 'Audiokommentatoren',
      audioTooltip: 'Sind Audiokommentare erlaubt, können Livestream und Highlights  kommentiert werden.',
      audioSwitches: {
        disabledAudioComments: 'Kommentator nicht erlauben',
        audioCommentsEnabled: 'Audio-Kommentare erlaubt',
        audioCommentsDisabled: 'Audio-Kommentare nicht erlaubt'
      },
      scoreboardTooltip: '',
      scoreboardSwitches: {
        scoreboardEnabled: 'Scoreboard anzeigen',
        scoreboardDisabled: 'Scoreboard nicht anzeigen'
      },
      connection: {
        title: 'Connection',
        errMsg: `der string muss mit rtmp://', 'gs://', 's3://', 'srt://' beginnen'`,
        bufferSecsTitle: 'Buffer sek.'
      }
    },
    teams: {
      stepTitle: 'Teams',
      title: 'Mannschaftsauswahl',
      homeSelectTitle: 'Heimverein',
      guestSelectTitle: 'Gastverein',
      teamSelect: {
        filterEmptyText: 'daten werden geladen',
        filterPlaceholderText: 'Mannschaft suchen...'
      },
      clubHomeInfo: 'Heimverein auswählen',
      clubHomeInfoSet: 'Heimverein gesetzt',
      clubGuestInfo: 'Gastverein auswählen',
      clubGuestInfoSet: 'Gastverein gesetzt',
      teamNameHome: 'Heimmannschaft',
      teamNameGuest: 'Gastmannschaft',
      needNewTeam: '...mein Team ist nicht aufgelistet',
      newTeam: '...neues Team anlegen',
      gender: 'Geschlecht',
      ageclass: 'Altersklasse',
      lableHomeclub: 'Heimmannschaft',
      lableGuestclub: 'Gastmannschaft',
      teamPlaceholder: 'Mannschaft auswählen',
      clubPlaceholder: 'Verein auswählen',
      placeholder: {
        selectFirstClub: 'Bitte wählen Sie ersten ein Club...',
        selectBothClub: 'Bitte wählen Sie beide Clubs aus...',
        select: 'Bitte auswählen...'
      },
      competitionLabel: 'Wettbewerb',
      season: 'Saison'
    },
    paywall: {
      stepTitle: 'Paywall',
      title: 'Preisoption',
      infoText: 'Legen Sie den Preis für die Zuschauer fest.',
      formFree: {
        title: 'Kostenlos',
        contentText: 'Kostenlos'
      },
      formPay: {
        title: 'Bezahlung pro Zuschauer',
        contentText: 'Preis pro Zuschauer',
        defaultTitleText: 'Einzel-Zugang',
        defaultDescriptionText: 'Dieses Spiel freischalten',
        vat: 'inkl. MwSt.',
        infoPopup: {
          headText: 'Weitere Informationen',
          contentText:
            'Staige erhebt pro gezahltem Einzelzugang (Pay-per-View) grundsätzlich eine Servicepauschale von 1,50 € netto, sofern sich keine anderen vorrangig vereinbarten Konditionen aus Eurem Vertragsverhältnis mit Staige oder unseren internationalen Lizenzpartnern (u.a.. AUT: RS Digital) ergeben. Servicepauschale wird bei Gutschrift der Nettoerlöse in Abzug gebracht. Nettoerlöse bezeichnet die tatsächlich erzielten Erlöse aus dem Pay-per-View-Angebot abzüglich der für die Zahlungsabwicklung über digitale Zahlungssysteme anfallenden Kosten sowie der gesetzlichen Umsatzsteuer. Tatsächlich erzielt sind Erlöse, die Staige durch die Anbieter der digitalen Zahlungssysteme auf dem jeweiligen Konto gutgeschrieben wurden und dadurch zur Auszahlung an den Verein tatsächlich zur Verfügung stehen.'
        },
        infoButton: 'inkl. 1,50€ Gebühr?'
      },
      formFreeTicket: {
        contentText: 'Freikarten generieren',
        ticket: 'Tickets',
        defaultTitleText: 'Freikarte',
        defaultDescriptionText: 'Freikarte',
        tooltip:
          'Die generierten Freikarten-Codes werden euch später in der angelegten Aufnahme im Kalender angezeigt.'
      }
    },
    sponsors: {
      stepTitle: 'Sponsoren',
      tooManyOfTypeErrorMessage:
        'Sie haben schon die maximal erlaubte Anzahl ({{maxAmount}}) von $t(general.adTypes.{{type}})-Sponsoren' +
        ' erreicht.',
      loadingAds: 'Lade Sponsor...',
      noAdsYet: 'Bitte erstellen Sie erst ein Werbematerial. Sie können es dann zu diesem Spiel hinzufügen.',
      remainingAmountOfAdsTitle: 'Anzahl noch auswählbarer Sponsoren pro Werbetyp:',
      matchSponsor: 'Spielsponsor',
      clubSponsor: 'Vereinssponsor'
    },
    toastText: {
      camWorkNotSet: 'Kameraführung wurde nicht ausgewählt!',
      errDate: 'Datum / Uhrzeit fehlt!',
      succsessSave: 'Die Änderungen wurde erfolgreich gespeichert',
      errSave: 'Hier ist etwas schief gelaufen.',
      succdessNew: 'Die Aufnahme wurde erfolgreich angelegt',
      underTenMin:
        'Die Aufnahme wurde nicht 15 Minuten vor Start erstellt. Es kann zu leichten Verzögerungen zu Beginn des Streams kommen, aber das Spiel wird vollständig übertragen.',
      startIsAfter: 'Der geplante Start dieser Aufzeichnung darf nicht in der Vergangenheit liegen',
      noContract: 'Irgendwas ist da schief gelaufen. Bitte versuche es später nochmal.',
      outOfStreamingMinutes: 'Nicht genug Streaming-Minuten übrig. Bitte deaktivieren sie geplante Aufnahmen.'
    }
  },
  videoList: {
    calendarToday: 'Heute',
    edit: 'Bearbeiten',
    infoTextNoData: 'Keine Weiteren Daten gefunden.',
    infoTextNoSearchFound: 'Keine Daten zu dieser sucheanfrage gefunden.',
    btnNewBroadcast: 'Eine neue Aufnahme planen',
    searchText: 'Suche',
    searrchPlaceholder: 'Kamera ID, Video ID oder Platz...',
    canceled: 'deaktiviert'
  },
  adMaterialsOverview: {
    newAd: 'Neue Werbung anlegen'
  },
  cameraMonito: {
    analysis: 'Kamera',
    moreCameras: 'Weitere Kameras',
    speedTest: {
      title: 'Upload Speed',
      speed: 'Übertragungsgeschwindigkeit',
      errorMessage: 'Konnte nicht ermittelt werden',
      unknown: 'Unbekannt',
      mbps: 'Mbit',
      startLabel: 'Speedtest starten',
      infoText: 'Ihre Verbindung ist schnell genug, um Live-Streaming und VoD-Aufnahmen zu ermöglichen.',
      infoTextNot:
        'Ihre Verbindung ist NICHT schnell genug, um Live-Streaming und VoD-Aufnahmen zu ermöglichen.'
    },
    claimCamButton: 'Neue Kamera hinzufügen',
    camId: 'KAMERA ID',
    speedTestBox: {
      headerText: 'Speedtest',
      infoText: 'Prüfen Sie Ihren aktuellen Upload'
    },
    setupStream: {
      headerText: 'Feldmaske definieren',
      infoText: 'Live-Ansicht und Einrichtung des Spielfelds'
    },
    support: {
      headerText: 'Support',
      infoText: 'Technische Unterstützung anfordern'
    }
  },
  claimCamera: {
    title: 'Kamera mit Konto verknüpfen',
    invalidQrCode: 'Der gescannte QR-Code scheint nicht von Staige zu sein. Der Inhalt ist momentan: ',
    welcomeMessage:
      'Wir freuen uns, dass du dich  für ein Kamerasystem von Staige entschieden hast. Verknüpfe nun deine Staige-Kamera mit der Backstaige-App. Scanne dafür den QR-Code, der mit deiner Kamera geliefert wurde. Alternativ kannst du den 20-stelligen Code manuell eintragen.',
    scanQrCode: 'QR-Code scannen',
    uploadImage: 'Bild hochladen',
    enterTokenManually: '20-stelligen Code eingeben',
    check: 'Prüfen',
    tokenWasValidMessage: 'Der Token war gültig und die Kamera wurde Ihren Konto zugeordnet!',
    tokenWasInvalidMessage: 'Der Token war leider nicht gültig!',
    claimAnotherCamera: 'Weitere Kamera hinzufügen',
    goToCamera: 'Zur Kamera',
    tryAgain: 'Erneut versuchen',
    noQrCodeFound: 'Es konnte kein QR-Code erkannt werden.',
    agbAcceptText: 'Ich habe die ',
    agbAcceptBtnText: 'Nutzungsbedingungen',
    agbAcceptTextEnd: ' gelesen und erkläre mich damit einverstanden.',
    agbTitle: 'Nutzungsbedingungen „Backstaige“'
  },
  matchfieldCalibration: {
    defaultMasktext: 'Standardmaske',
    recorded: 'Aufnahmezeitpunkt',
    matchfield: 'Matchfeld Aufnahme',
    camera: 'Kamera',
    // updateCamView: 'Kameraview aktualisieren',
    camView: 'Kameraview',
    recording: 'Aufnahme läuft',
    maskFieldButtonText: 'Aktuelle Feldmaske editieren',
    select: 'Feldmaske Kalibrieren',
    recordingFrom: 'Kalibrierungs-Aufnahme',
    working: 'In Arbeit',
    unavailable: 'Nicht vorhanden',

    fieldSet: 'Matchfelder',
    plusNewField: '+ Neues Matchfeld',
    newField: 'Neues Matchfeld',
    newFieldPlaceholder: 'z.B Kunstrasenplatz',
    ul: 'oben links',
    ur: 'oben rechts',
    ll: 'unten links',
    lr: 'unten rechts',
    selectCorner1: 'Bitte die Ecke ',
    selectCorner2: ' Klicken',
    confirmNew: 'Die vorherige Aufnahme wird dadurch unbrauchbar. Sind sie sicher?',
    saveError:
      'Beim Speichern der Feldmaske ist ein Fehler aufgetreten. Bitte versuchen sie es gleich erneut.',
    fieldExists: 'Feldname {{ fieldName }} existirert berreits',
    connectionClosedTitle: 'Die Kamera hat die Verbindung unterbrochen.',
    connectionClosedText:
      'Dies geschieht normalerweise nach einem Timeout oder wenn ein anderer User sich zur Kamera verbindet.',
    connectionClosedButton: 'Ok',
    connectionFailedTitle: 'Verbindung zur Kamera nicht möglich.',
    connectionFailedText: 'Bitte stellen sie sicher, dass die Kamera online erreichbar ist.',
    deviceBusyTitle: 'Die Kamera befindet sich gerade in einer Aufnahme.',
    deviceBusyText: 'Bitte versuchen sie es erneut, sobald die Aufnahme beendet ist.',
    connectionErrorTitle: 'Ein Fehler ist aufgetreten',
    connectionErrorText:
      'Bitte versuche, dich neu zu Verbinden. Sollte der Fehler bestehen bleiben, nimm bitte Kontakt mit dem Support auf.',
    cameraUploading:
      'Deine Kamera läd momentan Spielaufnahmen hoch. Das kann dazu führen, dass Qualität und Geschwindigkeit der Bildübertragung beeinträchtigt sind.'
  },
  browserUnsuported: {
    maintext: 'Dein momentaner Browser wird leider noch nicht unterstützt!',
    subtext:
      'Bitte nutzen die aktuelle Version von Chrome oder Safari, wenn du dieses Feature nutzen möchtest.'
  },
  support: {
    headText: 'Support',
    noResultText: 'Keine übereinstimmung gefunden.',
    searchText: 'Suche'
  },
  tagging: {
    siteMode: {
      text01: 'Das Spiel hat bereits angefangen und unsere KI hat schon einige Events automatisch markiert.',
      text02:
        'Damit die Events der richtigen Mannschaft zugeordnet werden, wähle bitte die Position deines Teams anhang der Szene des heutigen Anstoßes aus.'
    },
    waitScreen: {
      welcomeTitle: 'Willkommen zum Spiel',
      day: 'Tag',
      days: 'Tage',
      ai: 'KI',
      live: 'LIVE',
      modeTitle: 'Bitte wähle aus wie du das Spiel taggen möchtest:',
      modeField: {
        title: 'Am Spielfeld',
        infoText:
          'Optimierte Benutzeroberfläche für die schnelle Markierung von Spielereignissen. Die Tags werden in der Echtzeit des Spiels gespeichert.',
        btnText: 'Taggen starten!',
        startInfo01: 'Die Staige-Kamera "',
        startInfo02: '" wird erst um ',
        startInfo03: ' Uhr eingeschaltet.'
      },
      modeLive: {
        title: 'Remote',
        infoText: 'Markiere Spielereignisse remote mit Hilfe der Live-Spielwiedergabe.',
        btnText: 'Remote taggen starten!'
      }
    },
    liveTaggingPossibleText: 'live tagging nun möglich',
    lastTagContainer: {
      lastSetTagText: 'Letzter gesetzter Tag'
    },
    eventContainer: {
      taggingBtn: 'taggen',
      eventList: 'eventliste',
      showInsert: 'Einblendung anzeigen',

      timeMenu: {
        timer: 'Timer',
        start: 'Start',
        end: 'Ende',
        endTimeout: 'Ende Auszeit',
        playTime: 'Spielzeiten',

        quickStart: 'Zeit Fortsetzen',
        quickEnd: 'Zeit Pausieren',

        shotClock: 'Shotclock',
        timeControl: 'Zeitsteuerung',
        timeCorrection: 'Zeitkorrektur'
      },
      popup: {
        infoText: 'Möchtest du wirklich die Zeit dieses Events neu setzten?'
      },
      buttonOverlay: {
        back: 'Zurück',
        more: 'Mehr'
      }
    },
    clubInfo: {
      home: 'Heim',
      guest: 'Gast'
    },
    insert: {
      showBy: 'Einblendung erscheint bei',
      title: 'Art der einblendung',
      name: 'Einblendung',
      buttons: {
        full: 'Voller Bereich',
        bottom: 'Unterer Bereich',
        insert: 'Einblendung bestätigen',
        insertTemp: 'Einblenden bei'
      },
      input: {
        title: 'Upload'
      },
      slider: {
        title: 'Dauer der Sichtbarkeit',
        sec: 'Sekunden'
      }
    },
    tagList: {
      eventListMenu: {
        gameTime: 'Spielevents',
        timeEvents: 'Zeitevents',
        all: 'Alle',
        home: 'Heim',
        guest: 'Gast'
      },
      buttons: {
        events: 'Events',
        time: 'Zeit',
        edit: 'Edit'
      },
      deleteWarningText: 'Möchten sie dieses Event wirklich löschen?',
      infoStarText: 'Highlight',
      infoCog: 'Über Scoreboard getaggt'
    },
    checkAiTagList: {
      title: 'Sind diese KI Events richtig?'
    },
    overlay: {
      aiEvent: {
        text: 'Hey! Hast du gerade ein ',
        text1: ' von ',
        text2: ' verpasst?'
      },
      teamSide: {
        text: 'Auf welcher Seite startet die Heimmannschaft?',
        infoText:
          'Diese Information hilft uns, die von unserer KI erkannten Events während des Spiels der richtigen Mannschaft zuordnen zu können.',
        btn: {
          left: 'Links',
          right: 'Rechts'
        }
      }
    }
  },
  forms: {
    club: {
      title: 'Club anlegen',
      searchName: 'Suchname',
      color: 'Farbe',
      selectColor: 'Farbe Auswählen...',
      thumbnail: 'Bild',
      league: 'Liga',
      zip: 'PLZ',
      city: 'Stadt',
      location: 'Adresse',
      locationPlaceholder: 'Straße + Nr',
      hasSystem: 'hat ein Kamerasystem',
      hasCategory: 'hat eine Kategorie',
      commercialReady: 'commercial Ready',
      newClub: 'Neuen Club anlegen...'
    },
    team: {
      title: 'Team anlegen',
      age: 'Altersklasse',
      competitions: 'Wettbewerbe',
      aliasList: 'Alias Liste',
      dId: 'DID',
      dfbLink: 'Dfb Link',
      club: 'Club',
      newTeam: 'Neues Team anlegen...'
    },
    city: {
      title: 'Stadt anlegen',
      state: 'Bundesland',
      stateShort: 'Bundesland(Kürzel)',
      country: 'Land',
      newCity: 'Neue Stadt anlegen...'
    },
    competition: {
      competition: 'Wettbewerb',
      competitions: 'Wettbewerbe'
    },
    licenseTypes: {
      AdManagerAccess: 'Werbe Manager',
      CameraLicense: 'Kamera Manager',
      TrainerSubscription: 'Trainer',
      ContractOwnerLicense: 'Vertags Besitzer',
      CommentatorLicense: 'Kommentator'
    },
    contract: {
      title: 'Contract Details',
      text1: 'Um die Einrichtung abzuschließen gib bitte noch den Namen deiner Organisation an.',
      text2: 'Wähle anschließend deinen club aus um los zu legen.'
    }
  },
  administration: {
    title: 'Verwaltung',
    userManagement: {
      menuTitle: 'Nutzerverwaltung',
      menuSupTitle:
        'Füge neue Benutzer hinzu und verwalte ihren Zugang und ihre Rechte für die verschiedenen Staige-Produkte.',
      chooseAnotherContract: 'Einen anderen Contract auswählen',
      infoText: 'Verknüpfe Nutzer mit deiner Kamera und weise ihnen Rechte zu.',
      userEdit: {
        addUser: 'Nutzer hinzufügen',
        user: 'Nutzerverwaltung',
        mail: {
          placeholder: 'beispiel@staige.tv...',
          infoText: 'Der hinzugefügte Benutzer muss einen Account auf staige.tv besitzen.',
          errorMessage: 'Der Benutzer konnte nicht gefunden werden.'
        },
        newUserInput: {
          lable: 'E-Mail',
          placeholder: 'max.mustermann@mail.de',
          helperText: 'Der hinzugefügte Benutzer muss einen Account auf staige.tv besitzen'
        },
        userBoxNew: 'Erteilt dem neuen Benutzer Rechte'
      },
      userRole: {
        ContractOwnerLicense: 'Admin',
        CameraLicense: 'Aufnahmeverwalter',
        TrainerSubscription: 'Trainer',
        ClubTaggerLicense: 'Tagger',
        AdManagerAccess: 'AdManager',
        ClubStreamerLicense: 'Streamer'
      },
      userRoleTranslate: {
        contractOwner: 'Admin',
        cameraController: 'Aufnahmeverwalter',
        trainer: 'Trainer',
        clubTagger: 'Tagger',
        adManager: 'AdManager',
        ClubStreamerLicense: 'Streamer'
      },
      userRoleDesc: {
        ContractOwnerLicense: 'Zugriff auf alle Funktionen. Darf neue Nutzer hinzufügen und Rechte verwalten',
        CameraLicense: 'Darf Aufnahmen anlegen und aktivieren',
        TrainerSubscription: 'Hat Zugriff auf Staige Coach und alle Aufnahmen',
        ClubTaggerLicense: 'Darf Markierungen im Video hinzufügen',
        AdManagerAccess: 'Darf Werbung Bearbeiten',
        ClubStreamerLicense: 'Darf Streams mit zusätzlichem Content anlegen'
      }
    },
    contractStramingAbo: {
      menuTitle: 'Aboverwaltung',
      menuSupTitle:
        'Verwalte dein Abonnement und verschaffe dir einen Überblick über die verbleibende Streaming-Zeit.',
      infoBox: {
        hour: 'Std',
        min: 'Min',
        free: 'frei',
        freeInfoText: 'Aufnahmenzeit die noch nicht verplant oder verwendet wurde',
        recorded: 'aufgezeichnet',
        recordedInfoText: 'Vergangene Aufzeichnungen'
      },
      contact: {
        infoText: 'Du benötigst mehr Aufnahmezeit?',
        mailToButton: 'Support Kontaktieren'
      },
      helpContainer: {
        title: 'Hast du Fragen?',
        infoText: 'Kontaktiere unser Club Support!'
      },
      aboBox: {
        headerTitleLeft: 'Mein Abo',
        infoBarTitle: 'Aufnahmezeit',
        triggerTitle: 'Events',
        upgradeBtn: 'Upgrade',
        upcomingRecordings: 'Kommende Events',
        pastRecordings: 'Vergangene Events',
        planRecording: 'Event planen',
        badges: {
          match: 'Spiel',
          training: 'Training'
        },
        abo: 'Abonnement',
        standardLTE: {
          title: 'STANDARD LTE (4 Spiele)',
          info: 'Mit dem Standardkontingent könnt ihr insgesamt bis zu vier Spiele pro Monat (520 Minuten) aufzeichnen und live auf unserer Plattform staige.tv streamen.',
          info1:
            'Habt ihr noch mehr zu zeigen? Mit einer LTE-Flat oder einer LAN-Verbindung könnt ihr bis zu zehn Spiele pro Monat (1.300 Minuten) aufzeichnen und streamen. Meldet euch gern unter clubsupport@staige.com.',
          aboType: 'Monatlich'
        },
        proLTE: {
          title: 'PRO LTE (10 Spiele)',
          info: 'Mit dem PRO LTE Paket bzw. eurer LTE-Flat könnnt ihr insgesamt bis zu zehn Spiele pro Monat (1.300 Minuten) aufzeichnen und live auf unserer Plattform staige.tv streamen.',
          info1: 'Habt ihr Fragen zu eurem Kontingent? Meldet euch gern unter clubsupport@staige.com.',
          aboType: 'Monatlich'
        },
        lan: {
          title: 'LAN (10 Spiele)',
          info: 'Dank eurer LAN-Verbindung könnt ihr insgesamt bis zu zehn Spiele pro Monat (1.300 Minuten) aufzeichnen und live auf unserer Plattform staige.tv streamen.',
          info1: 'Habt ihr Fragen zu eurem Kontingent? Meldet euch gern unter clubsupport@staige.com.',
          aboType: 'Monatlich'
        },
        flex: {
          title: 'FLEXIBLES KONTINGENT',
          info: 'Wie vereinbart, könnt ihr das unten angezeigte Kontingent nutzen, um Spiele aufzuzeichnen und live auf unserer Plattform staige.tv zu streamen.',
          info1: 'Habt ihr Fragen zu eurem Kontingent? Meldet euch gern unter clubsupport@staige.com.',
          aboType: 'Monatlich'
        },
        monthly: {
          aboType: 'Monatlich'
        },
        yearly: {
          aboType: 'Jährlich'
        },
        free: {
          aboType: 'Frei Verfügbar'
        }
      }
    }
  }
}

export default translations
