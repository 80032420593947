import { Competition } from '@soccerwatch/common'
import * as Discovery from '@soccerwatch/discovery'
import * as Helper from '../helper/GlobalHelper'
import { CityData } from '../types/CityData'

const url = 'https://europe-west1-aisw-ww-prod.cloudfunctions.net/beta_api_skyhook'

export const getClubsById = async (ids: Array<string>) => {
  return await Helper.apiPost<any>(`${url}/backstaige/page/clubs`, { ids: ids })
}
