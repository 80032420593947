import { Container } from 'unstated-typescript'
import Discovery, { assertContainerHasInitializedDiscovery } from './discovery'
import type DiscoveryType from '@soccerwatch/discovery'
import { ClubData, Role } from '@soccerwatch/common'
import { ContractContainer } from './contractContainer'
import { TeamContainer } from './teamContainer'
import { CompetitionContainer } from './competitionContainer'
import { getClubById, postClubById } from '../api/api-club'
import { searchAndSortGetCallAs } from './serviceHelper'
import { getClubsById } from '../api/api-skyhook'

type ClubState = {
  loadingData: boolean
  clubs: Record<string, ClubData>
  publicClubs: Record<string, ClubData>
  discovery?: typeof DiscoveryType
}

export class ClubContainer extends Container<ClubState> {
  discovery?: typeof DiscoveryType
  contractContainer?: ContractContainer
  teamContainer?: TeamContainer
  competitionContainer?: CompetitionContainer
  constructor() {
    super()

    this.state = {
      loadingData: true,
      clubs: {},
      publicClubs: {},
      discovery: undefined
    }

    Discovery.then((d) => {
      this.setState({ discovery: d })
      // if (UserLogin.state.loggedIn) {
      //   this.initialize()
      // }
    })
  }
  // Recieves the initialized ContractContainer from userContainer
  async initialize(
    contractContainer: ContractContainer,
    teamContainer: TeamContainer,
    competitionContainer: CompetitionContainer
  ) {
    this.competitionContainer = competitionContainer
    this.contractContainer = contractContainer
    contractContainer.subscribeToContractChanged(
      'ClubContainer',
      this.onContractChanged,
      this.onBeforeContractChanged
    )
    this.teamContainer = teamContainer
    await this.fetchClubDataForCurrentContract()
    return
    // this.setClubList()
  }

  onBeforeContractChanged = (nextIndex: number, activeContract: () => number) => {
    return new Promise<void>((resolve) => {
      if (nextIndex !== activeContract()) return resolve()
      this.setState({ loadingData: true }, async () => {
        if (nextIndex !== activeContract()) return resolve()
        await this.fetchClubDataForContract(nextIndex)
        if (nextIndex !== activeContract()) return resolve()
        resolve()
      })
    })
  }

  onContractChanged = () => {}

  async getClubById(id: string) {
    if (this.state.clubs[id]) {
      return this.state.clubs[id]
    } else if (this.state.publicClubs[id]) {
      return this.state.publicClubs[id]
    } else {
      const data = this.fetchPublicClubDataById(id)
      return data
    }
  }

  getClubList() {
    return Object.keys(this.state.clubs).map((key) => this.state.clubs[key])
  }

  getClubListForCurrentContract = () => {
    return this.getClubListForContract()
  }

  getClubListForContract = (index?: number) => {
    if (!this.contractContainer) {
      console.warn(
        '<ClubContainer> Could not get CameraList for current Contract: ContractContainer not Initialized'
      )
      return []
    }
    const currentContract = this.contractContainer.getContract(index)
    if (!currentContract) {
      console.warn('<ClubContainer> Could not get CameraList for current Contract: No Contracts Available')
      return []
    }
    const clubs = currentContract.clubIds
      .map((clubId) => {
        if (!this.state.clubs[clubId]) {
          if (!this.state.loadingData) {
            console.error(`<ClubContainer> ClubId ${clubId} not found in State. This should not happen`)
          }
          return undefined
        }
        return this.state.clubs[clubId]
      })
      .filter((data) => !!data)
    return clubs as ClubData[]
  }

  async _fetchClubDataById(id: string) {
    assertContainerHasInitializedDiscovery(this)
    const data = getClubById(id)
    return data
  }

  async fetchClubDataById(id: string) {
    if (!this.state.clubs[id]) {
      const clubs = JSON.parse(JSON.stringify(this.state.clubs)) as Record<string, ClubData>
      clubs[id] = await this._fetchClubDataById(id)
      this.setState({ clubs })
      return clubs[id]
    }
  }

  async fetchPublicClubDataById(id: string) {
    const publicClubs = JSON.parse(JSON.stringify(this.state.publicClubs)) as Record<string, ClubData>
    publicClubs[id] = await this._fetchClubDataById(id)
    this.setState({ publicClubs })
    return publicClubs[id]
  }

  fetchClubDataForCurrentContract = async () => {
    return this.fetchClubDataForContract()
  }

  fetchClubDataForContract = async (index?: number) => {
    if (!this.contractContainer) {
      return console.error(
        '<ClubContainer>Could not fetch ClubData without initialized ContractContainer. This should not happen'
      )
    }
    const currentContract = this.contractContainer.getContract(index)
    if (!currentContract) {
      return console.warn('<ClubContainer> Can not fetch ClubData, no Active Contract')
    }
    const clubs = JSON.parse(JSON.stringify(this.state.clubs))
    // const clubsOfContract = await this.getClubsByList(currentContract.clubIds)
    const data = await getClubsById(currentContract.clubIds)

    let formatClubs = {}
    data.clubs.map((club: any) => {
      formatClubs = { ...formatClubs, [club.RowKey]: club }
    })

    // this.setState({ clubs: { ...clubs, ...clubsOfContract } })
    this.setState({ clubs: { ...clubs, ...formatClubs } })

    if (this.teamContainer) {
      // const teamResult = await this.teamContainer.fetchTeamsByClubList(Object.keys(clubsOfContract))
      const teamResult = await this.teamContainer.fetchTeamsByTeamList(data.teams)
      //console.log(teamResult)

      const teams = teamResult.teams
      if (this.competitionContainer) {
        //if (teams) await this.competitionContainer.fetchCompetitionsByTeamList(teams.map((t) => t.RowKey))
        this.competitionContainer.fetchCompetitionsByCompetitionList(data.competitions)

        this.setState({ loadingData: false })
      } else {
        console.error('ClubContainer:OnClubDataLoaded: Missing CompetitionContainer. This should not happen!')
      }
    } else {
      console.error('ClubContainer:OnClubDataLoaded: Missing Teamcontainer. This should not happen!')
    }
  }

  /*async getClubsByList(clubList: string[]) {
    assertContainerHasInitializedDiscovery(this)
    const clubs: Record<string, ClubData> = {}
    await Promise.all(
      clubList.map(async (clubId) => {
        if (!this.state.clubs[clubId]) {
          let clubData = await getClubById(clubId)
          clubs[clubData.RowKey] = clubData
        }
      })
    )
    return clubs
  }*/

  setClubHomeGuestById = async (homeId: number | string, guestId: number | string) => {
    assertContainerHasInitializedDiscovery(this)
    let homeClub = await getClubById(homeId)
    let guestClub = await getClubById(guestId)
    const clubs = JSON.parse(JSON.stringify(this.state.clubs))
    clubs[homeId] = homeClub
    clubs[guestId] = guestClub
    this.setState({ clubs })
  }

  createClub = async (body: Partial<ClubData>) => {
    assertContainerHasInitializedDiscovery(this)
    let callAs = ''
    if (this.contractContainer) {
      callAs = searchAndSortGetCallAs([
        Role.admin,
        this.contractContainer.getHighestRelevantRoleForCurrentContract()
      ])
    }

    const res = await postClubById(body, callAs)
    const newClubId = res.RowKey
    const club = await this.fetchPublicClubDataById(newClubId)
    return club
    // return res
  }
}

const clubContainer = new ClubContainer()
export default clubContainer
