import React from 'react'
import { Switch } from '@mobiscroll/react'
import HeaderWrapper from '../../../helper/PageWrapper'
import UserSelect from './container/UserSelect'
import AiswSpinner from '../../helper/aiswSpinner/aiswSpinner'
import i18n from 'i18next'
import StaigeButton from '../../../components/styles/Button'
import addUserIcon from '../../../img/icons/addUser.svg'
import StaigeInput from '../../../components/styles/Input'

import { ContractContainer, UserData } from '../../../services/contractContainer'
import { LicenseType } from '@soccerwatch/common'
import { UserContainer } from '../../../services/userContainer'
import { CityContainer } from '../../../services/cityContainer'
import { ClubContainer } from '../../../services/clubContainer'

import './UserManagement.scss'

type UserManagementLicenseType =
  | LicenseType.ContractOwnerLicense
  | LicenseType.CameraLicense
  | LicenseType.TrainerSubscription
  | LicenseType.ClubTaggerLicense
  | LicenseType.AdManagerAccess
  | LicenseType.ClubStreamerLicense

type UserManagementPropTypes = {
  contractContainer: ContractContainer
  clubContainer: ClubContainer
  cityContainer: CityContainer
  userContainer: UserContainer
  user: UserData[]
}

type UserManagementStateTypes = {
  openUser: UserData | undefined
  newUser: boolean
  waitUserSearch: boolean
  userNotFound: boolean
  mail: string
  checkSelect?: {
    [key: string]: boolean
  }
  contractFormOpen: boolean
  editLicense: Array<{
    check: boolean
    license: Array<any> | undefined
    licenseType: UserManagementLicenseType
  }>
  editLicenseUser: boolean
  saveDataLaod: boolean
}

export default class UserManagement extends React.Component<
  UserManagementPropTypes,
  UserManagementStateTypes
> {
  constructor(props: UserManagementPropTypes) {
    super(props)

    this.state = {
      openUser: undefined,
      newUser: false,
      waitUserSearch: false,
      userNotFound: false,
      mail: '',
      contractFormOpen: false,
      editLicense: [],
      editLicenseUser: false,
      saveDataLaod: false
    }
  }

  private ContractOwnerLicense = React.createRef<any>()
  private CameraLicense = React.createRef<any>()
  private TrainerSubscription = React.createRef<any>()
  private ClubTaggerLicense = React.createRef<any>()
  private AdManagerAccess = React.createRef<any>()
  private ClubStreamerLicense = React.createRef<any>()

  handleOpenUser = (user: UserData | undefined) => {
    this.setState({ openUser: user })
  }

  handleNewUser = (value: boolean) => {
    this.setState({ newUser: value, mail: '' })
  }

  renderUserManagement() {
    if (this.props.contractContainer.state.loadingData) {
      return (
        <div style={{ height: '100%' }}>
          <AiswSpinner />
        </div>
      )
    }
    const contract = this.props.contractContainer.getCurrentContract()
    if (!contract) {
      return (
        <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          NO CONTRACT
        </div>
      )
    }

    const user = this.props.user

    return (
      <div className='userManagement'>
        <div>
          <UserSelect
            contractContainer={this.props.contractContainer}
            userContainer={this.props.userContainer}
            contracts={this.props.contractContainer.state.contracts}
            contractSubUser={this.props.contractContainer.state.contractSubUser}
            loadingData={this.props.contractContainer.state.loadingData}
            handleNewUser={this.handleNewUser}
            handleOpenUser={this.handleOpenUser}
            user={user}
          />
          {this.props.userContainer.state.hasAdminRights && (
            <div className='bottomInfoText'>{i18n.t('administration.userManagement.infoText')}</div>
          )}
        </div>
      </div>
    )
  }

  handleDeleteSubUser = async (license: Array<any> | undefined, licenseType: UserManagementLicenseType) => {
    if (license !== undefined) {
      await this.props.contractContainer.deleteUserSubscriptionList(license)
    }
  }

  handleAddSubUser = async (licenseType: UserManagementLicenseType) => {
    const currentContract = this.props.contractContainer.getCurrentContract()
    if (!currentContract) {
      return console.error('<UserManagement> No Current Contract. This should not happen.')
    }
    if (this.state.openUser) {
      await this.props.contractContainer.postUserSubscription(
        currentContract.RowKey,
        licenseType,
        this.state.openUser?.RowKey
      )
    }
  }

  handleSwitch = async (
    value: boolean,
    license: Array<any> | undefined,
    licenseType: UserManagementLicenseType
  ) => {
    if (!value) {
      await this.handleDeleteSubUser(license, licenseType)
    } else {
      await this.handleAddSubUser(licenseType)
    }
  }

  renderUserRole = (
    licenseType: UserManagementLicenseType,
    title: string,
    text: string,
    superAdmin: boolean
  ) => {
    let license = this.state.openUser?.license.filter((licens) => {
      // Hope this works...
      return (licens.type as unknown as UserManagementLicenseType) === licenseType
    })

    let switchCheck = license !== undefined && license?.length > 0
    if (this.state.checkSelect) {
      switchCheck =
        this.state.checkSelect[licenseType] !== undefined ? this.state.checkSelect[licenseType] : switchCheck
    }

    return (
      <div className='roleBox'>
        <div className='roleBox-left'>
          <div className='role-title'>{title}</div>
          <div className='role-infoText'>{text}</div>
        </div>
        <div className='roleBox-right'>
          <Switch
            ref={this[licenseType]}
            checked={switchCheck}
            onChange={(ev) =>
              this.handleEditUser({ check: ev.target.checked, license: license, licenseType })
            }
            disabled={!superAdmin || (this.state.newUser && !this.state.openUser)}
          />
        </div>
      </div>
    )
  }

  handleEditUser = (option: {
    check: boolean
    license: Array<any> | undefined
    licenseType: UserManagementLicenseType
  }) => {
    const { editLicense } = this.state
    let newObj = [...editLicense]
    const find = newObj.find((obj) => {
      return obj.licenseType === option.licenseType
    })

    if (find) {
      // remove
      newObj = newObj.filter((e) => e.licenseType !== option.licenseType)
    } else {
      // add
      newObj.push(option)
    }

    this.setState({
      editLicense: newObj,
      editLicenseUser: newObj.length > 0
    })
  }

  shortNameExtration(name: string) {
    name = name.trim()
    const words = name.split(' ')
    let shortName = ''
    for (let i = 0; i < words.length; i++) {
      const firstCharFromName = words[i][0].toUpperCase()
      shortName += firstCharFromName
      if (i >= 1) {
        i = words.length
      }
    }
    return shortName
  }

  handleUserEdit = async () => {
    this.setState({
      saveDataLaod: true
    })
    const { editLicense } = this.state
    for (let index = 0; index < editLicense.length; index++) {
      const element = editLicense[index]
      await this.handleSwitch(element.check, element.license, element.licenseType)
    }

    this.setState({
      editLicense: [],
      editLicenseUser: false,
      openUser: undefined,
      saveDataLaod: false,
      newUser: false,
      mail: ''
    })
  }

  renderUserEdit(newUser: boolean) {
    const disabled = this.props.userContainer.state.hasAdminRights
    const { openUser } = this.state

    let btn = (fullW: boolean) => (
      <StaigeButton
        label={
          this.state.openUser?.addNewUser
            ? i18n.t('administration.userManagement.userEdit.addUser')
            : i18n.t('general.save')
        }
        onClick={this.handleUserEdit}
        disabled={disabled && !this.state.editLicenseUser}
        fullWidth={fullW}
      />
    )

    if (newUser && !this.state.openUser?.addNewUser) {
      btn = (fullW: boolean) => (
        <StaigeButton
          label={i18n.t('general.search')}
          onClick={this.handleSearchMail}
          disabled={this.state.mail.length <= 0}
          fullWidth={fullW}
        />
      )
    }

    if (this.state.saveDataLaod) {
      return (
        <div style={{ height: '100%' }}>
          <AiswSpinner />
        </div>
      )
    }

    return (
      <div className='userManagement'>
        <div className='userManagement-content '>
          <div className='content-btn '>
            <div style={{ position: 'fixed' }}>{btn(false)}</div>
          </div>
          <div className='content-userBox'>
            {newUser ? (
              <>
                <StaigeInput
                  onChange={(ev) => this.handleMailAddress(ev.target.value)}
                  label={i18n.t('administration.userManagement.userEdit.newUserInput.lable')}
                  placeholder={i18n.t('administration.userManagement.userEdit.newUserInput.placeholder')}
                  helperText={i18n.t('administration.userManagement.userEdit.newUserInput.helperText')}
                  errorMessage={i18n.t('administration.userManagement.userEdit.mail.errorMessage')}
                  valid={!this.state.userNotFound}
                />
                <div className='userBox-new'>
                  {i18n.t('administration.userManagement.userEdit.userBoxNew')}
                </div>
              </>
            ) : (
              <div className='userBox'>
                <div className='userBox-profile'>
                  {openUser?.thumbnail ? (
                    <img referrerPolicy='no-referrer' src={openUser.thumbnail} />
                  ) : (
                    <div className='shortName'>
                      {openUser?.username ? this.shortNameExtration(openUser?.username) : openUser?.username}
                    </div>
                  )}
                </div>
                <div className='userBox-info'>
                  <div className='name'>{openUser?.username}</div>
                  <div className='mail'>{openUser?.RowKey}</div>
                </div>
              </div>
            )}

            {this.props.contractContainer.getCurrentContract()?.contractOwnerSlots !== 0 &&
              this.renderUserRole(
                LicenseType.ContractOwnerLicense,
                i18n.t('administration.userManagement.userRole.ContractOwnerLicense'),
                i18n.t('administration.userManagement.userRoleDesc.ContractOwnerLicense'),
                disabled
              )}
            {this.props.contractContainer.getCurrentContract()?.cameraOwnerSlots !== 0 &&
              this.renderUserRole(
                LicenseType.CameraLicense,
                i18n.t('administration.userManagement.userRole.CameraLicense'),
                i18n.t('administration.userManagement.userRoleDesc.CameraLicense'),
                disabled
              )}
            {this.props.contractContainer.getCurrentContract()?.trainerSlots !== 0 &&
              this.renderUserRole(
                LicenseType.TrainerSubscription,
                i18n.t('administration.userManagement.userRole.TrainerSubscription'),
                i18n.t('administration.userManagement.userRoleDesc.TrainerSubscription'),
                disabled
              )}
            {this.props.contractContainer.getCurrentContract()?.adManagerSlots !== 0 &&
              this.renderUserRole(
                LicenseType.AdManagerAccess,
                i18n.t('administration.userManagement.userRole.AdManagerAccess'),
                i18n.t('administration.userManagement.userRoleDesc.AdManagerAccess'),
                disabled
              )}
            {this.props.contractContainer.getCurrentContract()?.taggerSlots !== 0 &&
              this.renderUserRole(
                LicenseType.ClubTaggerLicense,
                i18n.t('administration.userManagement.userRole.ClubTaggerLicense'),
                i18n.t('administration.userManagement.userRoleDesc.ClubTaggerLicense'),
                disabled
              )}
            {/* ? Enable once Clubstreamer can officially be set */}
            {this.props.contractContainer.getCurrentContract()?.streamerSlots !== 0 &&
              this.renderUserRole(
                LicenseType.ClubStreamerLicense,
                i18n.t('administration.userManagement.userRole.ClubStreamerLicense'),
                i18n.t('administration.userManagement.userRoleDesc.ClubStreamerLicense'),
                disabled
              )}

            <div className='bottom-btn'>{btn(true)}</div>
          </div>
        </div>
      </div>
    )
  }

  handleSearchMail = async () => {
    this.setState({
      waitUserSearch: true,
      userNotFound: false
    })

    const res = await this.props.contractContainer.getCheckUserMail(this.state.mail)
    const userData = this.props.userContainer.state.user?.user_id
    const giveUser = this.props.user.find((u) => u.RowKey === res.uid) || userData === res.uid

    if (res.uid && !giveUser) {
      const defaultUser = {
        RowKey: res.uid,
        username: res.displayName,
        thumbnail: '',
        license: [],
        myLicenses: false,
        addNewUser: true
      }

      this.setState({
        waitUserSearch: false,
        openUser: defaultUser
      })
    }

    if (!res.userExists) {
      this.setState({
        waitUserSearch: false,
        userNotFound: true
      })
    }
  }

  handleMailAddress = (value: string) => {
    this.setState({
      mail: value,
      userNotFound: false
    })
  }

  render() {
    const { openUser, newUser } = this.state

    if (openUser) {
      return (
        <HeaderWrapper
          headerTitle={openUser?.username}
          onClick={() => this.handleOpenUser(undefined)}
          style={{ height: '100%' }}
        >
          {this.renderUserEdit(false)}
        </HeaderWrapper>
      )
    }

    if (newUser) {
      return (
        <HeaderWrapper
          headerTitle={i18n.t('administration.userManagement.userEdit.addUser')}
          onClick={() => this.handleNewUser(false)}
          style={{ height: '100%' }}
        >
          {this.renderUserEdit(true)}
        </HeaderWrapper>
      )
    }

    return (
      <HeaderWrapper
        headerTitle={i18n.t('administration.userManagement.userEdit.user')}
        linkTo='/administration'
        quickButton={<StaigeButton icon={addUserIcon} onClick={() => this.handleNewUser(true)} round />}
        style={{ height: '100%' }}
      >
        {this.renderUserManagement()}
      </HeaderWrapper>
    )
  }
}
