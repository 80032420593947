import { Role } from '@soccerwatch/common'
import UserLogin from './userContainer'

export function getCallAs(role: Role) {
  const userRoles = UserLogin.getUserRoles()
  const userHasRole = userRoles.indexOf(role) > -1
  const callAs = userHasRole ? `?callAs=${role}` : ''
  return callAs
}

export function searchAndSortGetCallAs(roles: Array<Role>) {
  return `?callAs=${searchAndSortGetRoles(roles)}`
}

export function searchAndSortGetRoles(roles: Array<Role>) {
  const userRoles = UserLogin.getUserRoles()
  let callAs = ''
  for (let index = 0; index < roles.length; index++) {
    const userHasRole = userRoles.indexOf(roles[index]) > -1
    if (userHasRole) {
      callAs = `${roles[index]}`
      index = roles.length
    }
  }
  return callAs
}
