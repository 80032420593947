type TEventButtons = Array<{
  name: string
  nameEn: string
  eventTypes: any
  body?: any
}>

type eventType = {
  id: number
  startClockWithSeconds?: number
  clockReverse?: boolean
  startClock?: boolean
  stopClock?: boolean
  pauseClock?: boolean
  period?: number
  level?: number
}

export type TEventSettings = {
  [sportType: string]: {
    eventTime: Array<{
      id: number
      name: string
      nameEn: string
      eventType: {
        id: number
        startClockWithSeconds?: number
        clockReverse?: boolean
        startClock?: boolean
        stopClock?: boolean
        pauseClock?: boolean
        period?: number
        level?: number
      }
    }>

    eventTimeBeta: Array<{
      id: number
      name: string
      nameEn: string
      eventTypes: {
        start: eventType
        end: eventType | undefined
      }
      eventType?: {
        id: number
        startClockWithSeconds?: number
        clockReverse?: boolean
        startClock?: boolean
        stopClock?: boolean
        pauseClock?: boolean
        period?: number
        level?: number
      }
    }>

    eventTimeOut?: {
      start: {
        name: string
        nameEn: string
        maxTimeouts: number
        eventTypes: Array<number>
        body: { stopClock: boolean }
      }
      end: {
        name: string
        nameEn: string
        eventType: { id: number; startClock: boolean }
      }
    }

    eventOnlyStartStopTimer?: {
      stopTimer: {
        name: string
        nameEn: string
        eventType: { id: number; startClock?: boolean; stopClock?: boolean }
        stopType: true
      }
      startTimer: {
        name: string
        nameEn: string
        eventType: { id: number; startClock: true }
      }
    }
    eventShotClock?: {
      name: string
      nameEn: string
      eventTypes: Array<{
        stopClock?: boolean
        name: string
        nameEn: string
        bodyConfig: {
          id: number
          clockType: string
          startClockWithSeconds?: number
          clockReverse?: boolean
          startClock?: boolean
          stopClock?: boolean
        }
      }>
    }
    eventButtons: {
      single: TEventButtons
      double: TEventButtons
    }
  }
}

export const EVENTSETTINGS: TEventSettings = {
  soccer: {
    eventTimeBeta: [
      {
        id: 0,
        name: '1 Halbzeit',
        nameEn: 'Start 1. FH',
        eventTypes: {
          start: { id: 12, level: 1, startClockWithSeconds: 0 },
          end: { id: 13, pauseClock: true }
        }
      },
      {
        id: 1,
        name: '2 Halbzeit',
        nameEn: 'Start 1. FH',
        eventTypes: { start: { id: 14, level: 1, startClockWithSeconds: 2700 }, end: { id: 15, level: 1 } }
      },
      {
        id: 2,
        name: '1 Verlängerung',
        nameEn: 'Start 1. FH',
        eventTypes: {
          start: { id: 54, level: 1, startClockWithSeconds: 5400 },
          end: { id: 55, pauseClock: true }
        }
      },
      {
        id: 3,
        name: '2 Verlängerung',
        nameEn: 'Start 1. FH',
        eventTypes: { start: { id: 56, level: 1, startClockWithSeconds: 6300 }, end: { id: 57, level: 1 } }
      }
    ],
    eventTime: [
      {
        id: 0,
        name: 'Start 1. HZ',
        nameEn: 'Start 1. FH',
        eventType: { id: 12, level: 1 }
      },
      {
        id: 1,
        name: 'Ende 1. HZ',
        nameEn: 'End 1. FH',
        eventType: { id: 13, pauseClock: true }
      },
      {
        id: 2,
        name: 'Start 2. HZ',
        nameEn: 'Start 2. SH',
        eventType: { id: 14, level: 1 }
      },
      {
        id: 3,
        name: 'Ende 2. HZ',
        nameEn: 'End 2. SH',
        eventType: { id: 15, level: 1 }
      },
      {
        id: 4,
        name: 'Start 1. VL',
        nameEn: 'Start 1. OT',
        eventType: { id: 54, level: 1 }
      },
      {
        id: 5,
        name: 'Ende 1. VL',
        nameEn: 'End 1. OT',
        eventType: { id: 55, pauseClock: true }
      },
      {
        id: 6,
        name: 'Start 2. VL',
        nameEn: 'Start 2. OT',
        eventType: { id: 56, level: 1 }
      },
      {
        id: 7,
        name: 'Ende 2. VL',
        nameEn: 'End 2. OT',
        eventType: { id: 57, level: 1 }
      }
    ],
    eventButtons: {
      single: [
        {
          name: 'Verlängerung',
          nameEn: 'Overtime',
          eventTypes: 27
        },
        {
          name: 'markieren',
          nameEn: 'mark',
          eventTypes: 127,
          body: { private: true }
        },
        {
          name: 'Tricks',
          nameEn: 'Tricks',
          eventTypes: 16
        },
        {
          name: 'Anstoß',
          nameEn: 'Kick off',
          eventTypes: 47
        },
        {
          name: 'Sonstiges',
          nameEn: 'Other',
          eventTypes: 53
        } /*
        {
          name: 'Trick Pass',
          nameEn: 'Trick Pass',
          eventTypes: 17
        },
        {
          name: 'Trick Schuss',
          nameEn: 'Trick shot',
          eventTypes: 18
        },
        {
          name: 'Flitzer',
          nameEn: 'Naked Guy',
          eventTypes: 19
        },
        {
          name: 'Freude',
          nameEn: 'Happiness',
          eventTypes: 20
        },
        {
          name: 'Trauer',
          nameEn: 'Sadness',
          eventTypes: 21
        },
        {
          name: 'Aerger',
          nameEn: 'anger',
          eventTypes: 22
        },
        {
          name: 'Eskalation',
          nameEn: 'Escalation',
          eventTypes: 23
        },
        {
          name: 'Schlaegerei',
          nameEn: 'Fight',
          eventTypes: 24
        },
        {
          name: 'Kopfball',
          nameEn: 'header',
          eventTypes: 25
        },
       {
          name: 'Ende',
          nameEn: 'end',
          eventTypes: 28
        },
        {
          name: 'Kamera falsch',
          nameEn: 'Camera error',
          eventTypes: 29
        },
        {
          name: 'Bildfehler',
          nameEn: 'Image error',
          eventTypes: 30
        },
        {
          name: 'Spiel Löschen!',
          nameEn: 'Delete match!',
          eventTypes: 31
        },
        {
          name: 'Spiel OK!',
          nameEn: 'Match OK!',
          eventTypes: 32
        },*/
      ],
      double: [
        {
          name: 'Tor',
          nameEn: 'Goal',
          eventTypes: [0, 1],
          body: { level: 1 }
        },
        {
          name: 'Torchance',
          nameEn: 'Torchance',
          eventTypes: [2, 3],
          body: { level: 1 }
        },
        {
          name: 'Ecke',
          nameEn: 'Corner',
          eventTypes: [35, 36]
        },
        {
          name: 'Gelbe Karte',
          nameEn: 'Yellow Card',
          eventTypes: [8, 9]
        },
        {
          name: 'Rote Karte',
          nameEn: 'Red Card',
          eventTypes: [10, 11],
          body: { level: 1 }
        },
        {
          name: 'Foul',
          nameEn: 'Foul',
          eventTypes: [4, 5]
        },
        {
          name: '11.Meter',
          nameEn: '11.Meters',
          eventTypes: [6, 7],
          body: { level: 1 }
        },
        {
          name: 'Freistoß',
          nameEn: 'free kick',
          eventTypes: [37, 38]
        },
        {
          name: 'Abseits',
          nameEn: 'Offside',
          eventTypes: [33, 34]
        },
        {
          name: 'Abseitstor',
          nameEn: 'Offside goal',
          eventTypes: [45, 46]
        },
        {
          name: 'Parade',
          nameEn: 'defence',
          eventTypes: [41, 42]
        },
        {
          name: 'Verletzung',
          nameEn: 'injury',
          eventTypes: [43, 44]
        }
      ]
    }
  },
  basketball: {
    eventTimeBeta: [
      {
        id: 0,
        name: 'Start 1. Vt',
        nameEn: 'Start 1. Q',
        eventTypes: {
          start: { id: 200, startClockWithSeconds: 600, clockReverse: true },
          end: undefined
        }
      },
      {
        id: 1,
        name: 'Start 2. Vt',
        nameEn: 'Start 2. Q',
        eventTypes: {
          start: { id: 202, startClockWithSeconds: 600, clockReverse: true },
          end: undefined
        }
      },
      {
        id: 2,
        name: 'Start 3. Vt',
        nameEn: 'Start 3. Q',
        eventTypes: {
          start: { id: 204, startClockWithSeconds: 600, clockReverse: true },
          end: undefined
        }
      },
      {
        id: 3,
        name: 'Start 4. Vt',
        nameEn: 'Start 4. Q',
        eventTypes: {
          start: { id: 206, startClockWithSeconds: 600, clockReverse: true },
          end: undefined
        }
      }
    ],
    eventTime: [
      {
        id: 0,
        name: 'Start 1. Vt',
        nameEn: 'Start 1. Q',
        eventType: { id: 200, startClockWithSeconds: 600, clockReverse: true }
      },
      {
        id: 1,
        name: 'Start 2. Vt',
        nameEn: 'Start 2. Q',
        eventType: { id: 202, startClockWithSeconds: 600, clockReverse: true }
      },
      {
        id: 2,
        name: 'Start 3. Vt',
        nameEn: 'Start 3. Q',
        eventType: { id: 204, startClockWithSeconds: 600, clockReverse: true }
      },
      {
        id: 3,
        name: 'Start 4. Vt',
        nameEn: 'Start 4. Q',
        eventType: { id: 206, startClockWithSeconds: 600, clockReverse: true }
      }
    ],
    eventTimeOut: {
      start: {
        name: 'Auszeit',
        nameEn: 'timeout',
        maxTimeouts: 5,
        eventTypes: [428, 429],
        body: { stopClock: true }
      },
      end: {
        name: 'Ende',
        nameEn: 'end',
        eventType: { id: 231, startClock: true }
      }
    },
    eventOnlyStartStopTimer: {
      stopTimer: {
        name: 'Auszeit',
        nameEn: 'Time break',
        eventType: { id: 230, stopClock: true },
        stopType: true
      },
      startTimer: {
        name: 'Weiter',
        nameEn: 'Resume',
        eventType: { id: 231, startClock: true }
      }
    },
    eventShotClock: {
      name: 'Shotclock',
      nameEn: 'Shotclock',
      eventTypes: [
        {
          name: 'Shotclock reset',
          nameEn: 'Shotclock reset',
          bodyConfig: {
            id: 236,
            clockType: 'shotClock',
            startClockWithSeconds: 24,
            clockReverse: true,
            startClock: true
          }
        },
        {
          name: 'Shotclock start',
          nameEn: 'Shotclock start',
          bodyConfig: { id: 237, clockType: 'shotClock', clockReverse: true, startClock: true }
        },
        {
          name: 'Shotclock stop',
          nameEn: 'Shotclock stop',
          bodyConfig: { id: 238, clockType: 'shotClock', clockReverse: true, stopClock: true }
        }
      ]
    },
    eventButtons: {
      single: [
        {
          name: 'Anstoß',
          nameEn: 'Kick off',
          eventTypes: 47
        },
        {
          name: 'offense',
          nameEn: 'offense',
          eventTypes: 49
        },
        {
          name: 'defense',
          nameEn: 'defense',
          eventTypes: 50
        },
        {
          eventTypes: 127,
          name: 'markieren',
          nameEn: 'mark',
          body: { private: true }
        },
        {
          name: 'Sonstiges',
          nameEn: 'Other',
          eventTypes: 53
        }
      ],
      double: [
        {
          name: '1 Punkt',
          nameEn: '1 Point',
          eventTypes: [260, 261],
          body: {
            score: 1
          }
        },
        {
          name: '2 Punkte',
          nameEn: '2 Points',
          eventTypes: [262, 263],
          body: {
            score: 2
          }
        },
        {
          name: '3 Punkte',
          nameEn: '3 Points',
          eventTypes: [264, 265],
          body: {
            score: 3
          }
        },
        {
          name: 'Foul',
          nameEn: 'Foul',
          eventTypes: [4, 5]
        }
      ]
    }
  },
  icehockey: {
    eventTimeBeta: [
      {
        id: 0,
        name: '1. Drittel',
        nameEn: '1. Thirds',
        eventTypes: {
          start: { id: 200, startClockWithSeconds: 1200, clockReverse: true, period: 1, startClock: true },
          end: undefined // { id: 201, stopClock: true, pauseClock: true }
        }
      },
      {
        id: 1,
        name: '2. Drittel',
        nameEn: '2. Thirds',
        eventTypes: {
          start: { id: 202, startClockWithSeconds: 1200, clockReverse: true, period: 2, startClock: true },
          end: undefined //  { id: 203, stopClock: true, pauseClock: true }
        }
      },
      {
        id: 2,
        name: '3. Drittel',
        nameEn: '3. Thirds',
        eventTypes: {
          start: { id: 204, startClockWithSeconds: 1200, clockReverse: true, period: 3, startClock: true },
          end: undefined //  { id: 205, stopClock: true }
        }
      }
    ],
    eventTime: [],
    eventTimeOut: {
      start: {
        name: 'Auszeit',
        nameEn: 'timeout',
        maxTimeouts: 1,
        eventTypes: [428, 429],
        body: { stopClock: true }
      },
      end: {
        name: 'Ende',
        nameEn: 'end',
        eventType: { id: 231, startClock: true }
      }
    },
    eventOnlyStartStopTimer: {
      stopTimer: {
        name: 'Auszeit',
        nameEn: 'Time break',
        eventType: { id: 230, stopClock: true },
        stopType: true
      },
      startTimer: {
        name: 'Weiter',
        nameEn: 'Resume',
        eventType: { id: 231, startClock: true }
      }
    },
    eventButtons: {
      single: [
        {
          name: 'Sonstiges',
          nameEn: 'Other',
          eventTypes: 53
        }
      ],
      double: [
        {
          name: 'Tor',
          nameEn: 'Goal',
          eventTypes: [0, 1]
        },
        {
          name: 'Torchance',
          nameEn: 'Scoring chance',
          eventTypes: [2, 3]
        },
        {
          name: 'Strafzeit',
          nameEn: 'Penalty',
          eventTypes: [110, 111]
        },
        {
          name: 'Powerplay',
          nameEn: 'Powerplay',
          eventTypes: [70, 71]
        },
        {
          name: 'Icing',
          nameEn: 'Icing',
          eventTypes: [72, 73]
        },
        {
          name: 'Offside',
          nameEn: 'Offside',
          eventTypes: [33, 34]
        },
        {
          name: 'Face-off',
          nameEn: 'Face-off',
          eventTypes: [74, 75]
        }
      ]
    }
  },
  hockey: {
    eventTimeBeta: [
      {
        id: 0,
        name: 'Start 1. Vt',
        nameEn: 'Start 1. Q',
        eventTypes: {
          start: { id: 200, startClockWithSeconds: 900, clockReverse: true, period: 1, startClock: true },
          end: undefined // { id: 201, stopClock: true, pauseClock: true }
        }
      },
      {
        id: 1,
        name: 'Start 2. Vt',
        nameEn: 'Start 2. Q',
        eventTypes: {
          start: { id: 202, startClockWithSeconds: 900, clockReverse: true, period: 2, startClock: true },
          end: undefined // { id: 203, stopClock: true, pauseClock: true }
        }
      },
      {
        id: 2,
        name: 'Start 3. Vt',
        nameEn: 'Start 3. Q',
        eventTypes: {
          start: { id: 204, startClockWithSeconds: 900, clockReverse: true, period: 3, startClock: true },
          end: undefined // { id: 205, stopClock: true }
        }
      },
      {
        id: 3,
        name: 'Start 4. Vt',
        nameEn: 'Start 4. Q',
        eventTypes: {
          start: { id: 206, startClockWithSeconds: 900, clockReverse: true, period: 4, startClock: true },
          end: undefined // { id: 207, stopClock: true }
        }
      }
    ],
    eventTime: [],
    eventTimeOut: {
      start: {
        name: 'Auszeit',
        nameEn: 'timeout',
        maxTimeouts: 1,
        eventTypes: [428, 429],
        body: { stopClock: true }
      },
      end: {
        name: 'Ende',
        nameEn: 'end',
        eventType: { id: 231, startClock: true }
      }
    },
    eventOnlyStartStopTimer: {
      stopTimer: {
        name: 'Auszeit',
        nameEn: 'Time break',
        eventType: { id: 230, stopClock: true },
        stopType: true
      },
      startTimer: {
        name: 'Weiter',
        nameEn: 'Resume',
        eventType: { id: 231, startClock: true }
      }
    },
    eventButtons: {
      single: [
        {
          name: 'Sonstiges',
          nameEn: 'Other',
          eventTypes: 53
        }
      ],
      double: [
        {
          name: 'Tor',
          nameEn: 'Goal',
          eventTypes: [0, 1]
        },
        {
          name: 'Torchance',
          nameEn: 'Torchance',
          eventTypes: [2, 3],
          body: { level: 1 }
        },
        {
          name: 'Strafwurf',
          nameEn: 'penalty throw',
          eventTypes: [503, 504]
        },
        {
          name: 'Strafecke',
          nameEn: 'Penalty corner',
          eventTypes: [110, 111]
        },
        {
          name: 'Foul',
          nameEn: 'Foul',
          eventTypes: [4, 5]
        },
        {
          name: 'Ballgewinn',
          nameEn: 'Ball win',
          eventTypes: [174, 175]
        },
        {
          name: 'Ballverlust',
          nameEn: 'Ball loss',
          eventTypes: [176, 177]
        },
        {
          name: 'Konter',
          nameEn: 'Counterattack',
          eventTypes: [178, 179]
        },
        {
          name: 'Grüne Karte',
          nameEn: 'Green Card',
          eventTypes: [500, 501]
        },
        {
          name: 'Gelbe Karte',
          nameEn: 'Yellow Card',
          eventTypes: [8, 9]
        },
        {
          name: 'Rote Karte',
          nameEn: 'Red Card',
          eventTypes: [10, 11]
        }
      ]
    }
  },
  waterpolo: {
    eventTimeBeta: [
      {
        id: 0,
        name: 'Start 1. Vt',
        nameEn: 'Start 1. Q',
        eventTypes: {
          start: { id: 200, startClockWithSeconds: 480, clockReverse: true },
          end: undefined
        }
      },
      {
        id: 1,
        name: 'Start 2. Vt',
        nameEn: 'Start 2. Q',
        eventTypes: {
          start: { id: 202, startClockWithSeconds: 480, clockReverse: true },
          end: undefined
        }
      },
      {
        id: 2,
        name: 'Start 3. Vt',
        nameEn: 'Start 3. Q',
        eventTypes: {
          start: { id: 204, startClockWithSeconds: 480, clockReverse: true },
          end: undefined
        }
      },
      {
        id: 3,
        name: 'Start 4. Vt',
        nameEn: 'Start 4. Q',
        eventTypes: {
          start: { id: 206, startClockWithSeconds: 480, clockReverse: true },
          end: undefined
        }
      }
    ],
    eventTime: [
      {
        id: 0,
        name: '1. Vt',
        nameEn: '1. Q',
        eventType: { id: 200, startClockWithSeconds: 480, clockReverse: true }
      },
      {
        id: 1,
        name: '2. Vt',
        nameEn: '2. Q',
        eventType: { id: 202, startClockWithSeconds: 480, clockReverse: true }
      },
      {
        id: 2,
        name: '3. Vt',
        nameEn: '3. Q',
        eventType: { id: 204, startClockWithSeconds: 480, clockReverse: true }
      },
      {
        id: 3,
        name: '4. Vt',
        nameEn: '4. Q',
        eventType: { id: 206, startClockWithSeconds: 480, clockReverse: true }
      }
    ],
    eventTimeOut: {
      start: {
        name: 'Auszeit',
        nameEn: 'timeout',
        maxTimeouts: 2,
        eventTypes: [428, 429],
        body: { stopClock: true }
      },
      end: {
        name: 'Ende',
        nameEn: 'end',
        eventType: { id: 231, startClock: true }
      }
    },
    eventOnlyStartStopTimer: {
      stopTimer: {
        name: 'Auszeit',
        nameEn: 'Time break',
        eventType: { id: 230, stopClock: true },
        stopType: true
      },
      startTimer: {
        name: 'Weiter',
        nameEn: 'Resume',
        eventType: { id: 231, startClock: true }
      }
    },
    eventButtons: {
      single: [
        {
          name: 'Sonstiges',
          nameEn: 'Other',
          eventTypes: 53
        }
      ],
      double: [
        {
          name: 'Tor',
          nameEn: 'Goal',
          eventTypes: [0, 1]
        },
        {
          name: 'Torchance',
          nameEn: 'Torchance',
          eventTypes: [2, 3]
        },
        {
          name: 'Strafwurffehler',
          nameEn: 'penalty throw error',
          eventTypes: [426, 427]
        },
        {
          name: 'Freeiwurf',
          nameEn: 'Free throw',
          eventTypes: [172, 173]
        },
        {
          name: 'Foul',
          nameEn: 'Foul',
          eventTypes: [4, 5]
        },
        {
          name: 'Ballgewinn',
          nameEn: 'Ball win',
          eventTypes: [174, 175]
        },
        {
          name: 'Ballverlust',
          nameEn: 'Ball loss',
          eventTypes: [176, 177]
        },
        {
          name: 'Konter',
          nameEn: 'Counterattack',
          eventTypes: [178, 179]
        },
        {
          name: 'Ausschluss',
          nameEn: 'Exclusion',
          eventTypes: [418, 419]
        }
      ]
    }
  },
  handball: {
    eventTimeBeta: [
      {
        id: 0,
        name: 'Start 1. Rd',
        nameEn: 'Start 1st',
        eventTypes: {
          start: { id: 200, startClockWithSeconds: 0 },
          end: { id: 201, stopClock: true, pauseClock: true }
        }
      },
      {
        id: 1,
        name: 'Start 2. Rd',
        nameEn: 'Start 2nd',
        eventTypes: {
          start: { id: 202, startClockWithSeconds: 1800 },
          end: { id: 203, stopClock: true }
        }
      }
    ],
    eventTime: [
      {
        id: 0,
        name: 'Start 1. Rd',
        nameEn: 'Start 1st',
        eventType: { id: 200, startClockWithSeconds: 0 }
      },
      {
        id: 1,
        name: 'Ende 1. Rd',
        nameEn: 'End 1st',
        eventType: { id: 201, stopClock: true, pauseClock: true }
      },
      {
        id: 3,
        name: 'Start 2. Rd',
        nameEn: 'Start 2nd',
        eventType: { id: 202, startClockWithSeconds: 1800 }
      },
      {
        id: 4,
        name: 'Ende 2. Rd',
        nameEn: 'End 2nd',
        eventType: { id: 203, stopClock: true }
      }
    ],
    eventTimeOut: {
      start: {
        name: 'Auszeit',
        nameEn: 'timeout',
        maxTimeouts: 3,
        eventTypes: [428, 429],
        body: { stopClock: true }
      },
      end: {
        name: 'Ende',
        nameEn: 'end',
        eventType: { id: 231, startClock: true }
      }
    },
    eventOnlyStartStopTimer: {
      stopTimer: {
        name: 'Auszeit',
        nameEn: 'Time break',
        eventType: { id: 230, stopClock: true },
        stopType: true
      },
      startTimer: {
        name: 'Weiter',
        nameEn: 'Resume',
        eventType: { id: 231, startClock: true }
      }
    },
    eventButtons: {
      single: [
        {
          name: 'markieren',
          nameEn: 'mark',
          eventTypes: 127,
          body: { private: true }
        },
        {
          name: 'Sonstiges',
          nameEn: 'Other',
          eventTypes: 53
        }
      ],
      double: [
        {
          name: 'Tor',
          nameEn: 'Goal',
          eventTypes: [0, 1]
        },
        {
          name: 'Torchance',
          nameEn: 'Torchance',
          eventTypes: [2, 3]
        },
        {
          name: '7 Meter',
          nameEn: '7 Meter',
          eventTypes: [170, 171]
        },
        {
          name: 'Freiwurf',
          nameEn: 'Free Throw',
          eventTypes: [172, 173]
        },
        {
          name: 'Foul',
          nameEn: 'Foul',
          eventTypes: [4, 5]
        },
        {
          name: '2 Minuten Strafe',
          nameEn: '2 minute penalty',
          eventTypes: [232, 233]
        },
        {
          name: 'Ballgewinn',
          nameEn: 'Ball win',
          eventTypes: [174, 175]
        },
        {
          name: 'Ballverlust',
          nameEn: 'Ball loss',
          eventTypes: [176, 177]
        },
        {
          name: 'Konter',
          nameEn: 'Counterattack',
          eventTypes: [178, 179]
        },
        {
          name: 'Block',
          nameEn: 'Block',
          eventTypes: [180, 181]
        },
        {
          name: 'Gelbe Karte',
          nameEn: 'Yellow Card',
          eventTypes: [8, 9]
        },
        {
          name: 'Rote Karte',
          nameEn: 'Red Card',
          eventTypes: [10, 11]
        }
      ]
    }
  },
  baseball: {
    eventTimeBeta: [],
    eventTime: [],
    eventButtons: {
      single: [],
      double: [
        {
          name: 'Run',
          nameEn: 'Run',
          eventTypes: [0, 1] // [171,172] but this one are not working with scores..
        }
      ]
    }
  },
  volleyball: {
    eventTimeBeta: [],
    eventTime: [],
    eventButtons: {
      single: [
        {
          name: 'markieren',
          nameEn: 'mark',
          eventTypes: 127,
          body: { private: true }
        },
        {
          name: 'Keine Angabe',
          nameEn: 'Serve none',
          eventTypes: 212
        },
        {
          name: 'Sonstiges',
          nameEn: 'Other',
          eventTypes: 53
        }
      ],
      double: [
        /*{
          name: '1 Punkt',
          nameEn: '1 Point',
          eventTypes: [0, 1]
        },*/
        {
          name: 'Angabe',
          nameEn: 'Serve',
          eventTypes: [210, 211]
        },
        {
          name: 'Punkt',
          nameEn: 'Point',
          eventTypes: [342, 343],
          body: {
            score: 1
          }
        },
        {
          name: 'Satz',
          nameEn: 'Set',
          eventTypes: [340, 341]
        }
      ]
    }
  },
  horses: {
    eventTimeBeta: [],
    eventTime: [],
    eventButtons: {
      single: [],
      double: []
    }
  },
  dressage: {
    eventTimeBeta: [],
    eventTime: [],
    eventButtons: {
      single: [],
      double: []
    }
  },
  tennis: {
    eventTimeBeta: [
      {
        id: 0,
        name: 'Start',
        nameEn: 'Start',
        eventTypes: {
          start: { id: 200, startClockWithSeconds: 0 },
          end: undefined
        }
      }
    ],
    eventTime: [
      {
        id: 0,
        name: 'Start',
        nameEn: 'Start',
        eventType: { id: 200, startClockWithSeconds: 0 }
      }
    ],
    eventOnlyStartStopTimer: {
      stopTimer: {
        name: 'Stop',
        nameEn: 'Stop',
        eventType: { id: 230, stopClock: true },
        stopType: true
      },
      startTimer: {
        name: 'Weiter',
        nameEn: 'Resume',
        eventType: { id: 231, startClock: true }
      }
    },
    eventButtons: {
      single: [
        {
          name: 'Tiebreak',
          nameEn: 'Tiebreak',
          eventTypes: 189,
          body: { tiebreak: true }
        },
        {
          name: 'Kein Aufschlag',
          nameEn: 'Serve none',
          eventTypes: 212,
          body: {
            serveHome: false,
            serveGuest: false
          }
        },
        {
          name: 'Sonstiges',
          nameEn: 'Other',
          eventTypes: 53
        }
      ],
      double: [
        {
          name: 'Aufschlag',
          nameEn: 'Serve',
          eventTypes: [210, 211]
        },
        {
          name: 'Punkt',
          nameEn: 'Point',
          eventTypes: [185, 186],
          body: {
            score: 15
          }
        },
        {
          name: 'Spiel',
          nameEn: 'Game',
          eventTypes: [187, 188]
        },
        {
          name: 'Satz',
          nameEn: 'Set',
          eventTypes: [183, 184]
        },
        {
          name: 'Punkt Tiebreak',
          nameEn: 'Point Tiebreak',
          eventTypes: [191, 192]
        }
      ]
    }
  },
  padeltennis: {
    eventTimeBeta: [
      {
        id: 0,
        name: 'Start',
        nameEn: 'Start',
        eventTypes: {
          start: { id: 200, startClockWithSeconds: 0 },
          end: undefined
        }
      }
    ],
    eventTime: [
      {
        id: 0,
        name: 'Start',
        nameEn: 'Start',
        eventType: { id: 200, startClockWithSeconds: 0 }
      }
    ],
    eventOnlyStartStopTimer: {
      stopTimer: {
        name: 'Stop',
        nameEn: 'Stop',
        eventType: { id: 230, stopClock: true },
        stopType: true
      },
      startTimer: {
        name: 'Weiter',
        nameEn: 'Resume',
        eventType: { id: 231, startClock: true }
      }
    },
    eventButtons: {
      single: [
        {
          name: 'Tiebreak',
          nameEn: 'Tiebreak',
          eventTypes: 189,
          body: { tiebreak: true }
        },
        {
          name: 'Kein Aufschlag',
          nameEn: 'Serve none',
          eventTypes: 212,
          body: {
            serveHome: false,
            serveGuest: false
          }
        },
        {
          name: 'Sonstiges',
          nameEn: 'Other',
          eventTypes: 53
        }
      ],
      double: [
        {
          name: 'Aufschlag',
          nameEn: 'Serve',
          eventTypes: [210, 211]
        },
        {
          name: 'Punkt',
          nameEn: 'Point',
          eventTypes: [185, 186],
          body: {
            score: 15
          }
        },
        {
          name: 'Spiel',
          nameEn: 'Game',
          eventTypes: [187, 188]
        },
        {
          name: 'Satz',
          nameEn: 'Set',
          eventTypes: [183, 184]
        },
        {
          name: 'Punkt Tiebreak',
          nameEn: 'Point Tiebreak',
          eventTypes: [191, 192]
        }
      ]
    }
  },
  americanFootball: {
    eventTimeBeta: [
      {
        id: 0,
        name: 'Start 1. Vt',
        nameEn: 'Start 1. Q',
        eventTypes: {
          start: { id: 200, startClockWithSeconds: 900, clockReverse: true },
          end: undefined
        }
      },
      {
        id: 1,
        name: 'Start 2. Vt',
        nameEn: 'Start 2. Q',
        eventTypes: {
          start: { id: 202, startClockWithSeconds: 900, clockReverse: true },
          end: undefined
        }
      },
      {
        id: 2,
        name: 'Start 3. Vt',
        nameEn: 'Start 3. Q',
        eventTypes: {
          start: { id: 204, startClockWithSeconds: 900, clockReverse: true },
          end: undefined
        }
      },
      {
        id: 3,
        name: 'Start 4. Vt',
        nameEn: 'Start 4. Q',
        eventTypes: {
          start: { id: 206, startClockWithSeconds: 900, clockReverse: true },
          end: undefined
        }
      },
      {
        id: 3,
        name: 'Overtime',
        nameEn: 'Overtime',
        eventTypes: {
          start: { id: 208, startClockWithSeconds: 900, clockReverse: true },
          end: undefined
        }
      }
    ],
    eventTime: [
      {
        id: 0,
        name: 'Start 1. Vt',
        nameEn: 'Start 1. Q',
        eventType: { id: 200, startClockWithSeconds: 900, clockReverse: true }
      },
      {
        id: 1,
        name: 'Start 2. Vt',
        nameEn: 'Start 2. Q',
        eventType: { id: 202, startClockWithSeconds: 900, clockReverse: true }
      },
      {
        id: 2,
        name: 'Start 3. Vt',
        nameEn: 'Start 3. Q',
        eventType: { id: 204, startClockWithSeconds: 900, clockReverse: true }
      },
      {
        id: 3,
        name: 'Start 4. Vt',
        nameEn: 'Start 4. Q',
        eventType: { id: 206, startClockWithSeconds: 900, clockReverse: true }
      },
      {
        id: 4,
        name: 'Overtime',
        nameEn: 'Overtime',
        eventType: { id: 208, startClockWithSeconds: 900, clockReverse: true }
      }
    ],
    eventOnlyStartStopTimer: {
      stopTimer: {
        name: 'Stop',
        nameEn: 'Stop',
        eventType: { id: 230, stopClock: true },
        stopType: true
      },
      startTimer: {
        name: 'Weiter',
        nameEn: 'Resume',
        eventType: { id: 231, startClock: true }
      }
    },
    eventButtons: {
      single: [
        {
          name: 'Interception',
          nameEn: 'Interception',
          eventTypes: 390
        },
        {
          name: 'Fumble',
          nameEn: 'Fumble',
          eventTypes: 391
        },
        {
          name: 'Sack',
          nameEn: 'Sack',
          eventTypes: 392
        },
        {
          name: 'Sonstiges',
          nameEn: 'Other',
          eventTypes: 53
        }
      ],
      double: [
        {
          name: 'Touchdown',
          nameEn: 'Touchdown',
          eventTypes: [380, 381],
          body: {
            score: 6
          }
        },
        {
          name: 'Pat',
          nameEn: 'Pat',
          eventTypes: [382, 383],
          body: {
            score: 1
          }
        },
        {
          name: 'Two-Point Conversion',
          nameEn: 'Two-Point Conversion',
          eventTypes: [384, 385],
          body: {
            score: 2
          }
        },
        {
          name: 'Fieldgoal',
          nameEn: 'Fieldgoal',
          eventTypes: [386, 387],
          body: {
            score: 3
          }
        },
        {
          name: 'Safety',
          nameEn: 'Safety',
          eventTypes: [388, 389],
          body: {
            score: 2
          }
        }
      ]
    }
  }
}
