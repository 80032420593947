import React from 'react'
import i18n from 'i18next'
import FilteredSelect from '../../helper/filteredSelect/filteredSelect'
import TeamForm from '../../forms/teamForm/teamForm'
import ClubForm from '../../forms/clubForm/clubForm'
import eyeIcon from '../../../img/icons/eye.svg'
import eventIcon from '../../../img/icons/event.svg'
import scheduleIcon from '../../../img/icons/schedule.svg'
import locatioOnIcon from '../../../img/icons/location_on.svg'
import localActivityIcon from '../../../img/icons/local_activity.svg'

import { ClubData, Competition } from '@soccerwatch/common'
import { TeamData } from '../../../types/TeamData'
import { TeamContainer } from '../../../services/teamContainer'
import { ClubContainer } from '../../../services/clubContainer'
import { CityContainer } from '../../../services/cityContainer'
import { CompetitionContainer } from '../../../services/competitionContainer'

import './TeamsStep.scss'

export type TeamShortInfo = { RowKey: string; name: { valueText?: string } }

type TeamsStepPropTypes = {
  clubs: Record<string, ClubData>
  clubContainer: ClubContainer
  teamContainer: TeamContainer
  cityContainer: CityContainer
  competitionContainer: CompetitionContainer
  onSetClub: (club: ClubData, isHomeClub: boolean) => void
  onSetTeam: (team: TeamData, isHomeTeam: boolean) => void
  onSetCompetition: (competition: Competition) => void
  handleRecordState: (stateName: string, value: string) => void
  clubHome?: ClubData //TeamShortInfo
  clubGuest?: ClubData // TeamShortInfo
  teamHome?: TeamData
  teamGuest?: TeamData
  competition?: Competition
  season: string
  gender?: string
  isNew: boolean
  newEventSettings?: {
    privateActive?: boolean
    date?: string
    time?: string
    address?: string
    paywallActive?: boolean
  }
}

type TeamsStepStateTypes = {
  selects: Array<{
    text: string
    value: string
  }>
  clubHomeThump?: string
  clubGuestThump?: string
  clubFormOpen: boolean
  teamFormOpen: boolean
  clubFormForHome: boolean
  teamFormForHome: boolean
}
export class TeamStep extends React.Component<TeamsStepPropTypes, TeamsStepStateTypes> {
  constructor(props: TeamsStepPropTypes) {
    super(props)

    this.state = {
      selects: [],
      clubHomeThump: undefined,
      clubGuestThump: undefined,
      clubFormOpen: false,
      teamFormOpen: false,
      clubFormForHome: false,
      teamFormForHome: false
    }
  }

  private refHomeTeam: any = React.createRef()
  private refGuestTeam: any = React.createRef()

  componentDidMount() {
    this.setSelectData()

    if (this.props.clubHome) {
      this.getClubLogo(this.props.clubHome, 'clubHomeThump')
    }
    if (this.props.clubGuest) {
      this.getClubLogo(this.props.clubGuest, 'clubGuestThump')
    }
  }

  componentDidUpdate(prevProps: TeamsStepPropTypes) {
    if (this.props.clubs !== prevProps.clubs) {
      this.setSelectData()
    }

    if (this.props.clubHome !== prevProps.clubHome) {
      this.getClubLogo(this.props.clubHome, 'clubHomeThump')
    }

    if (this.props.clubGuest !== prevProps.clubGuest) {
      this.getClubLogo(this.props.clubGuest, 'clubGuestThump')
    }
  }

  setSelectData() {
    const selectData = Object.keys(this.props.clubs).map((clubId) => {
      const club = this.props.clubs[clubId]
      return { text: club.name, value: club.RowKey }
    })

    this.setState({
      selects: selectData
    })
  }

  onSetClub = (isHomeClub: boolean) => (club: ClubData) => {
    this.props.onSetClub(club, isHomeClub)
  }

  onSetTeam = (isHomeTeam: boolean) => (team: TeamData) => {
    this.props.onSetTeam(team, isHomeTeam)
  }

  openTeamForm = (teamFormForHome: boolean) => () => {
    this.setState({ teamFormOpen: true, teamFormForHome })
  }

  closeTeamForm = (team?: TeamData) => {
    if (team) {
      this.onSetTeam(this.state.teamFormForHome)(team)
    }
    this.setState({ teamFormOpen: false })
  }

  openClubForm = (clubFormForHome: boolean) => () => {
    this.setState({ clubFormOpen: true, clubFormForHome })
  }

  closeClubForm = (club?: ClubData) => {
    if (club) {
      this.onSetClub(this.state.clubFormForHome)(club)
    }
    this.setState({ clubFormOpen: false })
  }

  getClubLogo = (club: ClubData | undefined, state: string) => {
    const found = club
    if (state === 'clubGuestThump') {
      this.setState({
        clubGuestThump: found?.thumbnail ? found.thumbnail : undefined
      })
    }
    if (state === 'clubHomeThump') {
      this.setState({
        clubHomeThump: found?.thumbnail ? found.thumbnail : undefined
      })
    }
  }

  handleHomeTeam = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.handleRecordState('teamNameHome', event.target.value)
  }

  handleGuestTeam = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.handleRecordState('teamNameGuest', event.target.value)
  }

  handleDropdownGender = (event: { valueText?: string | undefined }, i: { getVal: () => string }) => {
    this.props.handleRecordState('gender', i.getVal() || '')
  }

  openSelector = (objRef: any) => {
    objRef.openModal()
  }

  render() {
    const teamCompetitions = this.props.teamHome
      ? this.props.competitionContainer.getCompetitionsOfTeam(this.props.teamHome.RowKey) ?? []
      : []
    const teamGuestCompetitions = this.props.teamGuest
      ? this.props.competitionContainer.getCompetitionsOfTeam(this.props.teamGuest.RowKey) ?? []
      : []
    teamGuestCompetitions.forEach((guestComp) => {
      const isDuplicate = teamCompetitions.find((comp) => comp.RowKey === guestComp.RowKey)
      if (!isDuplicate) {
        teamCompetitions.push(guestComp)
      }
    })

    return (
      <div className='main-teamsStep'>
        <TeamForm
          onClose={this.closeTeamForm}
          teamContainer={this.props.teamContainer}
          cityContainer={this.props.cityContainer}
          clubContainer={this.props.clubContainer}
          // competitionContainer={this.props.competitionContainer}
          club={this.state.teamFormForHome ? this.props.clubHome : this.props.clubGuest}
          open={this.state.teamFormOpen}
        />
        <ClubForm
          onClose={this.closeClubForm}
          clubContainer={this.props.clubContainer}
          cityContainer={this.props.cityContainer}
          open={this.state.clubFormOpen}
        />

        <div className='teams-preview'>
          {this.props.isNew && (
            <div className='main-tab'>
              <div className='tab-textBlock'>
                <div className='textBlock-icon'>
                  <img src={eyeIcon} />
                </div>
                <div className='textBlock-title'>
                  {this.props.newEventSettings?.privateActive ? 'Privat' : 'Öffentlich'}
                </div>
              </div>

              <div className='tab-textBlock'>
                <div className='textBlock-icon'>
                  <img src={eventIcon} />
                </div>
                <div className='textBlock-title'>{this.props.newEventSettings?.date}</div>
              </div>

              <div className='tab-textBlock'>
                <div className='textBlock-icon'>
                  <img src={scheduleIcon} />
                </div>
                <div className='textBlock-title'>{this.props.newEventSettings?.time}</div>
              </div>

              <div className='tab-textBlock mobile'>
                <div className='textBlock-icon'>
                  <img src={locatioOnIcon} />
                </div>
                <div className='textBlock-title'>{this.props.newEventSettings?.address}</div>
              </div>

              <div
                className='tab-textBlock mobile'
                style={this.props.newEventSettings?.paywallActive === true ? {} : { display: 'none' }}
              >
                <div className='textBlock-icon'>
                  <img src={localActivityIcon} />
                </div>
                <div className='textBlock-title'>Paywall</div>
              </div>
            </div>
          )}

          <div className='preview-teams'>
            <div className='teams-team'>
              <div className='team-logo'>
                {this.state.clubHomeThump ? <img src={this.state.clubHomeThump} /> : null}
              </div>
              <div className='team-text'>{this.props.clubHome?.name || 'Heim'}</div>
            </div>
            <div className='team-vs'>vs</div>
            <div className='teams-team'>
              <div className='team-logo'>
                {this.state.clubGuestThump ? <img src={this.state.clubGuestThump} /> : null}
              </div>
              <div className='team-text'>{this.props.clubGuest?.name || 'Gast'}</div>
            </div>
          </div>
        </div>

        <div className='teams-input'>
          <div className='input-team'>
            <FilteredSelect<ClubData>
              urlParameters={`&kind=skyhook-beta-cluball&fuzzy=true&match=name`}
              label={i18n.t('recordingPlan.teams.homeSelectTitle')}
              onChange={this.onSetClub(true)}
              onNewEntryNeeded={this.openClubForm(true)}
              value={this.props.clubHome}
              newItemLabel={i18n.t('forms.club.newClub')}
              defaultSelection={this.props.clubContainer.getClubListForCurrentContract()}
              ref={(instance) => {
                this.refHomeTeam = instance
              }}
              placeholder={i18n.t('recordingPlan.teams.placeholder.clubPlaceholder')}
            />

            {this.props.clubHome ? (
              <FilteredSelect<TeamData>
                defaultSelection={
                  this.props.clubHome?.RowKey
                    ? this.props.teamContainer.getTeamsOfClubSync(this.props.clubHome.RowKey)
                    : []
                }
                urlParameters={`&kind=skyhook-beta-teamall&fuzzy=true&match=name`}
                label={i18n.t('recordingPlan.teams.lableHomeclub')}
                placeholder={i18n.t('recordingPlan.teams.teamPlaceholder')}
                labelSuffixProp='age'
                id='ageClubHome'
                labelSuffixDelimiter=': '
                disableSearch
                newItemLabel={i18n.t('recordingPlan.teams.newTeam')}
                onChange={this.onSetTeam(true)}
                onNewEntryNeeded={this.openTeamForm(true)}
                value={this.props.teamHome}
              />
            ) : (
              <FilteredSelect
                disabled
                placeholder={i18n.t('recordingPlan.teams.clubPlaceholder')}
                label={i18n.t('recordingPlan.teams.placeholder.teamPlaceholder')}
                onChange={() => {}}
                onNewEntryNeeded={() => {}}
              />
            )}
          </div>
          <div className='input-team'>
            <FilteredSelect<ClubData>
              urlParameters={`&kind=skyhook-beta-cluball&fuzzy=true&match=name`}
              label={i18n.t('recordingPlan.teams.guestSelectTitle')}
              onChange={this.onSetClub(false)}
              onNewEntryNeeded={this.openClubForm(false)}
              newItemLabel={i18n.t('forms.club.newClub')}
              value={this.props.clubGuest}
              defaultSelection={this.props.clubContainer.getClubListForCurrentContract()}
              ref={(instance) => {
                this.refGuestTeam = instance
              }}
              placeholder={i18n.t('recordingPlan.teams.clubPlaceholder')}
            />

            {this.props.clubGuest ? (
              <FilteredSelect<TeamData>
                defaultSelection={
                  this.props.clubGuest?.RowKey
                    ? this.props.teamContainer.getTeamsOfClubSync(this.props.clubGuest.RowKey)
                    : []
                }
                urlParameters={`&kind=skyhook-beta-teamall&fuzzy=true&match=name`}
                // label={i18n.t('recordingPlan.teams.placeholder.select')}
                label={i18n.t('recordingPlan.teams.lableGuestclub')}
                placeholder={i18n.t('recordingPlan.teams.teamPlaceholder')}
                labelSuffixProp='age'
                id='ageClubGuest'
                labelSuffixDelimiter=': '
                disableSearch
                newItemLabel={i18n.t('recordingPlan.teams.newTeam')}
                onChange={this.onSetTeam(false)}
                onNewEntryNeeded={this.openTeamForm(false)}
                value={this.props.teamGuest}
              />
            ) : (
              <FilteredSelect
                disabled
                placeholder={i18n.t('recordingPlan.teams.teamPlaceholder')}
                label={i18n.t('recordingPlan.teams.placeholder.selectFirstClub')}
                onChange={() => {}}
                onNewEntryNeeded={() => {}}
              />
            )}
          </div>
        </div>

        {/*!!teamCompetitions.length && (
          <div className='teams-competition'>
            <FilteredSelect<Competition>
              urlParameters={`&kind=skyhook-beta-Competition&fuzzy=true&match=name`}
              onChange={this.props.onSetCompetition}
              label={i18n.t('recordingPlan.teams.competitionLabel')}
              subLabel={`${i18n.t('recordingPlan.teams.season')} ${this.props.season}`}
              disableSearch
              disableEntryCreation
              onNewEntryNeeded={() => {}}
              filterResultsFunction={(results: Competition[]) => {
                const season = this.props.season.replace('/', '')
                return results.filter((comp) => {
                  return comp.season === season
                })
              }}
              defaultSelection={teamCompetitions ?? undefined}
              value={this.props.competition}
              labelProp='name'
              valueProp='RowKey'
              allowNoneChoice
            />
          </div>
        )*/}
      </div>
    )
  }
}
