import React, { RefObject, createRef } from 'react'
import logoutIcon from '../img/icons/logout.svg'
import mobiscroll from '@mobiscroll/react'
import i18n from '../languages/i18n'
import staigeLogo from '../img/logo/staige-logo.svg'
import staigeLogoText from '../img/logo/staige-logo-text.svg'
import iconDots from '../img/icons/menu/dots.svg'
import iconHelp from '../img/icons/menu/help.svg'
import de from '../img/flags/de.svg'
import en from '../img/flags/us.svg'

import { UserContainer } from '../services/userContainer'
import contractContainer, { ContractContainer } from '../services/contractContainer'
import { Link } from 'react-router-dom'
import { checkLanguages } from '../pages/helper/checkLanguages/checkLanguages'

import './Topbar.scss'

type TopBarPropTypes = {
  userContainer: UserContainer
  contractContainer: ContractContainer
  siteTitle?: string
  linkTo?: string
  quickButton?: any
  onClick?: any
  scrollHeight: number
  simpleTopbar?: boolean
}

type TopBarStateTypes = {
  userInfo: {
    name: string
    shortName: string
    role: string
  }
  openLogoutMenu: boolean
  openOtherToolsMenu: boolean
  activeLangOverlay: boolean
}

export default class TopBar extends React.Component<TopBarPropTypes, TopBarStateTypes> {
  private refLogout: RefObject<HTMLDivElement>
  private refStaigeApps: RefObject<HTMLDivElement>
  private refLogin = React.createRef<HTMLDivElement>()

  constructor(props: TopBarPropTypes) {
    super(props)

    this.state = {
      userInfo: {
        name: 'name',
        shortName: '',
        role: ''
      },
      openLogoutMenu: false,
      openOtherToolsMenu: false,
      activeLangOverlay: false
    }

    this.refLogout = React.createRef()
    this.refStaigeApps = React.createRef()
  }

  componentDidMount(): void {
    this.loadUserdata()
    document.addEventListener('mousedown', this.handleClickOutside)
    document.addEventListener('mousedown', this.handleClickOutsideStaigeApps)
  }

  componentDidUpdate(
    prevProps: Readonly<TopBarPropTypes>,
    prevState: Readonly<TopBarStateTypes>,
    snapshot?: any
  ): void {
    if (
      this.props.contractContainer.getHighestRelevantRoleForCurrentContract() !== this.state.userInfo.role
    ) {
      this.loadUserdata()
    }
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickOutside)
    document.removeEventListener('mousedown', this.handleClickOutsideStaigeApps)
  }

  loadUserdata = () => {
    const { userContainer, contractContainer } = this.props
    const userName = userContainer.state.user?.name
    const userRole = contractContainer.getHighestRelevantRoleForCurrentContract()
    const shortName = this.shortNameExtration(userName || 'name')

    this.setState({
      userInfo: {
        name: userName || '',
        shortName: shortName,
        role: userRole
      }
    })
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this.refLogout.current && !this.refLogout.current.contains(event.target as Node)) {
      this.setState({ openLogoutMenu: false, activeLangOverlay: false })
    }
  }

  handleClickOutsideStaigeApps = (event: MouseEvent) => {
    if (this.refStaigeApps.current && !this.refStaigeApps.current.contains(event.target as Node)) {
      this.setState({ openOtherToolsMenu: false })
    }
  }

  shortNameExtration(name: string) {
    name = name.trim()
    const words = name.split(' ')
    let shortName = ''
    for (let i = 0; i < words.length; i++) {
      const firstCharFromName = words[i][0].toUpperCase()
      shortName += firstCharFromName
      if (i >= 1) {
        i = words.length
      }
    }
    return shortName
  }

  handleOtherToolsMenu = () => {
    this.setState({
      openOtherToolsMenu: !this.state.openOtherToolsMenu
    })
  }

  handleLogoutMenu = () => {
    this.setState({
      openLogoutMenu: !this.state.openLogoutMenu
    })
  }

  handleLogout = async () => {
    await this.props.userContainer.handleLogout()
    mobiscroll.toast({ message: i18n.t('login.toast.logoutSuccessful') })
  }

  checkLanguage(lang: string) {
    let findMore = lang.search('-')
    if (findMore !== -1) {
      const split = lang.split('-')
      return split[0]
    }
    return lang
  }

  handleLangOverlay = () => {
    this.setState({
      activeLangOverlay: !this.state.activeLangOverlay
    })
  }

  renderLangItem = (title: string, icon: string, onClick: () => void) => {
    return (
      <div className='logout' onClick={onClick}>
        <div className='lang-icon'>
          <img src={icon} />
        </div>
        <div className='lang-text'>{title}</div>
      </div>
    )
  }

  changeLanguage = (language: string) => {
    i18n.changeLanguage(language)
    window.location.reload()
  }

  render() {
    const { scrollHeight, simpleTopbar } = this.props
    const mobileScroll = scrollHeight > 0 ? -Math.abs(scrollHeight) : 0
    const topBarMargin = mobileScroll <= -60 ? -60 : mobileScroll

    const lang = checkLanguages()
    const langIcon = lang === 'de' ? de : en
    const langText = lang === 'de' ? 'Deutsch' : 'English'

    if (simpleTopbar) {
      return (
        <div className='comp-simpleTopbar'>
          <div className='simpleTopbar-left'>
            <div className='left-btn'>
              {!this.props.linkTo || (
                <Link to={this.props.linkTo} style={{ textDecoration: 'none' }}>
                  <div className='smallIcon-button'>
                    <span className='mbsc-ic mbsc-ic-arrow-left2' />
                  </div>
                </Link>
              )}

              {!this.props.onClick || (
                <div className='smallIcon-button' onClick={this.props.onClick}>
                  <span className='mbsc-ic mbsc-ic-arrow-left2' />
                </div>
              )}
            </div>
            <div className='left-title'>{this.props.siteTitle}</div>
          </div>
        </div>
      )
    }

    return (
      <div className='comp-topbar' style={{ marginTop: scrollHeight > 0 ? topBarMargin : 0 }}>
        <div style={{ display: 'none' }}>
          <aisw-user logo={false} ref={this.refLogin} language={this.checkLanguage(i18n.language)} />
        </div>
        <div className='topbar'>
          <div className='topbar-left'>
            <div className='sidebar-logo'>
              <img src={staigeLogo} />
              <img src={staigeLogoText} />
            </div>
            {!this.props.linkTo || (
              <Link to={this.props.linkTo} style={{ textDecoration: 'none' }}>
                <div className='smallIcon-button' style={{ borderRadius: '100%' }}>
                  <span className='mbsc-ic mbsc-ic-arrow-left2' />
                </div>
              </Link>
            )}

            {!this.props.onClick || (
              <div className='smallIcon-button' onClick={this.props.onClick} style={{ borderRadius: '100%' }}>
                <span className='mbsc-ic mbsc-ic-arrow-left2' />
              </div>
            )}

            <div className='left-title'>{this.props.siteTitle || 'title missing'}</div>
          </div>

          <div className='topbar-right'>
            <div className='btn-wrapper'>
              <div onClick={this.handleOtherToolsMenu}>
                <button disabled={false} className={`item-wrap ${false ? 'itme-wrap-active' : null}`}>
                  <img src={iconDots} className={`imgActive`} />
                </button>
              </div>

              <a
                style={{ cursor: 'unset' }}
                href='https://staige.com/faq/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <button disabled={false} className={`item-wrap ${false ? 'itme-wrap-active' : null}`}>
                  <img src={iconHelp} className={`imgActive`} />
                </button>
              </a>
            </div>

            <div className='right-userInfo' onClick={this.handleLogoutMenu}>
              <div className='userInfo-name'>{this.state.userInfo.name}</div>
              <div className='userInfo-Role'>
                {i18n.t('administration.userManagement.userRoleTranslate.' + this.state.userInfo.role)}
              </div>
            </div>
            <div className='right-user' onClick={this.handleLogoutMenu}>
              <div className='user-shortName'>{this.state.userInfo.shortName}</div>
            </div>
          </div>

          {!this.state.openOtherToolsMenu || (
            <div className='container-logout' ref={this.refStaigeApps}>
              <a
                className='logout'
                style={{ cursor: 'unset', textDecoration: 'none' }}
                href='https://staige.coach'
                target='_blank'
                rel='noopener noreferrer'
              >
                <div className='lang-text'>Staige Coach</div>
              </a>
            </div>
          )}

          {!this.state.openLogoutMenu || (
            <div className='container-logout' ref={this.refLogout}>
              {this.state.activeLangOverlay ? (
                <>
                  {this.renderLangItem('Deutsch', de, () => this.changeLanguage('de'))}
                  {this.renderLangItem('English', en, () => this.changeLanguage('en'))}
                </>
              ) : (
                <>
                  <div className='logout' onClick={this.handleLangOverlay}>
                    <div className='lang-icon'>
                      <img src={langIcon} />
                    </div>
                    <div className='lang-text'>{langText}</div>
                    <div className='lang-action'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='20'
                        viewBox='0 0 20 20'
                        fill='none'
                      >
                        <mask
                          id='mask0_1703_5038'
                          maskUnits='userSpaceOnUse'
                          x='0'
                          y='0'
                          width='20'
                          height='20'
                        >
                          <rect width='20' height='20' fill='#D9D9D9' />
                        </mask>
                        <g mask='url(#mask0_1703_5038)'>
                          <path
                            d='M7.83341 15L6.66675 13.8333L10.5001 10L6.66675 6.16667L7.83341 5L12.8334 10L7.83341 15Z'
                            fill='#F6F6F6'
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div className='logout' onClick={this.handleLogout}>
                    <div className='logout-icon'>
                      <img src={logoutIcon} />
                    </div>
                    <div className='logout-text'>{i18n.t('login.button.btnLogout')}</div>
                  </div>
                </>
              )}
            </div>
          )}

          <div className='topbar-mobileSecond'>
            <div className='left-title'>
              {!this.props.linkTo || (
                <Link to={this.props.linkTo} style={{ textDecoration: 'none' }}>
                  <div className='smallIcon-button' style={{ borderRadius: '100%' }}>
                    <span className='mbsc-ic mbsc-ic-arrow-left2' />
                  </div>
                </Link>
              )}

              {!this.props.onClick || (
                <div
                  className='smallIcon-button'
                  onClick={this.props.onClick}
                  style={{ borderRadius: '100%' }}
                >
                  <span className='mbsc-ic mbsc-ic-arrow-left2' />
                </div>
              )}
              {this.props.siteTitle || 'title missing'}
            </div>
            <div className='right-quickquickButtons'>
              {this.props.quickButton ? this.props.quickButton : null}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
