import React from 'react'
import HeaderWrapper from '../../helper/PageWrapper'
import iconPlus from '../../img/icons/plus.svg'
import AiswSpinner from '../helper/aiswSpinner/aiswSpinner'
import Button from '../../components/styles/Button'
import i18n from '../../languages/i18n'

import { ContractContainer } from '../../services/contractContainer'
import { UserContainer } from '../../services/userContainer'
import { Calendar } from './Container/calender'
import { CameraData, Role } from '@soccerwatch/common'
import { ClubData } from '@soccerwatch/common'
import { VideoData } from '@soccerwatch/common'
import { CameraContainer } from '../../services/cameraContainer'
import { VideoContainer } from '../../services/videoContainerContracts'
import { ClubContainer } from '../../services/clubContainer'
import { useLocation, useRouteMatch, withRouter } from 'react-router-dom'
import { useHistory } from 'react-router'
import { TeamContainer } from '../../services/teamContainer'

import './RecordingPlan.scss'

type RecordingPlanStateTypes = {
  cameras: Array<CameraData>
  videos: Array<VideoData>
  clubs: Array<ClubData>
  loadingData: boolean
  openOverlay: boolean
  editId: number | undefined
  quickButton?: any
}

type RecordingPlanProps = {
  userContainer: UserContainer
  cameraContainer: CameraContainer
  videoContainer: VideoContainer
  contractContainer: ContractContainer
  clubContainer: ClubContainer
  teamContainer: TeamContainer
  history: ReturnType<typeof useHistory>
  location: ReturnType<typeof useLocation>
  match: NonNullable<ReturnType<typeof useRouteMatch>>
}

class RecordingPlan extends React.Component<RecordingPlanProps, RecordingPlanStateTypes> {
  constructor(props: RecordingPlanProps) {
    super(props)

    this.state = {
      cameras: [],
      videos: [],
      clubs: [],
      loadingData: false,
      openOverlay: false,
      editId: undefined,
      quickButton: undefined
    }

    this.handleCreateVideo = this.handleCreateVideo.bind(this)
  }

  handleCreateVideo() {
    this.props.history.push('recordingplan/new')
  }

  onEventSelect = (id?: number) => {
    if (id !== undefined) {
      this.props.history.push(`edit/${id}`)
    } else {
      this.handleCreateVideo()
    }
  }

  setQuickButton = (button: any) => {
    this.setState({
      quickButton: button
    })
  }

  render() {
    const { cameraContainer, videoContainer, contractContainer } = this.props
    const isClubTagger = contractContainer.getHighestRelevantRoleForCurrentContract() === Role.clubTagger
    const isAdManager = contractContainer.getHighestRelevantRoleForCurrentContract() === Role.adManager

    if (
      !cameraContainer.state.loadingData &&
      !contractContainer.state.loadingData &&
      Object.keys(cameraContainer.state.cameras).length <= 0 &&
      !isClubTagger &&
      !isAdManager
    ) {
      this.props.history.replace(`/claim/camera`)
      return null
    }

    if (cameraContainer.state.loadingData || !videoContainer.state.initialDataLoaded) {
      return (
        <HeaderWrapper
          headerTitle={i18n.t('calendar.pageTitle')}
          linkTo='/recordingplan'
          style={{ height: '100%' }}
        >
          <div style={{ height: '100%' }}>
            <AiswSpinner />
          </div>
        </HeaderWrapper>
      )
    }

    return (
      <HeaderWrapper
        headerTitle={i18n.t('calendar.pageTitle')}
        quickButton={<Button icon={iconPlus} onClick={this.onEventSelect} round />}
        style={{ height: '100%' }}
      >
        <div className='page-recordingPlan'>
          <Calendar
            videos={videoContainer.getVideosForCurrentContract()}
            videoContainer={videoContainer}
            onEventSelect={this.onEventSelect}
            isClubTagger={isClubTagger}
            teamContainer={this.props.teamContainer}
            contractBar={contractContainer.state.contracts.length > 1}
            contractContainer={contractContainer}
          />
        </div>
      </HeaderWrapper>
    )
  }
}

export default withRouter<RecordingPlanProps, typeof RecordingPlan>(RecordingPlan)
