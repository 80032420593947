import Discovery, { assertContainerHasInitializedDiscovery } from './discovery'
import Cookies from 'universal-cookie'
import CameraContainer from './cameraContainer'
import ClubContainer from './clubContainer'
import TeamContainer from './teamContainer'
import CompetitionContainer from './competitionContainer'
import ContractContainer from './contractContainer'
import VideoContainer from './videoContainerContracts'
import AdvertisementContainer from './advertisementContainer'
import DiscoveryType from '@soccerwatch/discovery'

import firebaseInit from './firebase'
import firebase from 'firebase/app'
import 'firebase/auth'

import jwt_decode from 'jwt-decode'
import i18n from 'i18next'

import { Container } from 'unstated-typescript'
import { DecodedAiswToken, License, LicenseType, Role } from '@soccerwatch/common'
import { getTokenMe, getUserLicences } from '../api/api-user'
import { getHighestRelevantRoles } from '../pages/menu/Menu'

const cookies = new Cookies()

export type UserLoginState = {
  email: string
  loggedIn: boolean
  tryLogin: boolean
  discovery?: typeof DiscoveryType
  user?: DecodedAiswToken
  firebaseUser?: firebase.User
  licences: License[]
  hasAdminRights: boolean
  userLicenseTypes: LicenseType[]
  loadingData: boolean
  loginObserver?: () => void
  routeFilterObserver?: (value: Array<string>) => void
}

export class UserContainer extends Container<UserLoginState> {
  discovery?: typeof DiscoveryType
  private googleAuthProvider = new firebase.auth.GoogleAuthProvider()
  private facebookAuthProvider = new firebase.auth.FacebookAuthProvider()
  private twitterAuthProvider = new firebase.auth.TwitterAuthProvider()
  private appleAuthProvider = new firebase.auth.OAuthProvider('apple.com')
  private microsoftAuthProvider = new firebase.auth.OAuthProvider('microsoft.com')

  constructor() {
    super()

    this.state = {
      email: cookies.get('email'),
      loggedIn: false,
      tryLogin: false,
      discovery: undefined,
      user: undefined,
      firebaseUser: undefined,
      licences: [],
      hasAdminRights: false,
      userLicenseTypes: [],
      loadingData: false,
      loginObserver: undefined,
      routeFilterObserver: undefined
    }

    this.appleAuthProvider.addScope('email')
    this.appleAuthProvider.addScope('name')
    this.appleAuthProvider.setCustomParameters({
      locale: i18n.language
    })

    this.microsoftAuthProvider.addScope('mail.read')
    this.microsoftAuthProvider.setCustomParameters({
      tenant: process.env.REACT_APP_MICROSOFT_TENANT
    })

    //initialize firebase
    firebaseInit()

    Discovery.then((d) => {
      this.setState({ discovery: d })
      this.initialize()
    })
  }

  loginObserver = (loginObserver: () => void) => {
    this.setState({
      loginObserver
    })
  }

  routeFilterObserver = (routeFilterObserver: (value: Array<string>) => void) => {
    this.setState({
      routeFilterObserver
    })
  }

  async initialize() {
    firebase.auth().onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        if (this.state.loginObserver) {
          this.state.loginObserver()
        }

        await firebaseUser.getIdToken(true)
        const user = await this.getDecodedUserData(firebaseUser)
        this.setState({ user, firebaseUser: firebaseUser })
        await this.fetchUserLicences()
        await ContractContainer.initialize(
          VideoContainer,
          AdvertisementContainer,
          CameraContainer,
          this,
          ClubContainer,
          TeamContainer,
          CompetitionContainer
        )
        // * These are now initialized by ContractContainer
        // VideoContainer.initialize(ContractContainer)
        // AdvertisementContainer.initialize(ContractContainer)
        // CameraContainer.initialize(ContractContainer, this, videoContainer)
        // ClubContainer.initialize(ContractContainer, TeamContainer, CompetitionContainer)
        if (this.state.routeFilterObserver) {
          this.state.routeFilterObserver(getHighestRelevantRoles())
        }

        this.setState({ loggedIn: true })
      } else {
        this.setState({
          tryLogin: true
        })
      }
    })
  }

  getDecodedUserData = async (_user?: firebase.User) => {
    const user = _user ?? this.state.firebaseUser

    if (!user) {
      console.error('Can not Upadet User. Firebase not Initialized. This should not happen!')
      return
    }
    const userToken: string | undefined = await user.getIdToken()
    const userObject: DecodedAiswToken = jwt_decode(userToken)
    return userObject
  }

  async fetchUserLicences() {
    assertContainerHasInitializedDiscovery(this)

    this.setState({ loadingData: true })
    const licences = await getUserLicences()
    // const licences = await Helper.apiGet<License[]>(url)
    if (!licences) {
      console.error('<UserContainer> Could not load Licences')
      this.setState({ loadingData: false, licences: [] })
      return
    }

    const userLicenseTypes = this.reduceToLicenseType(licences)

    const hasAdminRights = userLicenseTypes.includes(LicenseType.ContractOwnerLicense)
    this.setState({ licences, hasAdminRights, userLicenseTypes, loadingData: false })
  }

  reduceToLicenseType = (licences: License[]) => {
    return licences.reduce((types, license) => {
      if (!types.includes(license.type)) {
        types.push(license.type)
      }
      return types
    }, [] as LicenseType[])
  }

  getLicencesOfContract = (contractId: string) => {
    return this.state.licences.filter((license) => license.contractId === contractId)
  }

  getHighestRelevantRoleOfContract = (contractId: string) => {
    const licences = this.getLicencesOfContract(contractId)
    const types = this.reduceToLicenseType(licences)
    return this.getHighestRelevantRole(types)
  }

  getHighestRelevantRolesOfContract = (contractId: string) => {
    const licences = this.getLicencesOfContract(contractId)
    const types = this.reduceToLicenseType(licences)
    return this.getHighestRelevantRoles(types)
  }

  getHighestRelevantRoleOfCurrentUser = () => {
    const types = this.state.userLicenseTypes
    return this.getHighestRelevantRole(types)
  }

  getHighestRelevantRole = (types: LicenseType[]) => {
    if (types.includes(LicenseType.ContractOwnerLicense)) {
      return Role.contractOwner
    } else if (types.includes(LicenseType.CameraLicense)) {
      return Role.cameraController
    } else if (types.includes(LicenseType.ClubTaggerLicense)) {
      return Role.clubTagger
    } else if (types.includes(LicenseType.AdManagerAccess)) {
      return Role.adManager
    } else if (types.includes(LicenseType.TrainerSubscription)) {
      return Role.trainer
    }
    return Role.user
  }

  getHighestRelevantRoles = (types: LicenseType[]) => {
    if (types.includes(LicenseType.ContractOwnerLicense)) {
      return [Role.contractOwner]
    } else if (types.includes(LicenseType.CameraLicense)) {
      return [Role.cameraController]
    } else if (types.includes(LicenseType.ClubTaggerLicense) && types.includes(LicenseType.AdManagerAccess)) {
      return [Role.clubTagger, Role.adManager]
    } else if (types.includes(LicenseType.ClubTaggerLicense)) {
      return [Role.clubTagger]
    } else if (types.includes(LicenseType.AdManagerAccess)) {
      return [Role.adManager]
    } else if (types.includes(LicenseType.TrainerSubscription)) {
      return [Role.trainer]
    }
    return [Role.user]
  }

  getUserRoles = () => {
    return this.state.user?.role ?? []
  }

  /*getRefreshUserToken = async () => {
    await firebase.auth().currentUser?.getIdToken(true)
    const user = await this.getDecodedUserData()
    if (!user) {
      console.error('WAOSPJFÜPOWEKJÜOA')
      return
    }
    this.setState({ user, email: user.email })
  }*/

  signInWithToken = async (token: string) => {
    let userToken = ''
    await getTokenMe(token).then((res) => {
      userToken = res.data
    })
    await firebase.auth().signInWithCustomToken(userToken)
  }

  handleLogout = async () => {
    await firebase.auth().signOut()
    window.location.reload()
    window.localStorage.removeItem('ContractIndex')
    this.setState({ loggedIn: false, user: undefined, email: undefined })
  }
}

export const userContainer = new UserContainer()
export default userContainer
