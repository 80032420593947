import React from 'react'
import iconUpLine from '../img/icons/upLine.svg'
import StaigeButton from './styles/Button'
import i18n from '../languages/i18n'

import { ContractContainer } from '../services/contractContainer'
import { VideoContainer } from '../services/videoContainerContracts'
import { Contract, VideoData, MonthReport, getTimeReport } from '@soccerwatch/common'
import { DateType } from '@mobiscroll/react5/dist/src/core/util/datetime'

import './TimeContainer.scss'

type TimeContainerPropTypes = {
  contractContainer: ContractContainer
  videoContainer: VideoContainer
  selectedDate: DateType
}

type TimeContainerStateTypes = {
  monthReport: MonthReport | undefined
}

export default class TimeContainer extends React.Component<TimeContainerPropTypes, TimeContainerStateTypes> {
  constructor(props: TimeContainerPropTypes) {
    super(props)
    this.state = {
      monthReport: undefined
    }
  }

  componentDidMount(): void {
    this.getTimeReport()
  }

  componentDidUpdate(
    prevProps: Readonly<TimeContainerPropTypes>,
    prevState: Readonly<TimeContainerStateTypes>,
    snapshot?: any
  ): void {
    const { loadingData } = this.props.videoContainer.state
    if (!loadingData) {
      this.getTimeReport()
    }
    //const check = this.checkTimechange(new Date(this.props.selectedDate.toString()), new Date(prevProps.selectedDate.toString()))
  }

  checkTimechange = (selectedDate: Date, prevDate: Date) => {
    if (
      selectedDate.getFullYear() !== prevDate.getFullYear() ||
      selectedDate.getMonth() !== prevDate.getMonth()
    ) {
      return true
    }
    return false
  }

  getTimeReport = async () => {
    const { contracts, activeContract } = this.props.contractContainer.state
    const contract = contracts[activeContract]
    const videoDataCopy = this.getContractVideos(contract)
    const timeReport = getTimeReport(
      videoDataCopy,
      contract.contingentAbos,
      new Date(this.props.selectedDate.toString())
    )
    const now = new Date(this.props.selectedDate.toString())

    let currentYear,
      currentMonth = undefined
    currentYear = timeReport.mapsByYear[now.getFullYear()]
    if (currentYear) {
      currentMonth = currentYear.mapsByMonth[now.getMonth()] as MonthReport
      if (JSON.stringify(this.state.monthReport) !== JSON.stringify(currentMonth))
        this.setState({ monthReport: currentMonth })
    }
  }

  getContractVideos = (contract: Contract) => {
    const contractVideos = (this.props.videoContainer.state.videos[contract.RowKey] as VideoData[]).filter(
      (game) => {
        return (
          game.error === undefined &&
          game.gpuerror === undefined &&
          (game.state !== 'done' || (game.state === 'done' && game.userStream)) &&
          game.contractId == contract.RowKey
        )
      }
    )

    return contractVideos
  }

  render() {
    const { monthReport } = this.state
    if (monthReport === undefined || monthReport.singleUseContingent.timeAvailable === 0) return null
    const timeUsed = monthReport.singleUseContingent.timeUsed
    const timeAvailable = monthReport.singleUseContingent.timeAvailable
    // const restTime = timeAvailable - timeUsed
    const progressWitdh = (timeUsed * 100) / timeAvailable
    // const progressBarMinus = 100 - progressWitdh
    const warningActiv = progressWitdh > (100 / 4) * 3

    return (
      <div className={`comp-timeContainer ${warningActiv ? 'comp-timeContainer-warn' : ''}`}>
        <div className='timeContainer-head '>
          <div className='head-text'>Monatliche Aufnahme-Minuten</div>
          {warningActiv ? (
            <StaigeButton
              style='ghost'
              label={i18n.t('administration.contractStramingAbo.aboBox.upgradeBtn')}
              icon={iconUpLine}
              iconMargin='0 0 0 -7px'
              size='small'
              linkTo='/administration/subscriptionmanagement'
            />
          ) : null}
        </div>
        <div className='timeContainer-progress '>
          <div className='progress-bar'>
            <div className='bar-progress' style={{ width: progressWitdh + '%' }} />
          </div>
        </div>
        <div className='timeContainer-time '>
          <div className='time-a'>{timeUsed}</div>
          <div className='time-b'>/{timeAvailable} min</div>
        </div>
      </div>
    )
  }
}
