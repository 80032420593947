import React, { Ref, useState } from 'react'
import i18n from 'i18next'
import mobiscroll from '@mobiscroll/react'
import StaigeButton from '../../../components/styles/Button'
import StaigeSwitch from '../../../components/styles/Switch'

import './embedCode.scss'

interface EmbedCodeProps {
  id: string
  active: boolean
}

export const EmbedCode: React.FC<EmbedCodeProps> = (props: EmbedCodeProps) => {
  const [embedCode, setEmbedCode] = useState<string>(
    `<div class="sw-embed" style="width:100%"><iframe class="sw-iframe" src="https://staige.tv/embed/video/${props.id}/highlight" style="width:100%;height:100%" frameborder="0" allowfullscreen></iframe></div>	<script>window.swResize = function(){var mapElements = document.getElementsByClassName("sw-embed");for (let i = 0; i < mapElements.length; i++) {const mapElement = mapElements[i];mapElement.style.height = "" + ((mapElement.scrollWidth / 16 * 9)) + "px"}};window.swResize();window.addEventListener("resize", function (e) {window.swResize();});</script>`
  )
  const [embedCodeSidebar, setEmbedCodeSidebar] = useState<boolean>(false)

  const handleChangeEmbedCodeSidebar = (value: boolean) => {
    let newCode
    if (value) {
      newCode = `<div class="sw-embed" style="width:100%"><iframe class="sw-iframe" src="https://staige.tv/embed/video/${props.id}/highlight" style="width:100%;height:100%" frameborder="0" allowfullscreen></iframe></div>	<script>window.swResize = function(){var mapElements = document.getElementsByClassName("sw-embed");for (let i = 0; i < mapElements.length; i++) {const mapElement = mapElements[i];mapElement.style.height = "" + ((mapElement.scrollWidth / 20 * 9)) + "px"}};window.swResize();window.addEventListener("resize", function (e) {window.swResize();});</script>`
    } else {
      newCode = `<div class="sw-embed" style="width:100%"><iframe class="sw-iframe" src="https://staige.tv/embed/video/${props.id}/highlight" style="width:100%;height:100%" frameborder="0" allowfullscreen></iframe></div>	<script>window.swResize = function(){var mapElements = document.getElementsByClassName("sw-embed");for (let i = 0; i < mapElements.length; i++) {const mapElement = mapElements[i];mapElement.style.height = "" + ((mapElement.scrollWidth / 16 * 9)) + "px"}};window.swResize();window.addEventListener("resize", function (e) {window.swResize();});</script>`
    }
    setEmbedCode(newCode)
    setEmbedCodeSidebar(value)
  }

  if (!props.active) {
    return null
  }

  return (
    <div className='embedCode-container'>
      <div className='embedCode-title'>{i18n.t('recording.embedCode.title')}</div>
      <textarea value={embedCode} rows={6} />
      <div className='option-copy'>
        <StaigeSwitch
          size='medium'
          label={i18n.t('recording.embedCode.diebarOption')}
          checked={embedCodeSidebar}
          onClick={() => handleChangeEmbedCodeSidebar(!embedCodeSidebar)}
        />
        <StaigeButton
          style='secondary'
          label={i18n.t('recording.embedCopy')}
          onClick={() => {
            navigator.clipboard.writeText(embedCode)
            mobiscroll.toast({
              message: i18n.t('recording.embedCopyToast')
            })
          }}
        />
      </div>
    </div>
  )
}
