import i18n from 'i18next'

export function checkLanguages() {
  let lang = i18n.language

  if (lang.search('-') !== -1) {
    lang = lang.split('-')[0]
  }

  if (lang !== 'de' && lang !== 'en') {
    // set fall back Lng
    lang = 'en'
  }

  return lang
}

// types: 'date' / 'time'
export function getLanguesFormat(
  type: 'date' | 'time'
): 'MM/DD/YYYY' | 'DD.MM.YYYY' | 'hh:mm A' | 'HH:mm' | undefined {
  if (type === 'date') {
    if (checkLanguages() === 'en') {
      return 'MM/DD/YYYY'
    } else {
      return 'DD.MM.YYYY'
    }
  } else if (type === 'time') {
    if (checkLanguages() === 'en') {
      return 'hh:mm A'
    } else {
      return 'HH:mm'
    }
  }
}
