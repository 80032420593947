import React from 'react'

import './Input.scss'

type staigeInputPropTypes = {
  icon?: string
  onChange?: (event: React.FormEventHandler<HTMLFormElement> | any) => void
  disabled?: boolean
  placeholder?: string
  helperText?: string
  label?: string
  valid?: boolean
  errorMessage?: string
  textarea?: boolean
}

type staigeInputStateTypes = {
  nothing?: string
}

export default class StaigeInput extends React.Component<staigeInputPropTypes, staigeInputStateTypes> {
  constructor(props: staigeInputPropTypes) {
    super(props)
    // this.state = {}
  }

  render() {
    const { icon, onChange, placeholder, helperText, label, valid, errorMessage, textarea } = this.props

    return (
      <div className='staige-input'>
        {label ? <div className='input-lable'>{label}</div> : null}

        <form
          className='input-form'
          onChange={onChange}
          onSubmit={(e) => {
            e.preventDefault()
          }}
          style={textarea ? { height: '100% ' } : {}}
        >
          <div className='input-icons'>
            {icon ? (
              <i className='icon'>
                <img src={icon} />
              </i>
            ) : null}

            {!textarea ? (
              <input
                className='inputField'
                type='text'
                placeholder={placeholder}
                style={icon ? {} : { paddingLeft: 12 }}
              />
            ) : (
              <textarea
                className='inputField'
                placeholder={placeholder}
                style={icon ? {} : { paddingLeft: 12 }}
                rows={5}
              />
            )}
          </div>
        </form>
        {helperText && valid ? <div className='input-helperText'>{helperText}</div> : null}
        {errorMessage && !valid ? (
          <div className='input-helperText input-helperTextError'>{errorMessage}</div>
        ) : null}
      </div>
    )
  }
}
