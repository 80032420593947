import React from 'react'
import StaigeButton from '../../../../components/styles/Button'
import StaigeCheckbox from '../../../../components/styles/Checkbox'
import StaigeInput from '../../../../components/styles/Input'
import PageWrapper from '../../../../helper/PageWrapper'
import i18n from '../../../../languages/i18n'

import { UserContainer } from '../../../../services/userContainer'

import './AboUpgrade.scss'

type AboDowngradePageProps = {
  userContainer: UserContainer
}

type AboDowngradeStateType = {
  userOtions?: any
  checkAgb: boolean
}

const listOption = [
  { label: 'Ich möchte mein Abo ändern.', value: 'changeAbo' },
  { label: 'Das Abo hat nicht wie erwartet kuntioniert.', value: 'notWorkingAbo' },
  {
    label: 'Das Abo wurde für ein bensonderes Event benötigt, das mittlerweile abgeschlossen ist.',
    value: 'onlyForOneEvent'
  },
  { label: 'Zu teuer.', value: 'tooExpensive' },
  { label: 'Ich brauche nicht so viele Minuten.', value: 'IdontNeedMinutes.' },
  { label: 'Das Service ist mir zu kompliziert.', value: 'serviceBad' },
  { label: 'Zu viele technische Probleme.', value: 'tooManyTechProblems' },
  { label: 'Anderer Grund.', value: 'otherReason' }
]

export default class AboDowngradePage extends React.Component<AboDowngradePageProps, AboDowngradeStateType> {
  constructor(props: AboDowngradePageProps) {
    super(props)
    this.state = {
      checkAgb: false,
      userOtions: this.initUserOptions()
    }
  }

  initUserOptions() {
    let userOtions = {}
    listOption.map((itm) => {
      userOtions = { ...userOtions, [itm.value]: false }
    })
    userOtions = { ...userOtions, textarea: '' }
    return userOtions
  }

  renderBottomBar = () => {
    return (
      <div>
        <StaigeButton
          label={'Downgrade bestätigen'}
          style='white'
          iconMargin='0 -4px 0 4px'
          disabled={!this.state.checkAgb}
          onClick={() => {}}
        />
      </div>
    )
  }

  handleUserOption = (value: string, option: boolean) => {
    let { userOtions } = this.state
    userOtions[value] = option
    this.setState({
      userOtions
    })
  }

  handleUserOptionText = (value: string) => {
    let { userOtions } = this.state
    userOtions.textarea = value
    this.setState({
      userOtions
    })
  }

  render() {
    console.log(this.state)
    return (
      <PageWrapper
        headerTitle={`Zurück zu Aboverwaltung`}
        linkTo={`/administration/subscriptionmanagement`}
        simpleTopbar
        bottomBar={this.renderBottomBar}
      >
        <div className='page-aboUpgrade'>
          <div className='content-wrap'>
            <div className='wrap-content'>
              <div className='content-header'>
                <div className='header-title'>Abo Downgrade</div>
                <div className='header-subTitle'>
                  Hi {this.props.userContainer.state.user?.name || 'User'}! Schade, dass du dein Pro LTE Abo
                  kündigen möchtest 😢
                </div>
              </div>

              <div className='content-body'>
                <div className='body-form'>
                  <div className='form-title'>Warum möchtest du kündigen?</div>
                  <div className='form-list'>
                    {listOption.map((itm, index) => {
                      return (
                        <StaigeCheckbox
                          key={index}
                          label={itm.label}
                          checked={this.state.userOtions[itm.label]}
                          handleClick={(option) => this.handleUserOption(itm.value, option)}
                        />
                      )
                    })}

                    <StaigeInput
                      placeholder='Bitte macht weitere Angaben, damit wir unser Angebot für dich verbessern können.'
                      onChange={(event) => this.handleUserOptionText(event.target.value)}
                      textarea
                    />
                  </div>
                  <div className='form-agb'>
                    <StaigeCheckbox
                      handleClick={(value) => {
                        this.setState({
                          checkAgb: value
                        })
                      }}
                      label={
                        <div>
                          {i18n.t('matchEditor.stepEvent.settings.paywall.agb1')}{' '}
                          <a className='agbBox-a' onClick={() => null}>
                            {i18n.t('matchEditor.stepEvent.settings.paywall.agb2')}
                          </a>
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    )
  }
}
