import React from 'react'
import TopBar from '../components/Topbar'
import { Subscribe } from 'unstated-typescript'
import userContainer from '../services/userContainer'
import contractContainer from '../services/contractContainer'

import './HeaderWrapper.scss'

type HeaderWrapperProps = {
  backLink?: boolean
  headerTitle?: string
  className?: string
  children?: any
  linkTo?: string
  quickButton?: any
  onClick?: any
  style?: any
  simpleTopbar?: boolean
  bottomBar?: any
}

type HeaderWrapperState = {
  // renderFlip: boolean
  height: number
}

export default class ContentHeader extends React.Component<HeaderWrapperProps, HeaderWrapperState> {
  constructor(props: HeaderWrapperProps) {
    super(props)

    this.state = {
      height: 0
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = (event: any) => {
    const isMobile = !window.matchMedia('only screen and (min-width: 768px)').matches
    const contentElement = document.getElementById('content')

    if (contentElement) {
      if (contentElement.clientHeight >= contentElement.scrollHeight) {
        this.setState({
          height: 0
        })
        return null
      }
    }

    this.setState({
      height: isMobile ? event.srcElement.scrollTop : 0
    })
  }

  render() {
    return (
      <Subscribe to={[userContainer]}>
        {(userContainer) => (
          <div className='pageWrapper'>
            <TopBar
              userContainer={userContainer}
              contractContainer={contractContainer}
              siteTitle={this.props.headerTitle || 'Title Missing'}
              linkTo={this.props.linkTo}
              quickButton={this.props.quickButton}
              onClick={this.props.onClick}
              scrollHeight={this.state.height}
              simpleTopbar={this.props.simpleTopbar}
            />
            <div
              className={`${this.props.className} mbsc-col-sm-12`}
              style={{ ...this.props.style, overflow: 'auto', height: '100%' }}
              id={'content'}
            >
              {this.props.children}
            </div>

            {this.props.bottomBar ? <div className='bottom-bar'>{this.props.bottomBar()}</div> : null}
          </div>
        )}
      </Subscribe>
    )
  }
}
